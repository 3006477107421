import VehicleTypeIndex from "../pages/admin/vehicle_type/index.vue";

const VehicleType = [
  {
    path: "vehicle_type",
    name: "vehicle_type",
    component: VehicleTypeIndex,
  },
];

export default VehicleType;
