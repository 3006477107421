<template>
  <BaseDetailLayout title="Employee Details">
    <template #detail>
      <v-row no-gutters class="ma-0 px-3 pt-3">
        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="primary rounded-lg">
            <v-row
              no-gutters
              v-if="employeeDetail.employee_details"
              class="pa-2"
            >
              <v-col cols="4" md="3" lg="4" class="pa-2">
                <img
                  :src="EmployeeImage"
                  height="
                  100px
                  "
                  width="
                  100px
                  "
                />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Employee Name</h4>
                  <h5 class="white--text">
                    {{ employeeDetail.employee_details.employee_name }}
                  </h5>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <h4 class="text-caption white--text">Employee ID</h4>
                    <h5 class="white--text">
                      {{ employeeDetail.employee_details.employee_id }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Company</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ employeeDetail.employee_details.company }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption">Company Location</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ employeeDetail.employee_details.company_location }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">Shift</h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ employeeDetail.employee_details.company_shift }}
                </h5>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="6">
          <section class="d-flex flex-column">
            <v-row
              no-gutters
              class="pb-3 px-3"
              v-if="employeeDetail && employeeDetail.employee_details"
            >
              <v-col
                v-for="(value, key, index) in employeeDetail.employee_details
                  .employee_analytics"
                :key="index"
                class="px-1 pb-1"
              >
                <v-card elevation="0" class="rounded-lg">
                  <v-card-text class="py-2">
                    <span
                      class="text-capitalize text-start text-body-2 font-weight-bold d-flex justify-start"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </span>
                    <div class="mt-2 d-flex align-center justify-start">
                      <div
                        class="icon-small light_primary d-flex justify-center align-center"
                      >
                        <v-icon color="primary" small>
                          {{ employeeAnalyticsIcon[key] }}</v-icon
                        >
                      </div>

                      <span class="text-caption px-4 my-1">
                        <h3>{{ value }}</h3>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="px-3" no-gutters>
              <v-col cols="12" class="px-1">
                <v-card class="rounded-lg" elevation="0">
                  <v-card-title>
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex justify-start align-center">
                        <h4 class="text-body-2 font-weight-bold">Attendance</h4>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end align-center">
                        <v-btn
                          small
                          outlined
                          class="mr-4"
                          color="primary darken-2"
                          @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          fab
                          text
                          x-small
                          color="grey darken-2"
                          @click="prev"
                        >
                          <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          text
                          x-small
                          color="grey darken-2"
                          @click="next"
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                        <!-- <h4>{{ $refs.calendar }}</h4> -->
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-sheet height="528">
                          <v-calendar
                            class="calendarClass"
                            ref="calendar"
                            :value="focus"
                            v-model="focus"
                            color="primary"
                            @click:more="viewDay"
                            :events="employeesEvents"
                            type="month"
                            @click:date="viewDay"
                          >
                          </v-calendar>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
        </v-col>

        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="rounded-lg">
            <v-card-title
              ><h4 class="text-body-2 font-weight-bold">Logs</h4></v-card-title
            >
            <v-divider></v-divider>
            <v-card-text
              style="height: calc(100vh - 225px)"
              class="overflow-y-auto"
            >
              <BaseLogs :logs="employeeLogs" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BaseDetailLayout>
</template>

<script>
import EmployeeImage from "@/assets/employee-image.png";
export default {
  name: "EmployeeLayout",
  data() {
    return {
      EmployeeImage,

      employeeDetail: {
        employee_details: {
          employee_name: "Rajshikar Dhanwad",
          employee_id: "EMP0024",
          company: "Ahmedabad City Area Project",
          company_location: "401, Satya One Complex, Memnagar",
          company_shift: "09:00 - 17:00",
          contact_number: "+971 444 874 9746",
          email: "rajshikar@gmail.com",
          addresses: {
            home_address:
              "Shiv Shakti Society, Nickol Naroda, Ahmedabad, India, 23.04, 72.53",
            preferred_pickup_location:
              "New Naroda, Ahmedabad, India, 23.04, 72.53",
            preferred_drop_location:
              "Satya One Complex, Memnagar, Ahmedabad, India, 23.04, 72.53",
          },
          employee_analytics: {
            present: 200,
            absent: 2,
            routes: 1,
            employee_since: "23/03/2023",
          },
        },
      },

      employeeAnalyticsIcon: {
        present: "mdi-account-check",
        absent: "mdi-account-remove",
        routes: "mdi-map-marker-path",
        employee_since: "mdi-calendar-range",
      },

      employeeLogs: [
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Driver has been assigned the vehicle(VH001)",
        },
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Trip Trip-01 has been assigned to the driver.",
        },
      ],

      // calendar variables
      focus: "",
      events: [
        {
          name: "PickUp",
          date: "2023-03-01",
          color: "#780000",
        },
        {
          name: "Drop",
          date: "2023-03-02",
          color: "#00612C",
        },
        {
          name: "PickUp",
          date: "2023-03-03",
          color: "#780000",
        },
        {
          name: "Drop",
          date: "2023-03-03",
          color: "#00612C",
        },
        {
          name: "Drop",
          date: "2023-03-04",
          color: "#00612C",
        },
        {
          name: "PickUp",
          date: "2023-03-23",
          color: "#780000",
        },
        {
          name: "Drop",
          date: "2023-03-23",
          color: "#00612C",
        },
        {
          name: "PickUp",
          date: "2023-03-24",
          color: "#780000",
        },
        {
          name: "Drop",
          date: "2023-03-24",
          color: "#00612C",
        },
        {
          name: "PickUp",
          date: "2023-03-25",
          color: "#780000",
        },
        {
          name: "Drop",
          date: "2023-03-25",
          color: "#00612C",
        },
      ],
    };
  },
  computed: {
    employeesEvents() {
      const events = this.events;
      events.forEach((element) => {
        element.start = element.date;
        element.color = element.color;
        element.name = element.name;
      });

      return events;
    },
  },
  methods: {
    // calendar methods
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = "";
    },

    viewDay({ date }) {
      this.focus = date;
      // this.type = "day";
    },
  },
  mounted() {
    // this.$refs.calendar.checkChange();
  },
};
</script>

<style >
</style>