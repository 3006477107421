import ProjectIndex from "../pages/admin/projects/index.vue";
import ProjectCreate from "../pages/admin/projects/create/index.vue";
import ProjectEdit from "../pages/admin/projects/edit/_id/index.vue";

const Projects = [
  {
    path: "projects",
    name: "projects",
    component: ProjectIndex,
  },
  {
    path: "projects/create",
    name: "projects/create",
    component: ProjectCreate,
  },
  {
    path: "projects/edit/:id",
    name: "projects/edit/:id",
    component: ProjectEdit,
  },
];

export default Projects;
