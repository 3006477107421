import PickupDropStopsIndex from "../pages/admin/pickup_drop_stops/index.vue";
// import EmployeesCreate from "../pages/admin/employees/create/index.vue";

const PickupDropStops = [
  {
    path: "pickup_drop_stops",
    name: "pickup_drop_stops",
    component: PickupDropStopsIndex,
  },
  // {
  //   path: "employees/create",
  //   name: "employees/create",
  //   component: EmployeesCreate,
  // },
];

export default PickupDropStops;
