<template>
  <RoutesForm :editMode="false"></RoutesForm>
</template>

<script>
import RoutesForm from "@/components/ModuleBasedComponents/routes/RoutesForm.vue";
export default {
  components: {
    RoutesForm,
  },
};
</script>

<style>
</style>