<template>
  <BaseListLayout
    ref="listLayout"
    title="Vehicle Type"
    :table-header="headers"
    :table-data="vehicleTypeList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getVehicleTypeList"
    id="vehicleTypeSearch"
    name="vehicleTypeSearch"
  >
    <template #listAction>
      <BaseButton
        id="vehicleTypeAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addVehicleType()"
        >Add Vehicle Type</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getVehicleTypeList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>

      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="vehicleTypeFilterDialog = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <!-- <VehicleFilter
        v-model="vehicleFilterDialog"
        @vehicleFiltersChanged="$refs.listLayout.searchTriggered()"
      ></VehicleFilter> -->
      <VehicleTypeForm
        ref="vehicleTypeForm"
        v-model="openVehicleTypeForm"
        :editMode="editMode"
        :id="vehicleTypeID"
        @refreshList="getVehicleTypeList({ offset: 0, limit: 10 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import VehicleTypeActionsButton from "@/components/AgGridButtons/VehicleTypeActionsButton.vue";
import VehicleTypeForm from "@/components/ModuleBasedComponents/vehicle_type/VehicleTypeForm.vue";
// import VehicleFilter from "@/components/ModuleBasedComponents/vehicles/VehicleFilter.vue";
// import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    VehicleTypeActionsButton,
    VehicleTypeForm,
    // VehicleFilter,
    // BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openVehicleTypeForm: false,
      editMode: false,
      vehicleFilterDialog: false,
      headers: [
        {
          headerName: "Vehicle Type",
          field: "type",
        },
        {
          headerName: "Seating Capacity",
          field: "seating_capacity",
        },
        {
          headerName: "Vehicle Count",
          field: "vehicle_count",
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "VehicleTypeActionsButton",
        },
      ],
      vehicleTypeList: [],
      vehicleTypeID: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getVehicleTypeList(params = {}) {
      let filters = localStorage.getItem("vehicleTypeFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.vehicle_type
        .getVehicleTypeList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vehicleTypeList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addVehicleType() {
      this.editMode = false;
      this.openVehicleTypeForm = true;
    },

    editVehicleType(id) {
      this.vehicleTypeID = id;
      this.editMode = true;
      this.$refs.vehicleTypeForm.getVehicleTypeObject(id);
      this.openVehicleTypeForm = true;
    },
  },
};
</script>

<style>
</style>