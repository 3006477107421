import axiosInstance from "./axios";

import auth from "../services/auth";
import employees from "@/services/employees";
import projects from "@/services/projects";
import driver from "@/services/driver";
import vehicle from "@/services/vehicle";
import company from "@/services/company";
import company_shifts from "@/services/company_shifts";
import company_addresses from "@/services/company_addresses";
import pickup_drop_stops from "@/services/pickup_drop_stops";
import user from "@/services/user";
import routes from "@/services/routes";
import trips from "@/services/trips";
import trip_redundant from "@/services/trip_redundant";
import route_planning from "@/services/route_planning";
import bulkUpload from "@/services/bulkUpload";
import dashboard from "@/services/dashboard";
import vehicle_type from "@/services/vehicle_type";
import route_quotation from "@/services/route_quotation";

export default {
  auth: auth(axiosInstance),
  employees: employees(axiosInstance),
  projects: projects(axiosInstance),
  driver: driver(axiosInstance),
  vehicle: vehicle(axiosInstance),
  company: company(axiosInstance),
  company_shifts: company_shifts(axiosInstance),
  company_addresses: company_addresses(axiosInstance),
  pickup_drop_stops: pickup_drop_stops(axiosInstance),
  user: user(axiosInstance),
  routes: routes(axiosInstance),
  trips: trips(axiosInstance),
  trip_redundant: trip_redundant(axiosInstance),
  route_planning: route_planning(axiosInstance),
  bulkUpload: bulkUpload(axiosInstance),
  dashboard: dashboard(axiosInstance),
  vehicle_type: vehicle_type(axiosInstance),
  route_quotation: route_quotation(axiosInstance),
};
