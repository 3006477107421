var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Vehicle`,"width":"50vh"},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"vehicleForm",attrs:{"id":"vehicleForm","name":"vehicleForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"vehicle_number","name":"vehicle_number","required":true,"label":"Vehicle Plate No.","error-messages":_vm.formErrors && _vm.formErrors.vehicle_number
                ? _vm.formErrors.vehicle_number
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.vehicle_number
                ? delete _vm.formErrors.vehicle_number
                : ''}},model:{value:(_vm.vehicleDetails.vehicle_number),callback:function ($$v) {_vm.$set(_vm.vehicleDetails, "vehicle_number", $$v)},expression:"vehicleDetails.vehicle_number"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"id":"project","name":"project","required":true,"label":"Select Project","itemsList":_vm.projectList,"rules":[(val) => !!val || 'Project is required'],"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.project ? _vm.formErrors.project : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.project
                ? delete _vm.formErrors.project
                : ''}},model:{value:(_vm.vehicleDetails.project),callback:function ($$v) {_vm.$set(_vm.vehicleDetails, "project", $$v)},expression:"vehicleDetails.project"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"id":"fuel_type","name":"fuel_type","required":true,"label":"Fuel Type","rules":[(val) => !!val || 'Fuel Type is required'],"itemsList":_vm.fuelTypesList,"isSearchRequired":false,"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.fuel_type ? _vm.formErrors.fuel_type : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.fuel_type
                ? delete _vm.formErrors.fuel_type
                : ''}},model:{value:(_vm.vehicleDetails.fuel_type),callback:function ($$v) {_vm.$set(_vm.vehicleDetails, "fuel_type", $$v)},expression:"vehicleDetails.fuel_type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"rounded-lg remove-number-spinners",attrs:{"type":"number","id":"seating_capacity","outlined":"","dense":"","name":"seating_capacity","required":true,"rules":[(val) => !!val || 'Seating Capacity is required'],"min":"1","label":"Seating Capacity *","error-messages":_vm.formErrors && _vm.formErrors.seating_capacity
                ? _vm.formErrors.seating_capacity
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.seating_capacity
                ? delete _vm.formErrors.seating_capacity
                : ''}},model:{value:(_vm.vehicleDetails.seating_capacity),callback:function ($$v) {_vm.$set(_vm.vehicleDetails, "seating_capacity", $$v)},expression:"vehicleDetails.seating_capacity"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{attrs:{"id":"vehicleResetBtn","customClass":"rounded-lg","small":""}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"id":"vehicleSubmitBtn","customClass":"rounded-lg","small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openVehicleForm),callback:function ($$v) {_vm.openVehicleForm=$$v},expression:"openVehicleForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }