<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="detailsRoutePlanning"
      name="detailsRoutePlanning"
      :disabled="params.data.status == 'Completed' ? false : true"
      iconName="mdi-eye"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="View Details"
      @click="viewPlan()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    viewPlan() {
      this.params.context.parentComponent.viewPlan(this.params.data.id);
    },
  },
};
</script>

<style>
</style>