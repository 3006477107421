<template>
  <div @click="$emit('selectRoute')">
    <v-expansion-panel-header class="pa-0 ma-0 app-list-card-expansion-header">
      <div class="d-flex algin-center justify-center justify-space-between">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-body-2 mr-1 font-weight-bold"
              v-if="route.route_name"
              v-bind="attrs"
              v-on="on"
            >
              {{ route.route_name }}
            </span>
          </template>
          <span class="text-capitalize">Route Name</span>
        </v-tooltip>

        <v-chip
          class="mr-1 text-capitalize primary--text font-weight-bold"
          small
          color="light_color_actions"
          v-if="route.route_type"
        >
          {{ route.route_type }}
        </v-chip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="px-0 py-2" v-if="route">
      <v-row no-gutters @click="onSelectedRoute(route)">
        <v-col cols="12" class="pb-1">
          <RoutesTooltipWithIcon
            v-if="route.company"
            icon="mdi-account"
            :text="route.company"
            label="Company Name"
          />
        </v-col>
        <v-col cols="12" class="pb-1">
          <RoutesTooltipWithIcon
            v-if="route.company_address"
            icon="mdi-office-building-marker"
            :text="route.company_address"
            label="Company Address"
          />
        </v-col>
        <v-col cols="12" class="pb-1">
          <RoutesTooltipWithIcon
            v-if="route.shift_time"
            icon="mdi-timer-marker"
            :text="route.shift_time"
            label="Route Duration"
          />
        </v-col>
        <v-col cols="12" class="">
          <RoutesTooltipWithIcon
            v-if="route.project"
            icon="mdi-map"
            :text="route.project"
            label="Project"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import RoutesTooltipWithIcon from "@/components/ModuleBasedComponents/routes/RoutesTooltipWithIcon.vue";
export default {
  components: {
    RoutesTooltipWithIcon,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onSelectedRoute(route) {
      this.$emit("selectedRoute", route.id);
      this.$emit("onSelectedRoute", route);
    },
  },
};
</script>
