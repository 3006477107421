<template>
  <BaseFormLayout
    :title="`${editMode ? 'Edit' : 'Add'} User`"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="userResetBtn"
        small
        class="mr-2 rounded-lg"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="userSubmitBtn"
        small
        customClass="rounded-lg"
        @click="submitForm()"
        :disabled="!isValid"
        >Submit</BaseButton
      >
    </template>

    <template #content>
      <v-form
        ref="userForm"
        id="userForm"
        autocomplete="off"
        v-model="isValid"
        :key="formCount"
      >
        <v-row class="pa-0 ma-0 white rounded-lg">
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-row class="pa-0 ma-0">
              <v-col cols="3">
                <BaseTextInput
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  :required="true"
                  v-model="userFormDetails.first_name"
                  :error-messages="
                    formErrors && formErrors.first_name
                      ? formErrors.first_name
                      : ''
                  "
                  @input="
                    formErrors && formErrors.first_name
                      ? delete formErrors.first_name
                      : ''
                  "
                ></BaseTextInput>
              </v-col>
              <v-col cols="3">
                <BaseTextInput
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  :required="true"
                  v-model="userFormDetails.last_name"
                  :error-messages="
                    formErrors && formErrors.last_name
                      ? formErrors.last_name
                      : ''
                  "
                  @input="
                    formErrors && formErrors.last_name
                      ? delete formErrors.last_name
                      : ''
                  "
                ></BaseTextInput>
              </v-col>
              <v-col cols="3">
                <BaseTextInput
                  id="username"
                  name="username"
                  label="Username"
                  :required="true"
                  v-model="userFormDetails.username"
                  :error-messages="
                    formErrors && formErrors.username ? formErrors.username : ''
                  "
                  @input="
                    formErrors && formErrors.username
                      ? delete formErrors.username
                      : ''
                  "
                ></BaseTextInput>
              </v-col>
              <v-col cols="3">
                <BaseEmailInput
                  id="email"
                  name="email"
                  label="Email"
                  :required="true"
                  v-model="userFormDetails.email"
                  :error-messages="
                    formErrors && formErrors.email ? formErrors.email : ''
                  "
                  @input="
                    formErrors && formErrors.email
                      ? delete formErrors.email
                      : ''
                  "
                ></BaseEmailInput>
              </v-col>
              <v-col cols="3">
                <!-- <BasePhoneInput
                  id="contact_number"
                  name="contact_number"
                  label="Contact Number"
                  :required="true"
                  v-model="userFormDetails.contact_number"
                  :error-messages="
                    formErrors && formErrors.contact_number
                      ? formErrors.contact_number
                      : ''
                  "
                  @input="
                    formErrors && formErrors.contact_number
                      ? delete formErrors.contact_number
                      : ''
                  "
                ></BasePhoneInput> -->
                <v-text-field
                  outlined
                  class="background-white rounded-lg remove-number-spinners"
                  type="number"
                  min="0"
                  step="01"
                  hide-details="auto"
                  :required="true"
                  dense
                  label="Contact Number *"
                  :rules="[(val) => !!val || 'Contact Number is required']"
                  :error-messages="
                    formErrors && formErrors.contact_number
                      ? formErrors.contact_number
                      : ''
                  "
                  v-model="userFormDetails.contact_number"
                  @input="
                    formErrors && formErrors.contact_number
                      ? delete formErrors.contact_number
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <BasePasswordInput
                  id="password"
                  name="password"
                  label="Password"
                  :required="!editMode"
                  v-model="userFormDetails.password"
                  :error-messages="
                    formErrors && formErrors.password ? formErrors.password : ''
                  "
                  @input="
                    formErrors && formErrors.password
                      ? delete formErrors.password
                      : ''
                  "
                ></BasePasswordInput>
              </v-col>
              <v-col cols="3">
                <BasePasswordInput
                  id="confirm_password"
                  name="confirm_password"
                  label="Confirm Password"
                  :required="!editMode"
                  v-model="userFormDetails.confirm_password"
                  :error-messages="
                    formErrors && formErrors.confirm_password
                      ? formErrors.confirm_password
                      : ''
                  "
                  @input="
                    formErrors && formErrors.confirm_password
                      ? delete formErrors.confirm_password
                      : ''
                  "
                ></BasePasswordInput>
              </v-col>
              <v-col cols="3">
                <BaseSelect
                  id="project"
                  name="project"
                  label="Select Project *"
                  :multiple="true"
                  :required="true"
                  :itemsList="projectList"
                  :rules="[(val) => !!val || 'Project is required']"
                  itemText="name"
                  itemValue="value"
                  v-model="userFormDetails.project"
                  :error-messages="
                    formErrors && formErrors.project ? formErrors.project : ''
                  "
                  @input="
                    formErrors && formErrors.project
                      ? delete formErrors.project
                      : ''
                  "
                ></BaseSelect>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="4" class="d-flex justify-center align-center">
            <BaseProfileUpload
              v-model="profileImage"
              @UploadProfile="updateProfile"
            ></BaseProfileUpload>
          </v-col> -->
        </v-row>
      </v-form>
    </template>
  </BaseFormLayout>
</template>

<script>
import { bus } from "@/main";
import { generatedFormData } from "@/utils/functions.js";
export default {
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      userFormDetails: {},
      isValid: true,
      formCount: 0,
      projectList: [],
      profileImage: null,
    };
  },
  methods: {
    updateProfile(image, isChangeable = false) {
      if (this.userFormDetails.update_profile_image || isChangeable) {
        this.userFormDetails.update_profile_image = true;
        if (image) {
          this.userFormDetails.profile_image = image;
        } else {
          delete this.userFormDetails.profile_image;
        }
      } else {
        this.userFormDetails.update_profile_image = false;
        delete this.userFormDetails.profile_image;
      }
    },
    getUserObject(id = null) {
      bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.userFormDetails = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    resetForm() {
      if (this.$refs && this.$refs.userForm) {
        this.$refs.userForm.reset();
        this.$refs.userForm.resetValidation();
      }
    },

    submitForm() {
      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.user
          .createUser(generatedFormData(this.userFormDetails))
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "User created!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        if (
          this.normalUserDetails &&
          !this.normalUserDetails.update_profile_image
        ) {
          delete this.normalUserDetails.profile_image;
        }
        this.$api.user
          .updateUserObject({
            id: this.userFormDetails.id,
            data: generatedFormData(this.userFormDetails),
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "User information updated!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      }
    },
  },
  mounted() {
    this.getProjectsList();
    if (this.$route.params.id) {
      this.getUserObject(this.$route.params.id);
    }
  },
};
</script>

<style>
</style>