import RoutePlanningIndex from "../pages/admin/route_planning/index.vue";
import RoutePlanningView from "../pages/admin/route_planning/view/_id/index.vue";

const RoutePlanning = [
  {
    path: "route_planning",
    name: "route_planning",
    component: RoutePlanningIndex,
  },
  {
    path: "route_planning/view/:id",
    name: "route_planning/view/:id",
    component: RoutePlanningView,
  },
];

export default RoutePlanning;
