<template>
  <div class="custom-list">
    <v-row no-gutters class="ma-0">
      <v-col cols="12" class="text-left pa-0">
        <v-card elevation="0" class="rounded-lg w-100 pa-4 light_background">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex">
              <BaseActionsButton
                iconName="mdi-arrow-left-bold"
                iconColor="primary"
                customClass="mr-2"
                @click="$router.back()"
                tooltipMessage="Back"
              ></BaseActionsButton>
              <h3 class="text-uppercase text-h6 font-weight-bold">
                Route Plan -
                {{
                  routePlanDetails && routePlanDetails.plan_name
                    ? routePlanDetails.plan_name
                    : ""
                }}
              </h3>
            </v-col>
            <!-- <v-col cols="6" class="d-flex justify-end align-center">
              <BaseButton
                id="createRoutesBtn"
                customClass="rounded-lg font-weight-bold"
                small
                :disabled="routePlanDetails.action_taken"
                @click="submitForm()"
                >Create Route(s)</BaseButton
              >
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-4 px-4">
        <v-card elevation="0" class="pa-0 rounded-lg">
          <v-card-title class="">
            <h4 class="text-body-1 font-weight-bold black--text">Plan Info.</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="routePlanForm">
              <v-row>
                <v-col cols="3">
                  <BaseTextInput
                    :disabled="true"
                    id="plan_name"
                    name="plan_name"
                    v-model="routePlanDetails.plan_name"
                    label="Plan Name"
                  ></BaseTextInput>
                </v-col>

                <v-col cols="3">
                  <BaseTextInput
                    :disabled="true"
                    id="company"
                    name="company"
                    v-model="routePlanDetails.company"
                    label="Company"
                  ></BaseTextInput>
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company"
                    name="company"
                    v-model="routePlanDetails.company"
                    :itemsList="companyList"
                    itemText="name"
                    itemValue="value"
                    label="Select Company *"
                    :rules="[(val) => !!val || 'Company is required']"
                    @change="
                      getCompanyAddressesList({
                        company_id: routePlanDetails.company,
                      }),
                        getCompanyShiftList({
                          company_id: routePlanDetails.company,
                        })
                    "
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="3">
                  <BaseTextInput
                    :disabled="true"
                    id="company_address"
                    name="company_address"
                    v-model="routePlanDetails.company_address"
                    label="Company Address"
                  ></BaseTextInput>
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company_address"
                    name="company_address"
                    v-model="routePlanDetails.company_address"
                    :itemsList="companyAddressesList"
                    itemText="name"
                    itemValue="value"
                    label="Select Company Address *"
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="3">
                  <BaseTextInput
                    :disabled="true"
                    id="company_shift"
                    name="company_shift"
                    v-model="routePlanDetails.company_shift"
                    label="Company Shift"
                  ></BaseTextInput>
                  <!-- <BaseSelect
                    :disabled="true"
                    id="company_shift"
                    name="company_shift"
                    v-model="routePlanDetails.company_shift"
                    :itemsList="companyShiftList"
                    itemText="name"
                    itemValue="value"
                    label="Select Company Shift *"
                  ></BaseSelect> -->
                </v-col>
                <v-col cols="3">
                  <BaseTextInput
                    :disabled="true"
                    id="route_type"
                    name="route_type"
                    v-model="routePlanDetails.route_type"
                    label="Route Type"
                  ></BaseTextInput>
                  <!-- <BaseSelect
                    :disabled="true"
                    id="route_type"
                    name="route_type"
                    v-model="routePlanDetails.route_type"
                    :itemsList="routeTypeOptions"
                    itemText="name"
                    itemValue="value"
                    label="Route Type *"
                  ></BaseSelect> -->
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-4 px-4">
        <v-expansion-panels
          v-for="(routePlan, routePlanIndex) in routePlanList"
          :key="routePlanIndex"
          flat
          class="mb-3"
        >
          <RoutePlanExpansionPanel
            v-model="routePlanList[routePlanIndex]"
            :routePlanIndex="routePlanIndex"
            :routeErrors="routeErrors[routePlanIndex]"
            :selectedRoutes="selectedRoutes"
            @routeSelected="routeSelected($event, routePlan.id)"
          ></RoutePlanExpansionPanel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
import RoutePlanExpansionPanel from "@/components/ModuleBasedComponents/route_planning/RoutePlanExpansionPanel.vue";
export default {
  components: {
    RoutePlanExpansionPanel,
  },
  data() {
    return {
      routePlanDetails: {},
      companyShiftList: [],
      companyList: [],
      companyAddressesList: [],
      routeTypeOptions: [
        {
          name: "PickUp",
          value: "pick_up",
        },
        {
          name: "Drop",
          value: "drop",
        },
      ],
      routePlanList: [],
      routeErrors: [],
      selectedRoutes: [],
    };
  },
  methods: {
    routeSelected(e, id) {
      if (e) {
        this.selectedRoutes.push(id);
      } else {
        this.selectedRoutes.splice(
          this.selectedRoutes.indexOf(this.selectedRoutes.find((v) => v == id)),
          1
        );
      }
    },
    getRoutePlanObject(id) {
      bus.$emit("showLoader", true);
      this.$api.route_planning
        .getRoutePlanObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routePlanDetails = res.data;
          this.routePlanList = res.data.planned_routes;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.company.getCompanyList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
        limit: "all",
        company_id: this.routePlanDetails.company,
      };
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyAddressesList = res.data.map((obj) => {
            return {
              name: obj.location_name,
              value: obj.id,
            };
          });
        });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
        company_id: this.routePlanDetails.company,
      };
      bus.$emit("showLoader", true);

      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        bus.$emit("showLoader", false);
        this.companyShiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.id,
          };
        });
      });
    },
    submitForm() {
      let data = { id: this.$route.params.id, data: [...this.selectedRoutes] };
      this.$api.route_planning
        .createRoutes(data)
        .then((res) => {
          bus.$emit("showToastMessage", {
            message: "Route(s) Created!",
            color: "success",
          });
          this.$router.push("/app/admin/routes");
        })
        .catch((err) => {
          console.err(err);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getRoutePlanObject(this.$route.params.id);
      this.getCompanyAddressesList();
      this.getCompanyList();
      this.getCompanyShiftList();
    }
  },
};
</script>

<style>
</style>