var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Company Location`,"width":"90vh"},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"companyLocationForm",attrs:{"id":"companyLocationForm","name":"companyLocationForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('BaseTextInput',{attrs:{"id":"location_name","name":"location_name","required":true,"label":"Location Name","error-messages":_vm.formErrors && _vm.formErrors.location_name
                ? _vm.formErrors.location_name
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.location_name
                ? delete _vm.formErrors.location_name
                : ''}},model:{value:(_vm.companyLocationForm.location_name),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "location_name", $$v)},expression:"companyLocationForm.location_name"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('BaseSelect',{attrs:{"id":"company","name":"company","required":true,"label":"Select Company *","itemsList":_vm.companyList,"itemText":"name","itemValue":"value","rules":[(val) => !!val || 'Company is required'],"error-messages":_vm.formErrors && _vm.formErrors.company ? _vm.formErrors.company : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.company
                ? delete _vm.formErrors.company
                : '';
              _vm.getCompanyShiftList({
                company_id: _vm.companyLocationForm.company,
              });}},model:{value:(_vm.companyLocationForm.company),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "company", $$v)},expression:"companyLocationForm.company"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-text-field',{ref:"address",staticClass:"rounded-lg",attrs:{"id":"address","name":"address","placeholder":"Location Address *","hide-details":"auto","required":true,"dense":"","outlined":"","rules":[(val) => !!val || 'Location Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.address ? _vm.formErrors.address : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.address
                ? delete _vm.formErrors.address
                : ''}},model:{value:(_vm.companyLocationForm.address),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "address", $$v)},expression:"companyLocationForm.address"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('BaseSelect',{attrs:{"id":"shift","name":"shift","required":true,"multiple":true,"label":"Select Shift(s) *","itemsList":_vm.shiftList,"itemText":"name","itemValue":"value","rules":[(val) => !!val || 'Shift(s) is required'],"error-messages":_vm.formErrors && _vm.formErrors.shifts ? _vm.formErrors.shifts : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.shifts ? delete _vm.formErrors.shifts : ''}},model:{value:(_vm.companyLocationForm.shifts),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "shifts", $$v)},expression:"companyLocationForm.shifts"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('BaseTextInput',{attrs:{"id":"contact_person","name":"contact_person","required":true,"label":"Contact Person","error-messages":_vm.formErrors && _vm.formErrors.contact_person
                ? _vm.formErrors.contact_person
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_person
                ? delete _vm.formErrors.contact_person
                : ''}},model:{value:(_vm.companyLocationForm.contact_person),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "contact_person", $$v)},expression:"companyLocationForm.contact_person"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg remove-number-spinners",attrs:{"outlined":"","type":"number","min":"0","step":"01","hide-details":"auto","dense":"","label":"Contact number","error-messages":_vm.formErrors && _vm.formErrors.contact_number
                ? _vm.formErrors.contact_number
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_number
                ? delete _vm.formErrors.contact_number
                : ''}},model:{value:(_vm.companyLocationForm.contact_number),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "contact_number", $$v)},expression:"companyLocationForm.contact_number"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('BaseEmailInput',{attrs:{"id":"contact_email","name":"contact_email","required":true,"label":"Contact Email","error-messages":_vm.formErrors && _vm.formErrors.contact_email
                ? _vm.formErrors.contact_email
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_email
                ? delete _vm.formErrors.contact_email
                : ''}},model:{value:(_vm.companyLocationForm.contact_email),callback:function ($$v) {_vm.$set(_vm.companyLocationForm, "contact_email", $$v)},expression:"companyLocationForm.contact_email"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{attrs:{"customClass":"rounded-lg","small":"","id":"companyLocationResetBtn"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"customClass":"rounded-lg","small":"","id":"companyLocationSubmitBtn"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openCompanyLocationForm),callback:function ($$v) {_vm.openCompanyLocationForm=$$v},expression:"openCompanyLocationForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }