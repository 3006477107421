<template>
  <v-dialog
    v-model="tripNewFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Trip Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="tripNewFilterDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="tripNewFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="12" lg="12" xl="12" class="">
              <BaseSelect
                dense
                hide-details
                outlined
                clearable
                label="Select Project"
                :itemsList="projectList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="tripFilters.project_id"
                class="pl-1"
                :menu-props="{ offsetY: true }"
                @change="syncCompanyFilters('project_id', $event)"
              ></BaseSelect>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-1">
              <BaseDatePickerInput
                v-model="tripFilters.from_date"
                id="fromDateFilter"
                name="fromDateFilter"
                label="From Date"
                @change="syncTripFilters('from_date', $event)"
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-2">
              <BaseDatePickerInput
                v-model="tripFilters.to_date"
                id="toDateFilter"
                name="toDateFilter"
                label="To Date"
                @change="syncTripFilters('to_date', $event)"
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2">
              <BaseSelect
                v-model="tripFilters.type"
                :itemsList="routeTypeOptions"
                itemText="name"
                itemValue="value"
                id="routeTypeFilter"
                name="routeTypeFilter"
                label="Route Type"
                @change="syncTripFilters('type', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-2">
              <BaseSelect
                v-model="tripFilters.status"
                :itemsList="tripStatusOptions"
                itemText="name"
                itemValue="value"
                id="statusFilter"
                name="statusFilter"
                label="Status"
                :multiple="true"
                @change="syncTripFilters('status', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetTripNewFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyTripNewFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
import { routeTypeOptions, tripStatusOptions } from "@/utils/optionsMapping.js";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      routeTypeOptions,
      tripStatusOptions,
      tripFilters: {},
      projectList: [],
    };
  },
  watch: {
    tripNewFilterDialog(value) {
      if (value) {
        this.getProjectsList();
        let filters = localStorage.getItem("tripNewFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.tripFilters = filters;
      }
    },
  },
  computed: {
    tripNewFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    syncTripFilters(key, value) {
      let filters = localStorage.getItem("tripNewFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.tripFilters = filters;
      localStorage.setItem("tripNewFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("tripNewFiltersChanged");
      this.tripNewFilterDialog = false;
    },
    resetFilters(reload) {
      this.tripFilters = {};
      localStorage.removeItem("tripNewFilters");
      this.$emit("tripNewFiltersChanged");
      if (this.$refs.tripNewFilterForm) {
        this.$refs.tripNewFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>