var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} User`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"userResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"userSubmitBtn","small":"","customClass":"rounded-lg","disabled":!_vm.isValid},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{key:_vm.formCount,ref:"userForm",attrs:{"id":"userForm","autocomplete":"off"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"pa-0 ma-0 white rounded-lg"},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"first_name","name":"first_name","label":"First Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.first_name
                    ? _vm.formErrors.first_name
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.first_name
                    ? delete _vm.formErrors.first_name
                    : ''}},model:{value:(_vm.userFormDetails.first_name),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "first_name", $$v)},expression:"userFormDetails.first_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"last_name","name":"last_name","label":"Last Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.last_name
                    ? _vm.formErrors.last_name
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.last_name
                    ? delete _vm.formErrors.last_name
                    : ''}},model:{value:(_vm.userFormDetails.last_name),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "last_name", $$v)},expression:"userFormDetails.last_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"username","name":"username","label":"Username","required":true,"error-messages":_vm.formErrors && _vm.formErrors.username ? _vm.formErrors.username : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.username
                    ? delete _vm.formErrors.username
                    : ''}},model:{value:(_vm.userFormDetails.username),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "username", $$v)},expression:"userFormDetails.username"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseEmailInput',{attrs:{"id":"email","name":"email","label":"Email","required":true,"error-messages":_vm.formErrors && _vm.formErrors.email ? _vm.formErrors.email : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.email
                    ? delete _vm.formErrors.email
                    : ''}},model:{value:(_vm.userFormDetails.email),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "email", $$v)},expression:"userFormDetails.email"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"background-white rounded-lg remove-number-spinners",attrs:{"outlined":"","type":"number","min":"0","step":"01","hide-details":"auto","required":true,"dense":"","label":"Contact Number *","rules":[(val) => !!val || 'Contact Number is required'],"error-messages":_vm.formErrors && _vm.formErrors.contact_number
                    ? _vm.formErrors.contact_number
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_number
                    ? delete _vm.formErrors.contact_number
                    : ''}},model:{value:(_vm.userFormDetails.contact_number),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "contact_number", $$v)},expression:"userFormDetails.contact_number"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BasePasswordInput',{attrs:{"id":"password","name":"password","label":"Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.password ? _vm.formErrors.password : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.password
                    ? delete _vm.formErrors.password
                    : ''}},model:{value:(_vm.userFormDetails.password),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "password", $$v)},expression:"userFormDetails.password"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BasePasswordInput',{attrs:{"id":"confirm_password","name":"confirm_password","label":"Confirm Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.confirm_password
                    ? _vm.formErrors.confirm_password
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.confirm_password
                    ? delete _vm.formErrors.confirm_password
                    : ''}},model:{value:(_vm.userFormDetails.confirm_password),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "confirm_password", $$v)},expression:"userFormDetails.confirm_password"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"project","name":"project","label":"Select Project *","multiple":true,"required":true,"itemsList":_vm.projectList,"rules":[(val) => !!val || 'Project is required'],"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.project ? _vm.formErrors.project : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.project
                    ? delete _vm.formErrors.project
                    : ''}},model:{value:(_vm.userFormDetails.project),callback:function ($$v) {_vm.$set(_vm.userFormDetails, "project", $$v)},expression:"userFormDetails.project"}})],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }