<template>
  <BaseListLayout
    ref="listLayout"
    title="Company Locations"
    :table-header="headers"
    :table-data="companyLocationList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getCompanyAddressesList"
    id="companyLocationSearch"
    name="companyLocationSearch"
  >
    <template #listAction>
      <BaseButton
        id="companyLocationAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="openCompanyLocationsForm = true"
        >Add Company Location</BaseButton
      >
      <BaseButton
        id="companyLocationBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Company Location</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getCompanyAddressesList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="openCompanyAddressFilter = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <CompanyLocationsFilter
        v-model="openCompanyAddressFilter"
        @companyAddressFiltersChanged="$refs.listLayout.searchTriggered()"
      ></CompanyLocationsFilter>
      <CompanyLocationsForm
        ref="companyLocationForm"
        v-model="openCompanyLocationsForm"
        :editMode="editMode"
        @refreshList="getCompanyAddressesList({ offset: 0, limit: 10 })"
      />
      <BulkUpload
        uploadTo="company_address"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getCompanyAddressesList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import CompanyLocationActionsButton from "@/components/AgGridButtons/CompanyLocationActionsButton.vue";
import CompanyLocationsForm from "@/components/ModuleBasedComponents/company_locations/CompanyLocationForm.vue";
import CompanyLocationsFilter from "@/components/ModuleBasedComponents/company_locations/CompanyLocationsFilter.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    CompanyLocationActionsButton,
    CompanyLocationsForm,
    CompanyLocationsFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openCompanyLocationsForm: false,
      showBulkUploadDialog: false,
      headers: [
        {
          headerName: "Location ID",
          field: "location_id",
        },
        {
          headerName: "Location Name",
          field: "location_name",
        },
        {
          headerName: "Address",
          field: "address",
        },
        {
          headerName: "Coordinate (Latitude & Longitude)",
          valueGetter: function (params) {
            return (
              params.data.coordinates.latitude +
              ", " +
              params.data.coordinates.longitude
            );
          },
        },
        {
          headerName: "Company",
          field: "company_name",
        },
        {
          headerName: "No. of Employee(s)",
          field: "no_of_employees",
        },
        {
          headerName: "Total Shifts",
          field: "company_shifts_count",
        },
        {
          headerName: "Contact Person",
          field: "contact_person",
        },
        {
          headerName: "Contact No",
          field: "contact_number",
        },
        {
          headerName: "Contact Email",
          field: "contact_email",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "CompanyLocationActionsButton",
        },
      ],
      companyLocationList: [],
      editMode: false,
      companyLocationID: null,
      openCompanyAddressFilter: false,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getCompanyAddressesList(params = {}) {
      let filters = localStorage.getItem("companyLocationFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.company_id && filters.company_id.length > 0) {
        filters.company_id = filters.company_id.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyLocationList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addCompanyLocation() {
      this.editMode = false;
      this.openCompanyLocationsForm = true;
    },

    editCompanyLocation(id) {
      this.editMode = true;
      this.openCompanyLocationsForm = true;
      this.$refs.companyLocationForm.getCompanyAddressesObject(id);
    },
  },
};
</script>

<style>
</style>