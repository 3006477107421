export function handleResponse(res) {
  let obj = {};
  if (res.data && typeof res.data == typeof {}) {
    obj.data = res.data;
    if (res.data.data) {
      obj.data = res.data.data;
    }
    if (
      res.data.count != null ||
      res.data.count != undefined ||
      res.data.count != ""
    ) {
      obj.count = res.data.count;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function handleError(err) {
  let obj = {};
  if (err.response && err.response.data) {
    obj.data = err.response.data;
    if (obj.data.error) {
      obj.data = err.response.data.error;
    }
  } else {
    obj.response = err.response;
  }
  // obj.status = err.response.status;
  return obj;
}

export function generatedFormData(obj, skipKeys = []) {
  let formData = new FormData();
  if (obj && typeof obj == "object") {
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (key == "coordinates") {
        value = JSON.stringify(value);
      }
      if (skipKeys.indexOf(key) == -1) {
        if (value != "" && value != null) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, null);
      }
    });
  }
  return formData;
}

export function convertToBlobAndDownload(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octetstream" })
  );
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function returnToday() {
  let today = new Date();
  return getDateTime(today);
}

export function getDateTime(_d) {
  let _ = new Date(_d),
    date = _.getDate() < 10 ? "0" + _.getDate() : _.getDate(),
    month = _.getMonth() + 1,
    year = _.getFullYear();

  month = month < 10 ? "0" + month : month;

  return [year, month, date].join("-");
}
