<template>
  <v-dialog
    v-model="companyFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Company Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="companyFilterDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="companyFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="">
              <BaseSelect
                dense
                hide-details
                outlined
                clearable
                label="Select Project"
                :itemsList="projectList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="companyFilter.project_id"
                class="pl-1"
                :menu-props="{ offsetY: true }"
                @change="syncCompanyFilters('project_id', $event)"
              ></BaseSelect>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetCompanyFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyCompanyFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      projectList: [],
      companyFilter: {},
    };
  },
  watch: {
    companyFilterDialog(value) {
      if (value) {
        this.getProjectsList();
        let filters = localStorage.getItem("companyFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.companyFilter = filters;
      }
    },
  },
  computed: {
    companyFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    syncCompanyFilters(key, value) {
      let filters = localStorage.getItem("companyFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.companyFilter = filters;
      localStorage.setItem("companyFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("companyFiltersChanged");
      this.companyFilterDialog = false;
    },
    resetFilters(reload) {
      this.companyFilter = {};
      localStorage.removeItem("companyFilters");
      this.$emit("companyFiltersChanged");
      if (this.$refs.companyFilterForm) {
        this.$refs.companyFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>