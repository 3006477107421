import RoutesIndex from "../pages/admin/routes/index.vue";
import RoutesCreate from "../pages/admin/routes/create/index.vue";
import RoutesEdit from "../pages/admin/routes/edit/_id/index.vue";

const Routes = [
  {
    path: "routes",
    name: "routes",
    component: RoutesIndex,
    props: true,
  },
  {
    path: "routes/create",
    name: "routes/create",
    component: RoutesCreate,
  },
  {
    path: "routes/edit/:id",
    name: "routes/edit/:id",
    component: RoutesEdit,
  },
];

export default Routes;
