import VehiclesIndex from "../pages/admin/vehicles/index.vue";
import VehiclesDetail from "../pages/admin/vehicles/detail/_id/index.vue";

const Vehicles = [
  {
    path: "vehicles",
    name: "vehicles",
    component: VehiclesIndex,
  },
  {
    path: "vehicles/detail/:id",
    name: "vehicles/detail/:id",
    component: VehiclesDetail,
  },
];

export default Vehicles;
