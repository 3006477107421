<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="editVehicleType"
      name="editVehicleType"
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editVehicleType()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    editVehicleType() {
      this.params.context.parentComponent.editVehicleType(this.params.data.id);
    },
  },
};
</script>

<style>
</style>