var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Driver`,"width":"80vh"},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"driverForm",attrs:{"id":"driverForm","name":"driverForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('BaseTextInput',{attrs:{"id":"first_name","name":"first_name","required":true,"label":"First Name","error-messages":_vm.formErrors && _vm.formErrors.first_name ? _vm.formErrors.first_name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.first_name
                ? delete _vm.formErrors.first_name
                : ''}},model:{value:(_vm.driverDetails.first_name),callback:function ($$v) {_vm.$set(_vm.driverDetails, "first_name", $$v)},expression:"driverDetails.first_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BaseTextInput',{attrs:{"id":"last_name","name":"last_name","required":true,"label":"Last Name","error-messages":_vm.formErrors && _vm.formErrors.last_name ? _vm.formErrors.last_name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.last_name
                ? delete _vm.formErrors.last_name
                : ''}},model:{value:(_vm.driverDetails.last_name),callback:function ($$v) {_vm.$set(_vm.driverDetails, "last_name", $$v)},expression:"driverDetails.last_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BaseTextInput',{attrs:{"id":"username","name":"username","required":true,"label":"Username","error-messages":_vm.formErrors && _vm.formErrors.username ? _vm.formErrors.username : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.username
                ? delete _vm.formErrors.username
                : ''}},model:{value:(_vm.driverDetails.username),callback:function ($$v) {_vm.$set(_vm.driverDetails, "username", $$v)},expression:"driverDetails.username"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BasePasswordInput',{attrs:{"id":"password","name":"password","label":"Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.password ? _vm.formErrors.password : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.password
                ? delete _vm.formErrors.password
                : ''}},model:{value:(_vm.driverDetails.password),callback:function ($$v) {_vm.$set(_vm.driverDetails, "password", $$v)},expression:"driverDetails.password"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BasePasswordInput',{attrs:{"id":"confirm_password","name":"confirm_password","label":"Confirm Password","required":!_vm.editMode,"error-messages":_vm.formErrors && _vm.formErrors.confirm_password
                ? _vm.formErrors.confirm_password
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.confirm_password
                ? delete _vm.formErrors.confirm_password
                : ''}},model:{value:(_vm.driverDetails.confirm_password),callback:function ($$v) {_vm.$set(_vm.driverDetails, "confirm_password", $$v)},expression:"driverDetails.confirm_password"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg remove-number-spinners",attrs:{"outlined":"","type":"number","min":"0","step":"01","hide-details":"auto","dense":"","required":true,"label":"Contact Number *","error-messages":_vm.formErrors && _vm.formErrors.contact_number
                ? _vm.formErrors.contact_number
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_number
                ? delete _vm.formErrors.contact_number
                : ''}},model:{value:(_vm.driverDetails.contact_number),callback:function ($$v) {_vm.$set(_vm.driverDetails, "contact_number", $$v)},expression:"driverDetails.contact_number"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"id":"project","name":"project","required":true,"label":"Select Project *","itemsList":_vm.projectList,"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.project ? _vm.formErrors.project : '',"rules":[(val) => !!val || 'Project is required']},on:{"change":function($event){_vm.formErrors && _vm.formErrors.project
                ? delete _vm.formErrors.project
                : '',
                _vm.getVehicleList({ project_id: _vm.driverDetails.project })}},model:{value:(_vm.driverDetails.project),callback:function ($$v) {_vm.$set(_vm.driverDetails, "project", $$v)},expression:"driverDetails.project"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"id":"vehicle_assigned","name":"vehicle_assigned","label":"Assign Vehicle","error-messages":_vm.formErrors && _vm.formErrors.vehicle_assigned
                ? _vm.formErrors.vehicle_assigned
                : '',"itemsList":_vm.vehicleList,"itemText":"name","itemValue":"value"},on:{"change":function($event){_vm.formErrors && _vm.formErrors.vehicle_assigned
                ? delete _vm.formErrors.vehicle_assigned
                : ''}},model:{value:(_vm.driverDetails.vehicle_assigned),callback:function ($$v) {_vm.$set(_vm.driverDetails, "vehicle_assigned", $$v)},expression:"driverDetails.vehicle_assigned"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg",attrs:{"label":"Shift Start Time *","outlined":"","dense":"","type":"time","hide-details":"auto","id":"shift_start","name":"shift_start","error-messages":_vm.formErrors && _vm.formErrors.shift_start
                ? _vm.formErrors.shift_start
                : '',"rules":[(v) => !!v || 'Shift Start Time is required']},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shift_start
                ? delete _vm.formErrors.shift_start
                : ''}},model:{value:(_vm.driverDetails.shift_start),callback:function ($$v) {_vm.$set(_vm.driverDetails, "shift_start", $$v)},expression:"driverDetails.shift_start"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"background-white rounded-lg",attrs:{"label":"Shift End Time *","outlined":"","dense":"","type":"time","hide-details":"auto","id":"shift_end","name":"shift_end","error-messages":_vm.formErrors && _vm.formErrors.shift_end ? _vm.formErrors.shift_end : '',"rules":[(v) => !!v || 'Shift End Time is required']},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shift_end
                ? delete _vm.formErrors.shift_end
                : ''}},model:{value:(_vm.driverDetails.shift_end),callback:function ($$v) {_vm.$set(_vm.driverDetails, "shift_end", $$v)},expression:"driverDetails.shift_end"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{attrs:{"id":"driverResetBtn","customClass":"rounded-lg","small":""}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"id":"driverSubmitBtn","customClass":"rounded-lg","small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openDriverForm),callback:function ($$v) {_vm.openDriverForm=$$v},expression:"openDriverForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }