<template>
  <BaseListLayout ref="listLayout" title="Route Quotation" :table-header="headers" :table-data="routeQuotationList"
    :hasListActions="true" :total="totalItems" :context="context" @getList="getRouteQuotationList"
    name="routeQuotationSearch" id="routeQuotationSearch">
    <template #listAction>
      <BaseButton id="routeQuotationAddBtn" small customClass="font-weight-bold rounded-lg mr-2"
        @click="addRouteQuotation()">Add Route Quotation</BaseButton>
      <!-- <BaseButton
        id="routeQuotationBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Route Quotation</BaseButton
      > -->
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList id="refreshList" name="refreshList" :isIcon="true" newClass="ml-2 mr-2"
        @click="getRouteQuotationList({ offset: 0, limit: 10 })"></BaseRefreshList>

      <BaseButton id="filterBtn" x-small fab depressed customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter" iconColor="primary" iconClass="ma-1" iconSize="17px" @click="routeQuotationFilter = true">
      </BaseButton>
    </template>
    <template #dialogs>
      <!-- <RouteQuotationFilter
        v-model="routeQuotationFilter"
        @routeQuotationFiltersChanged="$refs.listLayout.searchTriggered()"
      ></RouteQuotationFilter> -->

      <!-- <DriverForm
        ref="driverForm"
        v-model="openDriverForm"
        :editMode="editMode"
        :id="driverID"
        @refreshList="getDriverList({ offset: 0, limit: 10 })"
      /> -->
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import RouteQuotationActionsButton from "@/components/AgGridButtons/RouteQuotationActionsButton.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    RouteQuotationActionsButton,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openRouteQuotationForm: false,
      editMode: false,
      showBulkUploadDialog: false,
      routeQuotationFilterDialog: false,
      headers: [
        // {
        //   headerName: "Route Quotation ID",
        //   field: "route_quotation_id",
        // },
        {
          headerName: "Route Type",
          field: "route_type",
        },
        {
          headerName: "Location Address",
          field: "location_address",
        },
        // {
        //   headerName: "Destination Location",
        //   field: "destination_location",
        // },
        {
          headerName: "Start Time",
          field: "start_time",
        },
        {
          headerName: "Trip Duration (Min)",
          field: "duration",
        },
        // {
        //   headerName: "Stops",
        //   field: "stops",
        // },
        // {
        //   headerName: "Passengers",
        //   field: "passengers",
        // },
        {
          headerName: "Vehicle Fill Ratio (%)",
          field: "vehicle_fill_ratio",
        },
        {
          headerName: "Stop Processing Time (Min)",
          field: "per_stop_processing_time",
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Progress (%)",
          field: "progress",
        
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "RouteQuotationActionsButton",
        },
      ],
      routeQuotationList: [],
      driverID: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getRouteQuotationList(params) {
      bus.$emit("showLoader", true);
      this.$api.route_quotation
        .getRouteQuotationList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeQuotationList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    addRouteQuotation() {
      this.$router.push("/app/admin/route_quotation/create");
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
    viewRouteQuotationDetails(id) {
      this.$router.push(`/app/admin/route_quotation/detail/${id}`);
    },
  },
};
</script>

<style></style>