var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Route Quotation`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"routeQuotationResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"routeQuotationSubmitBtn","small":"","customClass":"rounded-lg","disabled":!_vm.isValid || _vm.file == null},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',{},[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Route Quotation Info ")])]),_c('v-card-subtitle',[_c('h5',{staticClass:"text-caption light_grey--text"},[_vm._v(" All field(s) with (*) are mandatory ")])]),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"routeQuotationForm",attrs:{"id":"routeQuotationForm","name":"routeQuotationForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"route_type","name":"route_type","label":"Select Route Type *","itemsList":_vm.routeTypeOptions,"itemText":"name","itemValue":"value","rules":[(val) => !!val || 'Route Type is required'],"error-messages":_vm.formErrors && _vm.formErrors.route_type
                      ? _vm.formErrors.route_type
                      : '',"isSearchRequired":false},on:{"change":function($event){_vm.formErrors && _vm.formErrors.route_type
    ? delete _vm.formErrors.route_type
    : ''}},model:{value:(_vm.routeQuotationDetails.route_type),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "route_type", $$v)},expression:"routeQuotationDetails.route_type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{ref:"location_address",staticClass:"rounded-lg",attrs:{"id":"location_address","name":"location_address","placeholder":"Location Address *","hide-details":"auto","error-messages":_vm.formErrors && _vm.formErrors.location_address
                      ? _vm.formErrors.location_address
                      : '',"required":true,"dense":"","outlined":"","rules":[
  (val) => !!val || 'Location Address is required',
]},on:{"input":function($event){_vm.formErrors && _vm.formErrors.location_address
    ? delete _vm.formErrors.location_address
    : ''}},model:{value:(_vm.routeQuotationDetails.location_address),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "location_address", $$v)},expression:"routeQuotationDetails.location_address"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","name":"latitude","id":"latitude","label":"Latitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Latitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.coordinates
                      ? _vm.formErrors.coordinates
                      : '',"outlined":""},model:{value:(_vm.coordinates.latitude),callback:function ($$v) {_vm.$set(_vm.coordinates, "latitude", _vm._n($$v))},expression:"coordinates.latitude"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","id":"longitude","name":"longitude","label":"Longitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Longitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.coordinates
                      ? _vm.formErrors.coordinates
                      : '',"outlined":""},model:{value:(_vm.coordinates.longitude),callback:function ($$v) {_vm.$set(_vm.coordinates, "longitude", _vm._n($$v))},expression:"coordinates.longitude"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseSelect',{attrs:{"id":"vehicle_types","name":"vehicle_types","label":"Vehicle Type *","multiple":true,"itemsList":_vm.vehicleTypeList,"itemText":"name","itemValue":"value","rules":[(val) => !!val || 'Vehicle Type is required'],"error-messages":_vm.formErrors && _vm.formErrors.vehicle_types
                      ? _vm.formErrors.vehicle_types
                      : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.vehicle_types
    ? delete _vm.formErrors.vehicle_types
    : ''}},model:{value:(_vm.routeQuotationDetails.vehicle_types),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "vehicle_types", $$v)},expression:"routeQuotationDetails.vehicle_types"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTimeInput',{attrs:{"id":"start_time","name":"start_time","required":true,"label":"Trip Start Time","error-messages":_vm.formErrors && _vm.formErrors.start_time
                      ? _vm.formErrors.start_time
                      : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.start_time
    ? delete _vm.formErrors.start_time
    : ''}},model:{value:(_vm.routeQuotationDetails.start_time),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "start_time", $$v)},expression:"routeQuotationDetails.start_time"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"duration","name":"duration","required":true,"label":"Trip Duration(Min)","error-messages":_vm.formErrors && _vm.formErrors.duration
                      ? _vm.formErrors.duration
                      : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.duration
    ? delete _vm.formErrors.duration
    : ''}},model:{value:(_vm.routeQuotationDetails.duration),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "duration", $$v)},expression:"routeQuotationDetails.duration"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"vehicle_fill_ratio","name":"vehicle_fill_ratio","type":"number","required":true,"label":"Vehicle Fill Ratio(%)","error-messages":_vm.formErrors && _vm.formErrors.vehicle_fill_ratio
                      ? _vm.formErrors.vehicle_fill_ratio
                      : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.vehicle_fill_ratio
    ? delete _vm.formErrors.vehicle_fill_ratio
    : ''}},model:{value:(_vm.routeQuotationDetails.vehicle_fill_ratio),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "vehicle_fill_ratio", $$v)},expression:"routeQuotationDetails.vehicle_fill_ratio"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('BaseTextInput',{attrs:{"id":"per_stop_processing_time","name":"per_stop_processing_time","type":"number","required":true,"label":"Stop Processing Time(Min)","error-messages":_vm.formErrors && _vm.formErrors.per_stop_processing_time
                      ? _vm.formErrors.per_stop_processing_time
                      : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.per_stop_processing_time
    ? delete _vm.formErrors.per_stop_processing_time
    : ''}},model:{value:(_vm.routeQuotationDetails.per_stop_processing_time),callback:function ($$v) {_vm.$set(_vm.routeQuotationDetails, "per_stop_processing_time", $$v)},expression:"routeQuotationDetails.per_stop_processing_time"}})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('h4',{staticClass:"text-body-1 font-weight-bold black--text"},[_vm._v(" Stops & Passengers ")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-space-between align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-file-input',{staticClass:"mr-4",attrs:{"id":"fileInputBulkUpload","hide-details":"","required":true,"dense":"","label":"Upload Sheet","prepend-inner-icon":"mdi-attachment mdi-rotate-90","prepend-icon":"","outlined":""},on:{"change":function($event){return _vm.getFileData($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('BaseButton',{attrs:{"id":"downloadSampleExcelBtn","small":"","customClass":"rounded-lg"},on:{"click":function($event){return _vm.downloadExcel()}}},[_vm._v(" Download Sample File")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('AgGridVue',{staticClass:"ag-theme-alpine",staticStyle:{"width":"100%","height":"450px"},attrs:{"grid-options":_vm.gridOptions,"column-defs":_vm.headers,"context":_vm.context,"row-data":_vm.dataObject},on:{"grid-ready":_vm.onGridReady}})],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }