<template>
  <v-app>
    <BaseLeftNavigation />
    <BaseHeader @logout="logout" />
    <v-main style="position: relative" class="light_background">
      <v-container
        fluid
        class="pa-0"
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import BaseHeader from "@/components/BaseComponents/BaseHeader.vue";
import BaseLeftNavigation from "@/components/BaseComponents/BaseLeftNavigation.vue";
// import { bus } from "@/main.js";

export default {
  name: "AdminLayout",
  components: {
    BaseHeader,
    BaseLeftNavigation,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/app/login");
    },
    checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/app/login") {
          this.$router.push({ path: "/app/login" });
        }
      } else {
        if (this.$route.fullPath == "/app") {
          this.$router.push({ path: "/app/admin" });
        }
        // if (["/app", "/app/admin"].indexOf(this.$route.fullPath) > -1) {
        //   this.$router.push({ path: "/app/admin/dashboard" });
        // }
      }
    },
  },
  async mounted() {
    if (window.google == undefined) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMAP_KEY,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"],
      });
      await loader.load();
    }
  },
  beforeDestroy() {
    this.checkIfLoggedIn();
  },
};
</script>

<style>
</style>
