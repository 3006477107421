<template>
  <div style="position: relative">
    <div
      v-bind="$attrs"
      :id="mapId"
      :style="{ width: mapWidth, height: mapHeight }"
    ></div>

    <div v-if="isDrawable">
      <v-btn
        v-if="isDeletable"
        depressed
        color="error"
        small
        class="Delete-Button"
        @click="deleteShape()"
      >
        <v-icon dark> mdi-delete-outline </v-icon>
      </v-btn>
    </div>

    <div v-if="showLocationName && locationName" class="Location-Name">
      <span>Location Name: </span>
      <span>{{ locationName }}</span>
    </div>

    <div class="mapInfo" v-if="hasMapInfo">
      <v-menu bottom offset-y v-model="menu">
        <template v-slot:activator="{ attrs }">
          <v-btn
            color="white"
            x-small
            class="btn-box"
            v-bind="attrs"
            depressed
            v-on="{ ...menu }"
            @click="menu = true"
          >
            <v-icon small color="primary" v-if="!menu"
              >mdi-information-variant</v-icon
            >
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-card>
          <slot name="mapInfo" />
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { iconsMap } from "@/assets/map/mapIcons.js";
import { bus } from "@/main";
// import { generateListOfHtmlTag, loadGoogleInstance } from "@/utils/functions";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";

export default {
  props: {
    mapId: {
      type: String,
      default: "googleMap",
    },
    isDrawable: {
      type: Boolean,
      default: false,
    },
    mapWidth: {
      type: String,
      default: "auto",
    },
    mapHeight: {
      type: String,
      default: "auto",
    },
    showLocationName: {
      type: Boolean,
      default: false,
    },
    hasMapInfo: {
      type: Boolean,
      default: false,
    },
    clusterMarkers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconsMap,
      menu: false,
      map: null,
      bounds: null,
      directionsService: null,
      drawingManager: null,
      lat: 24.349418127166604,
      lng: 46.32846275834888,
      polyOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00FF00",
        fillOpacity: 0.35,
        editable: true,
      },
      selectedShape: null,
      coordinates: null,
      markersArray: [],
      multiPolygonInstances: [],
      isDeletable: false,
      locationName: null,
      overLayingMarkers: [],
      disabledMultiPolygon: [],
      drawingManager: null,
    };
  },
  methods: {
    initMap(center = null) {
      // loadGoogleInstance();
      let interval = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(interval);
          this.initBounds();
          if (!center) {
            center = new window.google.maps.LatLng(this.lat, this.lng);
          }
          this.map = new window.google.maps.Map(
            document.getElementById(this.mapId),
            {
              zoom: 6,
              center: center,
              disableDefaultUI: true,
              keepSpiderfied: true,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
            }
          );

          this.drawingManager = new window.google.maps.drawing.DrawingManager({
            map: this.map,
            polygonOptions: this.polyOptions,
            rectangleOptions: this.polyOptions,
            drawingControl: this.isDrawable,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_TOP,
              editable: this.isDrawable,
              drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON,
                window.google.maps.drawing.OverlayType.RECTANGLE,
              ],
            },
          });
          window.google.maps.event.addListener(
            this.drawingManager,
            "overlaycomplete",
            this.overlayCompleteCallback.bind(this)
          );
          this.$emit("mapReady", this.map);
        }
      }, 100);
    },
    initBounds() {
      this.bounds = new window.google.maps.LatLngBounds();
    },
    fitMapBounds() {
      this.map.fitBounds(this.bounds);
    },
    setPlannedRoute(routeDetail = {}) {
      //===== Pointers ===============================================================
      if (Array.isArray(routeDetail.pointers)) {
        this.setDroppingLocation(routeDetail.pointers);
      }
      //===== Route ==================================================================
      if (routeDetail.route?.features?.length) {
        this.setFeaturesRoute(routeDetail.route.features);
      }
    },
    setDroppingLocation(locationList = [], iconType = "other") {
      if (this.bounds && this.bounds.length == 0) {
        this.bounds = new google.maps.LatLngBounds();
      }
      locationList.forEach((obj, index) => {
        let html;
        html = `<ul class="ma-0 pa-1">`;

        html += this.getHtmlListTitle(
          `${obj.reference_number || obj.name || obj.location_name}`
        );

        // html += this.getHtmlList("Location ID", obj.location_id);

        html += this.getHtmlList("Total Passengers", obj.total_passengers);

        html += this.getHtmlList("Address", obj.address);

        html += this.getHtmlList("Status", obj.status);

        html += this.getHtmlList("Customer Name", obj.customer_name);

        html += this.getHtmlList("Customer Contact No", obj.contact_number);

        html += this.getHtmlList("Voyage ID", obj.voyage_id);

        html += this.getHtmlList("Unit Name", obj.unit_name);

        html += this.getHtmlList("Delivery Window", obj.delivery_window);

        html += this.getHtmlList(
          "ETA",
          obj.status == "pickedup" ? obj.eta : null
        );

        html += this.getSimpleList("Reject Reason", obj.rejection_reason_list);

        html = html + `</ul>`;

        if (obj.location_type) {
          iconType = obj.location_type;
        }

        if (obj && obj.coordinates && obj.coordinates) {
          // iconType = "order"
          // this.oms = new OverlappingMarkerSpiderfier(this.map, {
          //   markersWontMove: true,
          //   markersWontHide: true,
          //   basicFormatEvents: true,
          // });

          let markerData = {
            position: {
              lat: obj.coordinates.latitude,
              lng: obj.coordinates.longitude,
            },
            map: this.map,
            animation: google.maps.Animation.DROP,
          };

          if (obj.status) {
            markerData.icon = {
              url: this.iconsMap[obj.status.toLowerCase()],
              origin: new google.maps.Point(0, -4),
              anchor: new google.maps.Point(20, 20),
              scaledSize: new google.maps.Size(25, 40),
            };
          } else {
            markerData.icon = {
              url:
                iconType != "other"
                  ? this.iconsMap[iconType]
                  : this.iconsMap["other"],
            };
          }

          if (obj.sequence_number) {
            markerData.label = {
              color:
                iconType == "customer" ||
                iconType == "Off Loading" ||
                iconType == "Order"
                  ? "black"
                  : "white",
              fontWeight: "bold",
              fontSize: "14px",
              text: obj.sequence_number.toString(),
            };
          }

          let infowindow = new google.maps.InfoWindow({ maxWidth: 350 });
          const marker = new google.maps.Marker(markerData); // markerData works here as a LatLngLiteral

          marker.addListener("mouseover", () => {
            infowindow.setContent(html);
            infowindow.open(this.map, marker);
          });
          marker.addListener("mouseout", () => {
            infowindow.close();
          });
          this.markersArray.push(marker);
          this.bounds.extend(markerData.position);
          if (this.clusterMarkers && locationList.length == index + 1) {
            this.overLayingMarkers = new OverlappingMarkerSpiderfier(this.map, {
              markersWontMove: true,
              markersWontHide: true,
              basicFormatEvents: true,
            });
            this.overlappingMakerShow();
          }
        }
      });
      // this.map.fitBounds(this.bounds);
    },
    setFeaturesRoute(featuresList = []) {
      if (featuresList.length) {
        setTimeout(() => {
          featuresList.forEach((route) => {
            this.map.data.addGeoJson(route);
            if (route && route.geometry?.coordinates?.length > 0) {
              route.geometry.coordinates.forEach((p) => {
                this.bounds.extend(new google.maps.LatLng(p[1], p[0]));
              });
            }
          });
          this.map.data.setStyle((feature) => {
            var color = "#3498eb";
            return {
              fillColor: color,
              strokeWeight: 4,
              strokeColor: color,
            };
          });
          this.map.fitBounds(this.bounds);
        }, 100);
      }
    },
    setRoute(route, clear = false) {
      let routes = [];
      if (route && route.length > 0) {
        route.forEach((item) => {
          routes.push({
            lat: parseFloat(item[1]),
            lng: parseFloat(item[0]),
          });
        });
        this.actualRoute = new google.maps.Polyline({
          path: routes,
          geodesic: true,
          strokeColor: "#009EF7",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        this.actualRoute.setMap(this.map);
      }
    },
    clearRoute() {
      if (this.actualRoute) {
        this.actualRoute.setMap(null);
      }
    },
    getHtmlListTitle(title) {
      return `<li class="pb-2">
                  <span class="text-primary text-subtitle-1 font-weight-bold text-capitalize">${
                    title ? title : "Details"
                  }</span>
              </li>`;
    },
    getHtmlList(key, value) {
      if (value) {
        return ` <li class="pb-1 ma-0">
                   <span class="text-primary text-caption font-weight-bold text-capitalize">${key.replaceAll(
                     "_",
                     " "
                   )} :</span>
                   <span class="text-primary text-caption"> ${value}</span>
                </li>`;
      } else {
        return "";
      }
    },
    getSimpleList(title, list) {
      if (list && list.length > 0) {
        let html = "";
        html += ` <li class="pa-0 ma-0">
                    <hr style="opacity:0.3" class="mb-1 mt-1">
                   <span class="text-primary text-caption font-weight-bold text-capitalize">${title} </span>
                  `;
        for (let i = 0; i < list.length; i++) {
          html += `<p class="text-primary text-caption pa-0 ma-0"> ${list[0]}</p>`;
        }
        html += "</li>";
        return html;
      } else {
        return "";
      }
    },
    setLocation(obj = {}, type = "Driver") {
      if (
        obj &&
        obj.coordinates &&
        obj.coordinates.latitude &&
        obj.coordinates.longitude
      ) {
        let html = `<ul class="ma-0 pa-1">`;

        html += this.getHtmlListTitle(
          type == "driver_live_location"
            ? "Driver Location"
            : "Driver Home Location"
        );
        html += this.getHtmlList("name", obj.name);
        html += this.getHtmlList("Time Stamp", obj.timestamp);
        html += this.getHtmlList("Address", obj.address);
        html += `</ul>
                    `;

        let markerData = {
          map: this.map,
          animation: google.maps.Animation.DROP,
          icon: {
            url: this.iconsMap[type == "driver" ? "driverHome" : "driver"],
          },
        };

        if (type == "driver_live_location") {
          markerData.position = {
            lat: obj.coordinates.latitude,
            lng: obj.coordinates.longitude,
          };
        } else {
          markerData.position = {
            lat: obj.location[0],
            lng: obj.location[1],
          };
        }
        let infowindow = new google.maps.InfoWindow({ maxWidth: 350 });
        const marker = new google.maps.Marker(markerData);
        marker.addListener("mouseover", () => {
          infowindow.setContent(html);
          infowindow.open(this.map, marker);
        });
        marker.addListener("mouseout", () => {
          infowindow.close();
        });
        this.markersArray.push(marker);
      }
    },
    convertToGeoJSON(coordinates = null) {
      if (!coordinates) {
        coordinates = this.coordinates;
      }
      if (!coordinates || coordinates.length == 0) {
        return false;
      } else {
        let GeoJSON = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [coordinates],
          },
          properties: {},
        };
        return GeoJSON;
      }
    },
    setEditablePolygon(path, coordinates, isEditable = true) {
      this.isDeletable = isEditable;
      this.selectedShape = new window.google.maps.Polygon({
        paths: path,
        strokeColor: "red",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "yellow",
        fillOpacity: 0.6,
        editable: isEditable,
      });
      this.selectedShape.setMap(this.map);
      this.coordinates = coordinates;
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });
      // this.selectedShape.getPath();
      window.google.maps.event.addListener(
        this.selectedShape.getPath(),
        "insert_at",
        () => {
          this.coordinates = [];
          this.selectedShape
            .getPath()
            .getArray()
            .forEach((ele) => this.coordinates.push([ele.lng(), ele.lat()]));

          this.coordinates.push(this.coordinates[0]);
        }
      );
      window.google.maps.event.addListener(
        this.selectedShape.getPath(),
        "set_at",
        () => {
          this.coordinates = [];
          this.selectedShape
            .getPath()
            .getArray()
            .forEach((ele) => this.coordinates.push([ele.lng(), ele.lat()]));

          this.coordinates.push(this.coordinates[0]);
        }
      );
      if (path && path.length > 0) {
        path.forEach((p) => {
          this.bounds.extend(new window.google.maps.LatLng(p.lat, p.lng));
        });
      }
      this.map.fitBounds(this.bounds);
    },
    getDirections(options) {
      return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
          if (window.google.maps && this.map) {
            clearInterval(interval);
            this.directionsService = new window.google.maps.DirectionsService();
            this.directionsService.route(options, (response, status) => {
              if (status !== "OK") {
                reject(new Error("Could not fetch directions."));
              }
              resolve(response);
            });
          }
        }, 500);
      });
    },
    /**
     * @description Generate GeoJson from drawing object or array of object
     * @param { Object | Array } lineString Object must have key "geometry" For any additional properties use key "properties" that must be JSON object
     * @returns FeatureCollection
     */
    generateGeoJsonFromLineString(lineString) {
      let geoJson = {
        features: [],
        type: "FeatureCollection",
      };
      if (Array.isArray(lineString)) {
        let index = 0;
        while (geoJson.features.length != lineString.length) {
          geoJson.features.push({
            ...lineString[index],
            type: "Feature",
          });
          index++;
        }
      } else {
        geoJson.features.push({
          ...lineString,
          type: "Feature",
        });
      }
      return geoJson;
    },
    generateLineStringFromRoute(path) {
      return new Promise((resolve) => {
        let lineString = {
          coordinates: [],
          type: "LineString",
        };
        path.overview_path.forEach((p) => {
          lineString.coordinates.push([p.lng(), p.lat()]);
          if (path.overview_path.length == lineString.coordinates.length) {
            resolve(lineString);
          }
        });
      });
    },
    async removeAllMarkers() {
      while (this.markersArray.length != 0) {
        this.markersArray[0].setMap(null);
        this.markersArray.splice(0, 1);
      }
    },
    overlayCompleteCallback(event) {
      this.setSelection(event);
      if (event.type == "polygon") {
        window.google.maps.event.addListener(
          this.selectedShape.overlay.getPath(),
          "insert_at",
          () => {
            this.setSelection(event);
          }
        );
        window.google.maps.event.addListener(
          this.selectedShape.overlay.getPath(),
          "set_at",
          () => {
            this.setSelection(event);
          }
        );
      } else {
        window.google.maps.event.addListener(
          this.selectedShape.overlay,
          "bounds_changed",
          () => {
            this.setSelection.bind(this);
          }
        );
      }
      this.isDeletable = true;
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    },
    setSelection(shape) {
      this.clearSelection();
      this.selectedShape = shape;
      if (shape.overlay.getEditable() == false) {
        shape.overlay.setEditable(true);
      }
      this.constructCordinates(this.selectedShape.type);
    },
    clearSelection() {
      if (this.selectedShape) {
        if (this.selectedShape.overlay) {
          this.selectedShape.overlay.setEditable(false);
        }
        if (this.selectedShape) {
          this.selectedShape.setMap(null);
        }
        this.selectedShape = null;
      }
    },
    clearGeoJson() {
      if (this.map !== null) {
        this.map.data.forEach((feature) => {
          this.map.data.remove(feature);
        });
      }
    },
    clearMarker() {
      if (this.clusterMarkers && this.markerCluster) {
        this.markerCluster = null;
      }
      while (this.markersArray.length) {
        const marker = this.markersArray[0];
        marker.setMap(null);
        this.markersArray.splice(0, 1);
      }
      this.initBounds();
    },
    constructCordinates(shapeType) {
      this.coordinates = [];
      if (shapeType == "polygon") {
        this.selectedShape.overlay
          .getPath()
          .getArray()
          .forEach((ele) => this.coordinates.push([ele.lng(), ele.lat()]));
        this.coordinates.push(this.coordinates[0]);
      } else {
        let bounds = this.selectedShape.overlay.getBounds();
        this.coordinates = [
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
        ];
      }
    },
    async addGeoCollection(collection, reset = false) {
      if (reset) {
        await this.removeGeoJson();
      }
      if (this.map && this.map.data) {
        this.map.data.addGeoJson(collection);
        this.showLocationInfo();
      }
    },
    addFeatures(features = []) {
      let interval = setInterval(() => {
        if (this.map !== null) {
          clearInterval(interval);
          features.forEach((route) => {
            this.map.data.addGeoJson(route);
            if (
              route &&
              route.geometry &&
              route.geometry.coordinates &&
              route.geometry.type == "LineString" &&
              route.geometry.coordinates.length > 0
            ) {
              route.geometry.coordinates.forEach((p) => {
                this.bounds.extend(new google.maps.LatLng(p[1], p[0]));
              });
            } else {
              route.geometry.coordinates[0].forEach((p) => {
                this.bounds.extend(new google.maps.LatLng(p[1], p[0]));
              });
            }
            this.fitMapBounds();
          });
        }
      });
      this.map.data.setStyle(() => {
        let color = "#3498eb";
        return {
          fillColor: color,
          strokeWeight: 4,
          strokeColor: color,
        };
      });
      // this.map.fitBounds(this.bounds);
    },
    // processPoints(geometry, callback, thisArg) {
    //   console.log("fired");
    //   if (geometry instanceof window.google.maps.LatLng) {
    //     callback.call(thisArg, geometry);
    //   } else if (geometry instanceof window.google.maps.Data.Point) {
    //     callback.call(thisArg, geometry.get());
    //   } else {
    //     geometry.getArray().forEach((g) => {
    //       this.processPoints(g, callback, thisArg);
    //     });
    //   }
    // },
    async addMultipleMarkers(
      objectList,
      typeKey = "default",
      hasInfoWindow = false,
      keysToIgnore = []
    ) {
      let interval = setInterval(() => {
        if (this.map !== null) {
          clearInterval(interval);
          this.removeAllMarkers();
          if (objectList.length > 0) {
            let i = 0;
            while (objectList.length != this.markersArray.length) {
              let obj = objectList[i];
              i++;
              let marker = null;
              if ("is_planned" in obj) {
                marker = this.addMarker(
                  obj,
                  obj.is_planned ? "planned_order" : "unplanned_order"
                );
              } else {
                marker = this.addMarker(obj, typeKey);
              }

              if (hasInfoWindow) {
                this.createMarkerInfoWindow(marker, obj, [
                  "coordinates",
                  "id",
                  ...keysToIgnore,
                ]);
              }
              this.overLayingMarkers = new OverlappingMarkerSpiderfier(
                this.map,
                {
                  markersWontMove: true,
                  markersWontHide: true,
                  basicFormatEvents: true,
                }
              );
              if (this.clusterMarkers && objectList.length - 1 == i) {
                this.overlappingMakerShow();
              }
            }
          }
        }
      }, 100);
    },
    createMarkerInfoWindow(marker, infoObj, keysToIgnore = []) {
      let html = "";
      let infoWindow = new window.google.maps.InfoWindow({ maxWidth: 350 });
      if (typeof infoObj == typeof {}) {
        for (const key in infoObj) {
          if (keysToIgnore.indexOf(key) == -1) {
            if (infoObj[key]) {
              html = html + generateListOfHtmlTag(key, infoObj[key]);
            }
          } else {
            continue;
          }
        }
        marker.addListener("mouseover", () => {
          infoWindow.setContent(html);
          infoWindow.open(this.map, marker);
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      }
    },
    setCustomMaker(data = {}, keysToIgnore = []) {
      let position = {};
      if (Array.isArray(data.coordinates)) {
        if (
          data.coordinates[1] >= -90 &&
          data.coordinates[1] <= 90 &&
          data.coordinates[0] >= -180 &&
          data.coordinates[0] <= 180
        ) {
          position = {
            lat: data.coordinates[1],
            lng: data.coordinates[0],
          };
        } else {
          bus.$emit("showToastMessage", {
            message: "Invalid coordinates provided!",
            color: "error",
          });
          return;
        }
      } else {
        if (
          data.coordinates &&
          data.coordinates.latitude >= -90 &&
          data.coordinates.latitude <= 90 &&
          data.coordinates.longitude >= -180 &&
          data.coordinates.longitude <= 180
        ) {
          position = {
            lat: data.coordinates.latitude,
            lng: data.coordinates.longitude,
          };
        } else {
          bus.$emit("showToastMessage", {
            message: "Invalid coordinates provided!",
            color: "error",
          });
          return;
        }
      }

      let mapCard = new google.maps.InfoWindow({ maxWidth: 470 });
      let html = ` <ul class="ma-0 pa-1">`;
      html += this.getHtmlListTitle(`${data.title ? data.title : "Details"}`);
      for (const key in data) {
        if (
          typeof data[key] != "object" &&
          key != "title" &&
          key != "id" &&
          key != "icons" &&
          key != "icon" &&
          !keysToIgnore.includes(key)
        ) {
          html += this.getHtmlList(key, data[key]);
        }
      }
      html += "</ul>";

      const marker = new google.maps.Marker({
        position: position,
        title: "type" in data ? (data.type == "drop" ? "Drop" : "Pickup") : "",
        map: this.map,
        icon: {
          url:
            data.icon || data.icons
              ? this.iconsMap[data.icon ? data.icon : data.icons]
              : this.iconsMap["other"],
        },
      });

      marker.addListener("mouseover", () => {
        mapCard.setContent(html);
        mapCard.open(this.map, marker);
      });
      marker.addListener("mouseout", () => {
        mapCard.close();
      });
      if (this.bounds == null) {
        this.bounds = new window.google.maps.LatLngBounds();
      }

      this.bounds.extend(new window.google.maps.LatLng(position));
      this.map.fitBounds(this.bounds);
      this.markersArray.push(marker);
      // this.overlappingMakerShow();
    },
    addMarker(orderObj, typeKey = "default", draggable = false) {
      if (orderObj.coordinates) {
        let position = {};
        if (Array.isArray(orderObj.coordinates)) {
          position = {
            lat: orderObj.coordinates[1],
            lng: orderObj.coordinates[0],
          };
        } else {
          position = {
            lat: orderObj.coordinates.latitude,
            lng: orderObj.coordinates.longitude,
          };
        }
        let markerConfig = {
          position: position,
          label:
            typeof orderObj.sequence_number != "string"
              ? JSON.stringify(orderObj.sequence_number)
              : orderObj.sequence_number,
          map: this.map,
          draggable: draggable,
          animation: window.google.maps.Animation.DROP,
        };
        if (orderObj[typeKey]) {
          markerConfig.icon = {
            url: this.iconsMap[orderObj[typeKey]],
          };
        } else if (typeKey != "other") {
          markerConfig.icon = {
            url: this.iconsMap[typeKey],
          };
        } else {
          markerConfig.icon = {
            url: this.iconsMap["other"],
          };
        }
        let markerObj = new window.google.maps.Marker(markerConfig);
        if (draggable) {
          markerObj.addListener("drag", (e) => {
            this.$emit("locationUpdated", e);
          });
          markerObj.addListener("dragend", (e) => {
            this.$emit("locationUpdated", e);
          });
        }
        this.markersArray.push(markerObj);
        this.map.setCenter(position);
        return markerObj;
      }
    },
    removeMarker(marker) {
      marker.setMap(null);
    },
    setMapCenter(coordinates) {
      this.map.setCenter(coordinates);
    },
    setMapZoom(level) {
      this.map.setZoom(level);
    },
    deleteShape() {
      this.isDeletable = false;
      if (this.selectedShape) {
        if (this.selectedShape.overlay) {
          this.selectedShape.overlay.setMap(null);
          this.selectedShape = null;
        } else {
          this.selectedShape.setMap(null);
          this.selectedShape = null;
        }
        if (this.drawingManager) {
          this.drawingManager.setOptions({
            drawingControl: true,
          });
        }
        this.coordinates = [];
      }
      if (this.multiPolygonInstances) {
        while (this.multiPolygonInstances.length != 0) {
          this.multiPolygonInstances[0].setMap(null);
          this.multiPolygonInstances.splice(0, 1);
        }
        if (this.drawingManager) {
          this.drawingManager.setOptions({
            drawingControl: true,
          });
        }
      }
    },
    setPropertiesOfFeature(feature) {
      let style = feature.getProperty("style");
      this.map.data.overrideStyle(feature, style);
    },
    showLocationInfo() {
      if (this.showLocationName) {
        this.map.data.addListener("mouseover", (event) => {
          this.locationName = event.feature.getProperty("name");
        });
        this.map.data.addListener("mouseout", () => {
          this.locationName = null;
        });
      }
    },
    addFeatureCallBack(feature) {
      this.setPropertiesOfFeature(feature);
      const geo = feature.getGeometry();
      if (geo) {
        this.bounds = new window.google.maps.LatLngBounds();
        // this.processPoints(geo, this.bounds.extend, this.bounds);
        this.map.setZoom(5);
        this.map.fitBounds(this.bounds);
        this.map.setCenter(this.bounds.getCenter());
      }
    },
    loadDisabledMultiPolygon(coordinates, drawingControl = true) {
      let interval = setInterval(() => {
        if (this.map) {
          clearInterval(interval);
          this.bounds = new window.google.maps.LatLngBounds();
          if (coordinates.length > 0) {
            coordinates.forEach((array, index) => {
              let path = [];
              array[0].forEach((element) => {
                path.push({ lat: element[1], lng: element[0] });
                this.bounds.extend({ lat: element[1], lng: element[0] });
              });
              this.disabledMultiPolygon[index] = new window.google.maps.Polygon(
                {
                  paths: path,
                  strokeColor: "orange",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "orange",
                  fillOpacity: 0.3,
                  editable: false,
                }
              );
              this.disabledMultiPolygon[index].setMap(this.map);
            });
          }
          if (this.drawingManager) {
            this.drawingManager.setOptions({
              drawingControl: drawingControl,
            });
          }
          this.map.fitBounds(this.bounds);
        }
      }, 100);
    },

    loadDrivers(driversList) {
      let interval = setInterval(() => {
        if (this.map !== null && driversList.length > 0) {
          clearInterval(interval);
          this.bounds = new window.google.maps.LatLngBounds();
          let isActiveDriver = null;
          for (let index = 0; index < driversList.length; index++) {
            const driver = driversList[index];
            if (
              driver &&
              driver.driver_details &&
              driver.driver_details.driver_location &&
              driver.driver_details.driver_location.latitude &&
              driver.driver_details.driver_location.latitude
            ) {
              isActiveDriver = isActiveDriver + 1;
              let infowindow = new window.google.maps.InfoWindow({
                maxWidth: 350,
              });
              let html = `<ul class="ma-0 pa-1">
                          <li><span class="text-primary text-subtitle-1 font-weight-bold">${driver.driver_details.driver_name}</span></li>
                          <li><span class="text-primary text-subtitle-1 font-weight-bold pa-0" style="opacity:0.3"><hr class="mb-4"></li>
                          <li class="d-flex pa-0 ma-0">
                            <span class="text-primary text-caption font-weight-bold pr-2">Trip Date :  </span>
                            <span class="text-primary text-caption"> ${driver.driver_details.trip_date}</span>
                          </li>
                          <li class="d-flex pa-0 ma-0">
                            <span class="text-primary text-caption font-weight-bold pr-2">Trip Reference No :  </span>
                            <span class="text-primary text-caption"> ${driver.driver_details.trip_reference_number}</span>
                          </li>
                          <li class="d-flex pa-0 ma-0">
                            <span class="text-primary text-caption font-weight-bold pr-2">Vehicle :  </span>
                            <span class="text-primary text-caption"> ${driver.driver_details.vehicle_number}</span>
                          </li>
                          <li class="d-flex pa-0 ma-0">
                            <span class="text-primary text-caption font-weight-bold pr-2">Time Stamp :  </span>
                            <span class="text-primary text-caption"> ${driver.driver_details.timestamp}</span>
                          </li>
                        </ul>`;

              const marker = new window.google.maps.Marker({
                position: {
                  lat: driver.driver_details.driver_location.latitude,
                  lng: driver.driver_details.driver_location.longitude,
                },
                map: this.map,
                icon: {
                  url: driver.type
                    ? this.iconsMap[driver.type]
                    : this.iconsMap["other"],
                },
              });

              this.bounds.extend({
                lat: driver.driver_details.driver_location.latitude,
                lng: driver.driver_details.driver_location.longitude,
              });
              // driver info window
              marker.addListener("mouseover", () => {
                infowindow.setContent(html);
                infowindow.open(this.map, marker);
              });
              marker.addListener("mouseout", () => {
                infowindow.close();
              });
              // driver route click event
              marker.addListener("click", () => {
                this.$emit("selectDriver", driver);
              });
              this.markersArray.push(marker);
            }
          }
          if (isActiveDriver == null) {
            const post = new window.google.maps.LatLng(this.lat, this.lng);
            this.map.setCenter(post);
            this.map.setZoom(8);
          } else {
            this.map.fitBounds(this.bounds);
          }
        }
      });
    },
    overlappingMakerShow() {
      this.markersArray.forEach((marker, i) => {
        google.maps.event.addListener(marker, "spider_click", function (e) {
          // 'spider_click', not plain 'click'
        });
        this.overLayingMarkers.addMarker(marker); // adds the marker to the spiderfier _and_ the map
      });
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
.mapInfo {
  left: 5px;
  top: 5px;
  position: absolute;
  z-index: 1;
}

.Delete-Button {
  min-width: 38px !important;
  position: absolute;
  top: 50px;
  right: 11px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  z-index: 1;
}

.Location-Name {
  position: absolute;
  top: 0;
  z-index: 1;
}
</style>
