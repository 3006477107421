import SupportIndex from "../pages/admin/support/index.vue";

const Support = [
  {
    path: "support",
    name: "support",
    component: SupportIndex,
  },
];

export default Support;
