import { company_shifts } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCompanyShiftList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(company_shifts.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCompanyShift(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(company_shifts.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyShiftObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company_shifts.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCompanyShiftObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${company_shifts.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateCompanyShiftObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${company_shifts.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
