<template>
  <BaseAppBar class="white">
    <template #appBarLogo>
      <v-img :src="$globalConstant.logo" />
    </template>
    <template #userNotifications>
      <v-icon color="primary"> mdi-bell </v-icon>
    </template>
    <template #profileImage>
      <v-avatar>
        <img :src="$globalConstant.profileImage" />
      </v-avatar>
    </template>
    <template #userName> John Doe </template>
    <template #listItemsMenu>
      <v-list>
        <!-- TODO: Replace the $emit events with the methods   -->
        <v-list-item :rounded="true" @click="$emit('openAccountDialog')">
          <v-icon color="primary">mdi-account</v-icon>
          <span class="pl-2 text-capitalize primary--text">profile</span>
        </v-list-item>
        <v-list-item :rounded="true" @click="$emit('openChangePassword')">
          <v-icon color="primary">mdi-lock-reset</v-icon>
          <span class="pl-2 text-capitalize primary--text"
            >change password</span
          >
        </v-list-item>
        <v-list-item :rounded="true" @click="$emit('logout')">
          <v-icon color="primary">mdi-logout</v-icon>
          <span class="pl-2 text-capitalize primary--text">Logout</span>
        </v-list-item>
      </v-list>
    </template>
  </BaseAppBar>
</template>

<script>
import BaseUserNotifications from "./BaseUserNotifications.vue";
export default {
  components: { BaseUserNotifications },
  methods: {},
};
</script>

<style>
</style>