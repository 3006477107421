import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "@/assets/colors.js";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      dark: {},
      light: {
        ...colors.solid,
        ...colors.light,
      },
    },
  },
});
