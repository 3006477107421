<template>
  <v-dialog
    v-model="bulkUploadDialog"
    persistent
    scrollable
    width="90%"
    max-width="60vw"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span
          class="text-lg-subtitle-1 text-xl-h6 text-uppercase font-weight-normal primary--text"
        >
          {{
            uploadTo == "customer_address"
              ? "Customer"
              : uploadTo.replace(/_/g, " ")
          }}
        </span>
        <v-btn icon color="primary" small @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-8">
          <v-col cols="5" class="d-flex">
            <v-file-input
              id="fileInputBulkUpload"
              v-model="file"
              hide-details
              dense
              label="Upload Document"
              prepend-inner-icon="mdi-attachment mdi-rotate-90"
              class="mr-4"
              prepend-icon=""
              outlined
              @change="getFileData($event)"
            ></v-file-input>
            <v-icon color="primary" @click="openInstructionDialog"
              >mdi-information</v-icon
            >
          </v-col>
          <v-col cols="7" class="d-flex justify-end align-center">
            <BaseButton
              id="sampleExcelFileBtn"
              class="primary"
              customClass="rounded-lg"
              small
              depressed
              @click="downloadSampleExcel()"
            >
              Download Sample Excel File
            </BaseButton>
            <!-- <BaseButton
              id="getData"
              class="primary"
              customClass="rounded-lg"
              small
              depressed
              @click="getFileData($event)"
              >Get Data</BaseButton
            > -->
            <!-- <v-btn class="primary" depressed @click="downloadSampleExcel()">
              Download Sample Excel File
            </v-btn> -->
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pt-0">
            <span> Total Records : {{ totalRecordCount }}</span>
            <span> Records with Error : {{ rowData.length }}</span>
          </v-col>
          <v-col v-if="distinctErrors.length > 0" class="py-0">
            <v-alert border="left" colored-border color="red">
              <v-row no-gutters>
                <v-col class="grow">
                  <v-list class="py-0">
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="text-grey font-weight-medium"
                        >Error(s):
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="pl-4 pt-1 text-grey"
                        v-for="(err, index) in distinctErrors"
                        :key="index"
                      >
                        {{ err }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list>
                </v-col>
                <v-col class="shrink">
                  <BaseButton
                    id="removeErrorsBulkUpload"
                    class="red white--text"
                    customClass="rounded-lg"
                    small
                    depressed
                    @click="removeAllRecordWithError"
                  >
                    Remove All With Error
                  </BaseButton>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="rowData.length > 0">
            <AgGridVue
              @grid-ready="gridReady"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :default-col-def="defaultColDef"
              :row-data="rowData"
              :context="context"
              style="width: 100%; height: 400px"
              class="ag-theme-alpine"
            >
            </AgGridVue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4 d-flex justify-end">
        <BaseButton
          id="submitDataBulkUploadBtn"
          color="primary"
          customClass="rounded-lg"
          small
          :loading="loading"
          :disabled="!file || rowData.length != 0"
          @click="submitData()"
          >Submit</BaseButton
        >
      </v-card-actions>
    </v-card>

    <Instruction
      class="pt-6"
      v-model="instructionDialog"
      :instructionList="instructionList"
    />
  </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import Instruction from "@/components/BulkUploadComponents/Instruction.vue";
import { convertToBlobAndDownload, downloadBlob } from "@/utils/functions.js";
import BulkUploadErrorTooltip from "@/components/BulkUploadComponents/BulkUploadErrorTooltip.vue";
import RemoveRowButtonBulkUpload from "@/components/BulkUploadComponents/RemoveRowButtonBulkUpload.vue";
import BaseFileUpload from "@/components/BaseComponents/BaseFileUpload.vue";
import { bus } from "@/main";

export default {
  components: {
    AgGridVue,
    Instruction,
    BulkUploadErrorTooltip,
    RemoveRowButtonBulkUpload,
    BaseFileUpload,
  },
  props: {
    value: Boolean,
    uploadTo: {
      required: true,
      type: [String, null],
    },
    hasBulkActions: { type: Boolean, default: false },
  },
  data() {
    return {
      bulkAction: "Upload",
      loading: false,
      file: null,
      fulldata: [],
      rowData: [],
      columnDefs: [],
      _reqFields: [],
      _allFields: null,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },
      requiredHeaders: [],
      serverErrors: [],

      // For Instructions Dialog
      instructionList: [],
      instructionDialog: false,
      dataObject: [],

      nonFieldErrors: [],
    };
  },
  watch: {
    totalRecordCount(val) {
      if (val == 0) {
        this.$refs.fileUpload.removeFile();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    totalRecordCount() {
      return this.dataObject.filter((item) => Object.keys(item).length > 0)
        .length;
    },
    bulkUploadDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    distinctErrors() {
      let err = [];

      this.serverErrors
        .filter((e) => {
          return Object.keys(e).length > 0;
        })
        .map((m) => {
          return Object.keys(m).map((k) => {
            return m[k];
          });
        })
        .forEach((r) => {
          r.forEach((s) => {
            err = [...new Set([...err, ...s])];
          });
        });
      return err;
    },
  },
  methods: {
    openInstructionDialog() {
      if (this.uploadTo == "employees") {
        this.instructionList = [
          "Username",
          "First Name",
          "Last Name",
          "Contact Number",
          "Password",
          "Company Address",
          "Company",
          "Latitude",
          "Longitude",
          "Pickup Address",
          "Drop Address",
        ];
      } else if (this.uploadTo == "drivers") {
        this.instructionList = [
          "First Name",
          "Last Name",
          "Username",
          "Password",
          "Contact Number",
          "Project",
          "Vehicle Assigned",
          "Shift Start",
          "Shift End",
        ];
      } else if (this.uploadTo == "vehicles") {
        this.instructionList = [
          "Vehicle Plate Number",
          "Project",
          "Fuel Type",
          "Seating Capacity",
        ];
      } else if (this.uploadTo == "company") {
        this.instructionList = [
          "Company ID",
          "Company Name",
          "Project",
          "Contact Number",
          "Contact Email",
          "Website",
        ];
      } else if (this.uploadTo == "company_shift") {
        this.instructionList = [
          "Shift ID",
          "Shift Name",
          "Start Time",
          "End Time",
          "Company",
        ];
      } else if (this.uploadTo == "company_address") {
        this.instructionList = [
          "Location Name",
          "Company",
          "Address",
          "Latitude",
          "Longitude",
          "Shift",
        ];
      } else if (this.uploadTo == "pickup_drop_stops") {
        this.instructionList = [
          "Location Name",
          "Company",
          "Latitude",
          "Longitude",
        ];
      } else if (this.uploadTo == "customer_tag") {
        this.instructionList = ["Tag", "Location Id"];
      } else if (this.uploadTo == "vehicle_tag") {
        this.instructionList = ["Tag", "Vehicle Number"];
      } else if (this.uploadTo == "user_management") {
        this.instructionList = [
          "Username",
          "First Name",
          "Last Name",
          "Email",
          "Contact Number",
          "Password",
          "Branch Code",
        ];
      }
      this.instructionDialog = true;
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    getFileData(file) {
      console.log("file", file);
      if (file) {
        this.file = file;
        let reader = new FileReader();
        reader.onload = async () => {
          this.excelData = [];
          let fileData = reader.result;
          let wb = XLSX.read(fileData, {
            type: "binary",
            cellDates: true,
          });
          let rowData = XLSX.utils.sheet_to_row_object_array(
            wb.Sheets[wb.SheetNames[0]],
            {
              header: 0,
              defval: "",
              raw: false,
              cellNF: false,
            }
          );
          this.dataObject = rowData.map((row, index) => {
            let newObject = {};
            newObject.oldIndex = index;
            for (const [key, value] of Object.entries(row)) {
              let newKey = key
                .toString()
                .toLowerCase()
                .trim()
                .replace(/ /g, "_");
              newObject[newKey] = value.trim();
            }
            return newObject;
          });
          // console.log(this.dataObject);
          // this.rowData = this.dataObject;
        };
        reader.readAsBinaryString(file);
      } else {
        this.clearDialogData();
      }
    },
    removeErrorRow(index, oldIndex) {
      setTimeout(() => {
        this.serverErrors.splice(index, 1);
        this.rowData.splice(index, 1);

        this.dataObject.splice(
          this.dataObject.findIndex((item) => item.oldIndex == oldIndex),
          1
        );
      }, 200);
    },
    dataChange(param) {
      let field = param.colDef.field;
      let localError = this.serverErrors[param.node.rowIndex];
      if (param && param.newValue != param.oldValue) {
        delete localError[field];
      }
      let newIndex = this.dataObject.findIndex(
        (item) => item.oldIndex == param.data.oldIndex
      );
      this.dataObject[newIndex] = param.data;

      if (
        localError.non_field_errors ||
        (Object.keys(localError) && Object.keys(localError).length == 0)
      ) {
        setTimeout(() => {
          this.serverErrors.splice(param.node.rowIndex, 1);
          this.rowData.splice(param.node.rowIndex, 1);
        }, 200);
      }

      return param.newValue;
    },
    cellStyle(param) {
      if (
        param &&
        param.colDef &&
        param.colDef.field &&
        this.serverErrors[param.rowIndex].hasOwnProperty(param.colDef.field)
      ) {
        return {
          backgroundColor: "red",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
    removeAllRecordWithError() {
      while (this.rowData.length > 0) {
        this.removeDataFromRow(this.rowData[0].oldIndex, 0);
      }
    },
    removeDataFromRow(oldIndex, currentIndex) {
      this.dataObject.splice(oldIndex, 1, {});
      this.rowData.splice(currentIndex, 1);

      let data = this.dataObject.filter((obj) => Object.keys(obj).length !== 0);

      if (data.length == 0) {
        this.clearDialogData();
      }

      if (this.rowData.length == 0) {
        this.serverErrors = [];
      }
    },
    returnDownloadFunction(requestType) {
      if (requestType == "download") {
        return this.uploadTo == "pickup_drop_stops"
          ? this.bulkAction == "Upload"
            ? "getPickupDropStopsSampleSheet"
            : "getPickupDropStopsUpdateSampleSheet"
          : this.uploadTo == "company_address"
          ? this.bulkAction == "Upload"
            ? "getCompanyAddressSampleSheet"
            : "getCompanyAddressUpdateSampleSheet"
          : this.uploadTo == "company_shift"
          ? this.bulkAction == "Upload"
            ? "getCompanyShiftSampleSheet"
            : "getCompanyShiftUpdateSampleSheet"
          : this.uploadTo == "company"
          ? this.bulkAction == "Upload"
            ? "getCompanySampleSheet"
            : "getCompanyUpdateSampleSheet"
          : this.uploadTo == "employees"
          ? this.bulkAction == "Upload"
            ? "getEmployeesSampleSheet"
            : "getEmployeesUpdateSampleSheet"
          : this.uploadTo == "drivers"
          ? this.bulkAction == "Upload"
            ? "getDriversSampleSheet"
            : "getDriversUpdateSampleSheet"
          : this.uploadTo == "vehicles"
          ? this.bulkAction == "Upload"
            ? "getVehiclesSampleSheet"
            : "getVehiclesUpdateSampleSheet"
          : this.uploadTo == "customer_address"
          ? this.bulkAction == "Upload"
            ? "getCustomerAddressSampleSheet"
            : "getCustomerAddressUpdateSampleSheet"
          : this.uploadTo == "sale_order"
          ? "getSaleOrderSampleSheet"
          : this.uploadTo == "customer_tag"
          ? "getCustomerTagSampleSheet"
          : this.uploadTo == "vehicle_tag"
          ? "getVehicleTagSampleSheet"
          : this.uploadTo == "adhoc_order"
          ? "getAdhocOrderSampleSheet"
          : this.uploadTo == "user_management"
          ? "getUserManagementSampleSheet"
          : "getItemSampleSheet";
      } else {
        return this.uploadTo == "pickup_drop_stops"
          ? "bulkUploadPickupDropStops"
          : this.uploadTo == "company_address"
          ? "bulkUploadCompanyAddress"
          : this.uploadTo == "company_shift"
          ? "bulkUploadCompanyShift"
          : this.uploadTo == "company"
          ? "bulkUploadCompany"
          : this.uploadTo == "employees"
          ? "bulkUploadEmployees"
          : this.uploadTo == "drivers"
          ? `bulkUploadDrivers`
          : this.uploadTo == "vehicles"
          ? `bulkUploadVehicles`
          : this.uploadTo == "customer_address"
          ? `bulk${this.bulkAction}CustomerAddress`
          : this.uploadTo == "sale_order"
          ? "bulkUploadSaleOrder"
          : this.uploadTo == "customer_tag"
          ? "bulkUploadCustomerTag"
          : this.uploadTo == "vehicle_tag"
          ? "bulkUploadVehicleTag"
          : this.uploadTo == "adhoc_order"
          ? "bulkUploadAdhocOrder"
          : this.uploadTo == "user_management"
          ? "bulkUploadUserManagement"
          : `bulk${this.bulkAction}Item`;
      }
    },
    downloadSampleExcel() {
      this.$api.bulkUpload[this.returnDownloadFunction("download")]()
        .then((res) => {
          convertToBlobAndDownload(
            res.data,
            `Sample ${
              this.uploadTo != "customer_address"
                ? this.uploadTo.replace(/_/g, " ")
                : "Customer"
            } excel file.xlsx`
          );
          bus.$emit("showToastMessage", {
            message: "File downloaded Successfully! ",
            color: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showToastMessage", {
            message: "Can't download File ",
            color: "error",
          });
        });
    },
    submitData() {
      // this.loading = true;
      this.serverErrors = [];
      if (this.dataObject && this.dataObject.length > 0) {
        let payload = this.dataObject.filter(
          (item) => Object.keys(item).length > 0
        );
        bus.$emit("showLoader", true);
        this.$api.bulkUpload[this.returnDownloadFunction("upload")](payload)
          .then((res) => {
            // console.log("inside then");
            // console.log("res", res);
            this.$emit("refreshList");
            bus.$emit("showLoader", false);
            this.loading = false;
            bus.$emit("showToastMessage", {
              message: "Successfully Uploaded File ",
              color: "success",
            });
            this.serverErrors = [];
            this.closeDialog();
          })
          .catch(async (err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            if (err.data && !err.data.non_field_errors) {
              this.serverErrors = err.data;
              this.generateErrorRow(err.data);
            } else {
              this.nonFieldErrors = err.data.non_field_errors;
            }
            bus.$emit("showToastMessage", {
              message: "Error Uploading Sheet",
              color: "error",
            });
            this.loading = false;
          });
      } else {
        bus.$emit("showToastMessage", {
          message: "Data not found",
          color: "error",
        });
      }
    },
    generateErrorRow(errorRow) {
      let errorIndexRow = errorRow.map((item) => {
        return Object.keys(item).length > 0 ? true : false;
      });

      this.columnDefs = Object.keys(this.dataObject[0]).map((item) => {
        return {
          headerName: item.replace(/_/g, " ").toUpperCase(),
          field: item,
          editable: "oldIndex" != item ? true : false,
          hide: "oldIndex" == item || "formError" == item ? true : false,
          valueParser: this.dataChange,
          cellStyle: this.cellStyle,
        };
      });

      this.columnDefs.unshift({
        headerName: "Errors",
        pinned: "left",
        width: 90,
        cellRenderer: "BulkUploadErrorTooltip",
      });

      this.columnDefs.push({
        headerName: "Action",
        pinned: "right",
        width: 90,
        cellRenderer: "RemoveRowButtonBulkUpload",
      });
      this.serverErrors = this.serverErrors.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });

      this.rowData = this.dataObject.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });
      this.rowData = this.rowData.map((row, index) => {
        let error = this.serverErrors[index];
        return { ...row, formError: error };
      });
    },
    closeDialog() {
      this.bulkUploadDialog = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.file = null;
      this.fulldata = [];
      this.nonFieldErrors = [];
      this.rowData = [];
      this.columnDefs = [];
      this.dataObject = [];
      this._reqFields = [];
      this.serverErrors = [];
    },
  },
};
</script>

<style>
.drop-box {
  border: 2px dashed var(--v-primary-lighten3);
  height: calc(100vh - 600px);
}
</style>
