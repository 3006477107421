<template>
  <BaseDialog
    v-model="openPickupDropStopForm"
    :title="`${editMode ? 'Edit' : 'Add'} Pick Up / Drop Stop`"
    width="60vh"
    :nonFieldErrors="formErrors && formErrors.nonFieldErrors"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="pickupDropStopForm"
        id="pickupDropStopForm"
        name="pickupDropStopForm"
      >
        <v-row>
          <!-- <v-col cols="12">
            <BaseTextInput
              id="stop_id"
              name="stop_id"
              v-model="pickupDropDetails.stop_id"
              :required="true"
              label="Stop ID"
              :error-message="
                formErrors && formErrors.stop_id ? formErrors.stop_id : ''
              "
              @input="
                formErrors && formErrors.stop_id
                  ? delete formErrors.stop_id
                  : ''
              "
            ></BaseTextInput>
          </v-col> -->
          <v-col cols="12">
            <BaseTextInput
              id="location_name"
              name="location_name"
              v-model="pickupDropDetails.location_name"
              :required="true"
              label="Stop Name"
              :error-messages="
                formErrors && formErrors.location_name
                  ? formErrors.location_name
                  : ''
              "
              @input="
                formErrors && formErrors.location_name
                  ? delete formErrors.location_name
                  : ''
              "
            ></BaseTextInput>
          </v-col>

          <v-col cols="12">
            <v-text-field
              ref="address"
              id="address"
              name="address"
              placeholder="Stop Address *"
              hide-details="auto"
              v-model="pickupDropDetails.address"
              :required="true"
              dense
              outlined
              class="rounded-lg"
              :rules="[(val) => !!val || 'Stop Address is required']"
              :error-messages="
                formErrors && formErrors.address ? formErrors.address : ''
              "
              @input="
                formErrors && formErrors.address
                  ? delete formErrors.address
                  : '',
                  formErrors && formErrors.coordinates
                    ? delete formErrors.coordinates
                    : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <!-- <BaseTextInput
              :disabled="true"
              id="latitude"
              name="latitude"
              v-model="pickupDropDetails.coordinates.latitude"
              :required="true"
              label="Latitude"
              :error-messages="
                formErrors && formErrors.latitude ? formErrors.latitude : ''
              "
              @input="
                formErrors && formErrors.latitude
                  ? delete formErrors.latitude
                  : ''
              "
            ></BaseTextInput> -->
            <v-text-field
              type="text"
              name="latitude"
              label="Latitude *"
              required
              dense
              hide-details="auto"
              :disabled="true"
              :rules="[(v) => !!v || 'Latitude is Required']"
              :error-messages="
                formErrors && formErrors.coordinates
                  ? formErrors.coordinates
                  : ''
              "
              class="rounded-lg"
              v-model.number="pickupDropDetails.coordinates.latitude"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <!-- <BaseTextInput
              :disabled="true"
              id="longitude"
              name="longitude"
              v-model="pickupDropDetails.coordinates.longitude"
              :required="true"
              label="Longitude"
              :error-messages="
                formErrors && formErrors.longitude ? formErrors.longitude : ''
              "
              @input="
                formErrors && formErrors.longitude
                  ? delete formErrors.longitude
                  : ''
              "
            ></BaseTextInput> -->
            <v-text-field
              type="text"
              name="longitude"
              label="Longitude *"
              required
              dense
              hide-details="auto"
              :disabled="true"
              :rules="[(v) => !!v || 'Longitude is Required']"
              :error-messages="
                formErrors && formErrors.coordinates
                  ? formErrors.coordinates
                  : ''
              "
              class="rounded-lg"
              v-model.number="pickupDropDetails.coordinates.longitude"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <GoogleMap
              mapWidth="100%"
              mapHeight="20vh"
              ref="pickupDropForMap"
              :isDrawable="false"
              :clusterMarkers="false"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <BaseSecondaryButton
        customClass="rounded-lg"
        small
        id="pickupDropStopResetBtn"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >

      <BaseButton
        :disabled="!isValid"
        customClass="rounded-lg"
        small
        id="pickupDropSubmitBtn"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import GoogleMap from "@/components/BaseComponents/GoogleMap.vue";
import { bus } from "@/main";
export default {
  components: {
    GoogleMap,
  },
  props: {
    value: Boolean,
    editMode: {
      type: Boolean,
    },
    id: Number,
  },
  data() {
    return {
      pickupDropDetails: {
        coordinates: {},
      },
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
    };
  },
  watch: {
    openPickupDropStopForm(val) {
      if (val) {
        this.setAutoComplete();
      }

      if (val && this.id) {
        this.getPickupDropStopsObject(this.id);
      }
    },
  },
  computed: {
    openPickupDropStopForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getPickupDropStopsObject(id = null) {
      bus.$emit("showLoader", true);
      this.$api.pickup_drop_stops
        .getPickupDropStopsObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.pickupDropDetails = res.data;
          this.$refs.pickupDropForMap.addMultipleMarkers([
            {
              coordinates: this.pickupDropDetails.coordinates,
            },
          ]);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    setAutoComplete() {
      const interval = setInterval(async () => {
        if (this.$refs.address && window.google && window.google.maps) {
          clearInterval(interval);

          let input = document.getElementById("address");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["SA"],
          });

          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.pickupDropDetails.address =
                place.name + ", " + place.formatted_address;
              this.pickupDropDetails.coordinates.latitude = lat;
              this.pickupDropDetails.coordinates.longitude = lon;
            }, 100);

            let newObj = {};
            newObj = {
              coordinates: [
                place.geometry.location.lng(),
                place.geometry.location.lat(),
              ],
            };
            setTimeout(() => {
              this.$refs.pickupDropForMap.clearMarker();
              this.$refs.pickupDropForMap.addMultipleMarkers([newObj]);
            }, 100);
          });
        }
      }, 100);
    },

    resetForm() {
      this.$refs.pickupDropStopForm.reset();
      this.$refs.pickupDropStopForm.resetValidation();
      this.$refs.pickupDropForMap.clearMarker();
    },

    submitForm() {
      let data = { ...this.pickupDropDetails };

      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.pickup_drop_stops
          .createPickupDropStops(data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Pickup / Drop Stop created!",
              color: "success",
            });
            this.resetForm();
            this.openPickupDropStopForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        this.$api.pickup_drop_stops
          .updatePickupDropStopsObject({
            id: this.pickupDropDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Pickup / Drop information updated!",
              color: "success",
            });
            this.resetForm();
            this.openPickupDropStopForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      }
    },
  },
};
</script>

<style>
</style>