import { project } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getProjectList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(project.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createProject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(project.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDefaultServiceableArea(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.default_serviceable_area}`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateProjectObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${project.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateProjectObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${project.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
