<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="viewDetailsUser"
      name="viewDetailsUser"
      iconName="mdi-eye"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="View Details"
    ></BaseActionsButton>
    <BaseActionsButton
      id="editUser"
      name="editUser"
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editUser()"
    ></BaseActionsButton>
    <!-- <BaseActionsButton
      iconName="mdi-delete"
      iconColor="red"
      tooltipMessage="Delete User"
    ></BaseActionsButton> -->
  </div>
</template>

<script>
export default {
  methods: {
    editUser() {
      this.params.context.parentComponent.editUser(this.params.data.id);
    },
  },
};
</script>

<style>
</style>