import { company_addresses } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCompanyAddressesList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(company_addresses.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCompanyAddresses(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(company_addresses.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyAddressesObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company_addresses.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCompanyAddressesObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${company_addresses.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateCompanyAddressesObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${company_addresses.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
