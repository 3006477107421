<template>
  <BaseFilter
    title="Route Trip Filter"
    :isChips="isChips"
    @submit="$emit('apply')"
    @reset="$emit('reset')"
    :isIcon="true"
    color="light_color_actions"
    ><template #form>
      <v-row no-gutters>
        <v-col cols="12" lg="6" xl="6" class="">
          <BaseSelect
            dense
            hide-details
            outlined
            clearable
            label="Select Project"
            :itemsList="projectList"
            itemText="name"
            itemValue="value"
            v-model="filter.project"
            class="pl-1"
            :menu-props="{ offsetY: true }"
            @input="routeTripFiltersChanged('project', $event)"
          ></BaseSelect>
        </v-col>
      </v-row> </template
  ></BaseFilter>
</template>

<script>
export default {
  props: {
    value: Boolean,
    isChips: Boolean,
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      projectList: [
        {
          name: "PR001",
          value: "PR001",
        },
        {
          name: "PR002",
          value: "PR002",
        },
      ],
    };
  },
  methods: {
    routeTripFiltersChanged(key, value) {
      let obj = {};
      obj[key] = value;
    },
  },
};
</script>

<style>
</style>