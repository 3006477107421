<template>
  <RouteQuotationForm></RouteQuotationForm>
</template>

<script>
import RouteQuotationForm from "@/components/ModuleBasedComponents/route_quotation/RouteQuotationForm.vue";
export default {
  components: {
    RouteQuotationForm,
  },
};
</script>

<style>
</style>