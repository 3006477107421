import { driver } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getDriverList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(driver.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createDriver(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(driver.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverDetailsView(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverTripList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.base}trip_list/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateDriverObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${driver.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateDriverObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${driver.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
