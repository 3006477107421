export const version = "/api/v1/";

const employeeBase = `${version}employee/`;
const projectBase = `${version}project/`;
const driverBase = `${version}driver/`;
const vehicleBase = `${version}vehicle/`;
const companyBase = `${version}company/`;
const companyAddressBase = `${version}company_address/`;
const companyShiftBase = `${version}company_shift/`;
const pickupDropStopsBase = `${version}location_master/`;
const userBase = `${version}user/`;
const routeBase = `${version}route/`;
const tripBase = `${version}trip/`;
const tripRedundantBase = `${version}route_trip/`;
const routePlanningBase = `${version}route_plan/`;
const dashboardBase = `${version}dashboard/`;
const vehicleTypeBase = `${version}vehicle_type/`;
const routeQuotationBase = `${version}quick_plan/`;

export const route_quotation = {
  base: `${routeQuotationBase}`,
};

export const vehicle_type = {
  base: `${vehicleTypeBase}`,
};

export const employee = {
  base: `${employeeBase}`,
};

export const project = {
  base: `${projectBase}`,
  default_serviceable_area: `${projectBase}default_serviceable_area/`,
};

export const driver = {
  base: `${driverBase}`,
};

export const vehicle = {
  base: `${vehicleBase}`,
};

export const company = {
  base: `${companyBase}`,
};

export const company_addresses = {
  base: `${companyAddressBase}`,
};

export const company_shifts = {
  base: `${companyShiftBase}`,
};

export const pickup_drop_stops = {
  base: `${pickupDropStopsBase}`,
};

export const user = {
  base: `${userBase}`,
};

export const route = {
  base: `${routeBase}`,
};

export const trip = {
  base: `${tripBase}`,
};

export const tripRedundant = {
  base: `${tripRedundantBase}`,
};

export const routePlanning = {
  base: `${routePlanningBase}`,
};

export const dashboard = {
  base: `${dashboardBase}`,
};
