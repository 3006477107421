<template>
  <BaseDialog
    v-model="openVehicleTypeForm"
    :title="`${editMode ? 'Edit' : 'Add'} Vehicle Type`"
    width="50vh"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-row
        v-if="
          formErrors &&
          formErrors.non_field_errors &&
          formErrors.non_field_errors.length > 0
        "
      >
        <v-col cols="12" class="pa-0">
          <v-alert
            color="error"
            class="white--text align-center"
            dismissible
            dense
          >
            <p
              v-for="(error, i) in formErrors.non_field_errors"
              :key="i"
              class="pa-0 d-flex text-center align-center"
            >
              {{ error }}
            </p>
            <template #close>
              <v-icon @click="formErrors.non_field_errors = []" color="white"
                >mdi-close</v-icon
              >
            </template>
          </v-alert>
        </v-col>
      </v-row>

      <v-form
        v-model="isValid"
        ref="vehicleTypeForm"
        id="vehicleTypeForm"
        name="vehicleTypeForm"
      >
        <v-row>
          <v-col cols="12">
            <BaseTextInput
              id="type"
              name="type"
              v-model="vehicleTypeDetails.type"
              :required="true"
              label="Vehicle Type"
            ></BaseTextInput>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              class="rounded-lg"
              id="seating_capacity"
              name="seating_capacity"
              v-model="vehicleTypeDetails.seating_capacity"
              :required="true"
              label="Seating Capacity *"
              hide-details="auto"
              :rules="[
                (val) => !!val || 'Seating Capacity is required',
                (val) => val > 0 || 'Seating Capacity must be at least 1',
              ]"
              :error-messages="
                formErrors && formErrors.seating_capacity
                  ? formErrors.seating_capacity
                  : ''
              "
              @input="
                formErrors && formErrors.seating_capacity
                  ? delete formErrors.seating_capacity
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              class="rounded-lg"
              hide-details="auto"
              id="vehicle_count"
              name="vehicle_count"
              v-model="vehicleTypeDetails.vehicle_count"
              :required="true"
              label="Vehicle Count *"
              :rules="[
                (val) => !!val || 'Vehicle Count is required',
                (val) => val > 0 || 'Vehicle Count must be at least 1',
              ]"
              :error-messages="
                formErrors && formErrors.vehicle_count
                  ? formErrors.vehicle_count
                  : ''
              "
              @input="
                formErrors && formErrors.vehicle_count
                  ? delete formErrors.vehicle_count
                  : ''
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="vehicleTypeResetBtn"
        customClass="rounded-lg"
        small
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        :disabled="!isValid"
        id="vehicleTypeSubmitBtn"
        customClass="rounded-lg"
        small
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    id: Number,
  },
  data() {
    return {
      isValid: true,
      vehicleTypeDetails: {},
      formErrors: {
        non_field_errors: [],
      },
    };
  },
  computed: {
    openVehicleTypeForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    getVehicleTypeObject(id = null) {
      this.$api.vehicle_type
        .getVehicleTypeObject(id)
        .then((res) => {
          this.vehicleTypeDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    submitForm() {
      let data = { ...this.vehicleTypeDetails };

      if (!this.editMode) {
        this.$api.vehicle_type
          .createVehicleType(data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vehicle Type created!",
              color: "success",
            });
            this.resetForm();
            this.openVehicleTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data && err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        console.log("else");
        console.log(this.vehicleTypeDetails.id);
        console.log(data);
        this.$api.vehicle_type
          .updateVehicleTypeObject({
            id: this.vehicleTypeDetails.id,
            data: data,
          })
          .then(() => {
            console.log("then");
            bus.$emit("showToastMessage", {
              message: "Vehicle type information updated!",
              color: "success",
            });
            this.resetForm();
            this.openVehicleTypeForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      this.$refs.vehicleTypeForm.reset();
      this.$refs.vehicleTypeForm.resetValidation();
    },
  },
};
</script>

<style>
</style>