<template>
  <VehicleDetail></VehicleDetail>
</template>

<script>
import VehicleDetail from "@/components/ModuleBasedComponents/vehicles/VehicleDetail.vue";
export default {
  components: {
    VehicleDetail,
  },
};
</script>

<style>
</style>