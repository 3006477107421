import { route_quotation } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getRouteQuotationList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(route_quotation.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createRouteQuotation(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(route_quotation.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getRouteQuotationObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route_quotation.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRouteQuotationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route_quotation.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  exportRoute(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route_quotation.base}${id}/export_routes/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  downloadExcelFile() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route_quotation.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  }
});
