<template>
  <BaseListLayout
    ref="listLayout"
    title="Employees"
    :table-header="headers"
    :table-data="employeeList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getEmployeesList"
    name="employeeSearch"
    id="employeeSearch"
    localStorageKey="employees_columns"
  >
    <template #listAction>
      <BaseButton
        id="employeeAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addEmployee()"
        >Add Employee</BaseButton
      >
      <BaseButton
        id="employeeBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Employees</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
      ></BaseRefreshList>
      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="employeeFilterDialog = true"
      ></BaseButton>
    </template>

    <template #dialogs>
      <EmployeesFilter
        v-model="employeeFilterDialog"
        @employeeFiltersChanged="$refs.listLayout.searchTriggered()"
      ></EmployeesFilter>

      <BulkUpload
        uploadTo="employees"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getEmployeesList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import EmployeesActionsButton from "@/components/AgGridButtons/EmployeeActionsButton.vue";
import EmployeesFilter from "@/components/ModuleBasedComponents/employees/EmployeesFilter.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
export default {
  components: {
    BaseListLayout,
    EmployeesActionsButton,
    EmployeesFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      headers: [
        {
          headerName: "Employee ID",
          field: "username",
        },
        {
          headerName: "Employee Name",
          field: "full_name",
        },
        {
          headerName: "Company",
          field: "company_name",
        },
        {
          headerName: "Company Location",
          field: "company_address",
        },
        {
          headerName: "Shift Time",
          field: "company_shift",
        },
        {
          headerName: "Home Address",
          field: "home_address",
        },
        {
          headerName: "Preferred Pickup Address",
          field: "pickup_address",
        },
        {
          headerName: "Preferred Drop Address",
          field: "drop_address",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "EmployeesActionsButton",
        },
      ],
      employeeList: [],
      showBulkUploadDialog: false,
      employeeFilterDialog: false,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getEmployeesList(params = {}) {
      let filters = localStorage.getItem("employeeFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }

      if (filters.company_id && filters.company_id.length > 0) {
        filters.company_id = filters.company_id.join(",");
      }
      if (filters.company_address && filters.company_address.length > 0) {
        filters.company_address = filters.company_address.join(",");
      }
      if (filters.ordering && filters.ordering.length > 0) {
        filters.ordering = filters.ordering.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.employees
        .getEmployeesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.employeeList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addEmployee() {
      this.$router.push("/app/admin/employees/create");
    },

    editEmployee(id) {
      this.$router.push(`/app/admin/employees/edit/${id}`);
    },

    viewDetails(id) {
      this.$router.push(`/app/admin/employees/detail/${id}`);
    },

    bulkUploadEmployees() {},
  },
};
</script>

<style>
</style>