const logo = require("@/assets/logo.png");

const itemsPerPage = 10;

const profileImage = require("@/assets/user.png");

const notificationIcon = require("@/assets/icons/notificationBellIcon.png");

const loginBackGroundLogo = require("@/assets/login-image.png");

export default {
  logo,
  itemsPerPage,
  profileImage,
  notificationIcon,
  loginBackGroundLogo,
};
