<template>
  <v-dialog
    v-model="employeeFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Employees Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="employeeFilterDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="employeeFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="pl-2">
              <BaseSelect
                id="companyFilter"
                dense
                hide-details
                outlined
                clearable
                label="Select Company"
                :itemsList="companyList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="employeeFilter.company_id"
                :menu-props="{ offsetY: true }"
                @change="
                  syncEmployeeFilters('company_id', $event),
                    getCompanyAddressesList({
                      company_id: employeeFilter.company_id,
                    })
                "
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="pl-2">
              <BaseSelect
                id="companyFilter"
                dense
                hide-details
                outlined
                clearable
                label="Select Company Addresses"
                :itemsList="companyLocationList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="employeeFilter.company_address"
                :menu-props="{ offsetY: true }"
                @change="syncEmployeeFilters('company_address', $event)"
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="pt-2 pl-2">
              <BaseSelect
                v-model="employeeFilter.ordering"
                :itemsList="employeeOrderingOptions"
                itemText="name"
                itemValue="value"
                id="employeeOrdering"
                name="employeeOrdering"
                label="Ordering"
                :multiple="true"
                :menu-props="{ offsetY: true }"
                @change="syncEmployeeFilters('ordering', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetEmployeesFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyEmployeesFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
import { employeeOrderingOptions } from "@/utils/optionsMapping.js";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      employeeOrderingOptions,
      projectList: [],
      companyList: [],
      companyLocationList: [],
      employeeFilter: {},
    };
  },
  watch: {
    employeeFilterDialog(value) {
      if (value) {
        this.getCompanyList();
        let filters = localStorage.getItem("employeeFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.employeeFilter = filters;
        if (this.employeeFilter.company_id) {
          this.getCompanyAddressesList({
            company_id: this.employeeFilter.company_id,
          });
        }
      }
    },
  },
  computed: {
    employeeFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      if (params && params.company_id && params.company_id.length > 0) {
        params.company_id = params.company_id.join(",");
      }
      params = {
        ...params,
      };
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          this.companyLocationList = res.data.map((obj) => {
            return {
              name: obj.location_name,
              value: obj.location_id,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    syncEmployeeFilters(key, value) {
      let filters = localStorage.getItem("employeeFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.employeeFilter = filters;
      localStorage.setItem("employeeFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("employeeFiltersChanged");
      this.employeeFilterDialog = false;
    },
    resetFilters(reload) {
      this.companyFilter = {};
      localStorage.removeItem("employeeFilters");
      this.$emit("employeeFiltersChanged");
      if (this.$refs.employeeFilterForm) {
        this.$refs.employeeFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>