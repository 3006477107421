import UsersIndex from "../pages/admin/users/index.vue";
import UsersCreate from "../pages/admin/users/create/index.vue";
import UsersEdit from "../pages/admin/users/edit/_id/index.vue";

const Users = [
  {
    path: "users",
    name: "users",
    component: UsersIndex,
  },
  {
    path: "users/create",
    name: "users/create",
    component: UsersCreate,
  },
  {
    path: "users/edit/:id",
    name: "users/edit/:id",
    component: UsersEdit,
  },
];

export default Users;
