<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      iconName="mdi-eye"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="View Details"
    ></BaseActionsButton>
    <BaseActionsButton
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editProject"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    editProject() {},
  },
};
</script>

<style>
</style>