<template>
  <BaseListLayout
    title="Projects"
    :table-header="headers"
    :table-data="projectList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getProjectsList"
    id="projectSearch"
    name="projectSearch"
  >
    <template #listAction>
      <BaseButton
        id="projectAddBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="addProject()"
        >Add Project</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2"
        @click="getProjectsList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import ProjectActionsButton from "@/components/AgGridButtons/ProjectActionsButton.vue";
import ProjectForm from "@/components/ModuleBasedComponents/projects/ProjectForm.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    ProjectActionsButton,
    ProjectForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openProjectForm: false,
      headers: [
        {
          headerName: "Project ID",
          field: "project_id",
        },
        {
          headerName: "Project Name",
          field: "project_name",
        },
        {
          headerName: "Address",
          field: "base_address",
        },
        {
          headerName: "Country",
          field: "country_name",
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ProjectActionsButton",
        },
      ],
      projectList: [],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.projects
        .getProjectList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.projectList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addProject() {
      this.$router.push("/app/admin/projects/create");
    },
    editProject(id) {
      this.$router.push(`/app/admin/projects/edit/${id}`);
    },
  },
};
</script>

<style>
</style>