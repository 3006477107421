import EmployeesIndex from "../pages/admin/employees/index.vue";
import EmployeesCreate from "../pages/admin/employees/create/index.vue";
import EmployeesEdit from "../pages/admin/employees/edit/_id/index.vue";
import EmployeesDetail from "../pages/admin/employees/detail/_id/index.vue";

const Employees = [
  {
    path: "employees",
    name: "employees",
    component: EmployeesIndex,
  },
  {
    path: "employees/create",
    name: "employees/create",
    component: EmployeesCreate,
  },
  {
    path: "employees/edit/:id",
    name: "employees/edit/:id",
    component: EmployeesEdit,
  },
  {
    path: "employees/detail/:id",
    name: "employees/detail/:id",
    component: EmployeesDetail,
  },
];

export default Employees;
