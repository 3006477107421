import Dashboard from "./dashboard";
import Company from "./company";
import CompanyLocations from "./company_locations";
import CompanyShifts from "./company_shifts";
import Projects from "./projects";
import Users from "./users";
import Support from "./support";
import Drivers from "./drivers";
import Vehicles from "./vehicles";
import Employees from "./employees";
import Trips from "./trips";
import Routes from "./routes";
import PickupDropStops from "./pickup_drop_stops";
import TripsRedundant from "./trips_new";
import RoutePlanning from "./route_planning";
import RouteQuotation from "./route_quotation";
import VehicleType from "./vehicle_type";

export default [
  {
    path: "",
    redirect: "/app/admin/projects",
  },
  ...Dashboard,
  ...Employees,
  ...Drivers,
  ...Vehicles,
  ...Company,
  ...CompanyLocations,
  ...CompanyShifts,
  ...Projects,
  ...Users,
  ...Routes,
  ...Support,
  ...Trips,
  ...PickupDropStops,
  ...TripsRedundant,
  ...RoutePlanning,
  ...RouteQuotation,
  ...VehicleType,
];
