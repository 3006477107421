<template>
  <v-timeline dense align-top>
    <div v-for="(route_stop, index) in route_stops" :key="index">
      <v-timeline-item small icon="mdi-check" color="primary">
        <v-row class="">
          <v-col cols="3">
            <BaseSelect
              id="stop_name"
              name="stop_name"
              v-model="route_stop.location_id"
              :required="true"
              label="Pickup Location *"
              :itemsList="locationList"
              item-text="name"
              item-value="value"
              :rules="[(val) => !!val || 'Pickup Location is required']"
              :error-messages="
                routeErrors && routeErrors.location_id
                  ? routeErrors.location_id
                  : ''
              "
              @change="
                routeErrors && routeErrors.location_id
                  ? delete routeErrors.location_id
                  : ''
              "
            ></BaseSelect>

            <div class="d-flex justify-end mt-2">
              <BaseSecondaryButton
                id="addPickupRouteBtn"
                icon="mdi-plus"
                customClass="routeButtons mr-2"
                iconColor="primary"
                iconClass="mx-1"
                x-small
                outlined
                @click="addPickUpRoute()"
              ></BaseSecondaryButton>
              <BaseSecondaryButton
                :disabled="route_stops.length == 1"
                id="removePickupRouteBtn"
                icon="mdi-minus"
                customClass="routeButtons red--text"
                iconColor="red"
                iconClass="mx-1"
                x-small
                outlined
                @click="removePickUpRoute(index)"
              ></BaseSecondaryButton>
            </div>
          </v-col>

          <!-- <v-col cols="2">
                              <BaseTimeInput
                                id="time"
                                name="time"
                                v-model="route_stop.time"
                                label="Time"
                                :required="true"
                              ></BaseTimeInput>
                            </v-col> -->

          <v-col cols="2">
            <BaseButton
              id="passengersBtn"
              icon="mdi-account"
              iconColor="black"
              outlined
              customClass="passengersClass light_button_grey rounded-lg"
              @click="addPassengers(index)"
            >
              <span
                class="black--text font-weight-bold text-body-2 text-capitalize"
              >
                ({{ route_stops.noOfPassengers }}) &nbsp;&nbsp;
                Passenger(s)</span
              >
            </BaseButton>
          </v-col>
        </v-row>
      </v-timeline-item>

      <div id="dialogs">
        <AddEmployeeToRoutes
          v-model="openAddEmployees"
          @passengersList="passengersList"
          :filters="filters"
        ></AddEmployeeToRoutes>
        <!-- :routeIndex="route_stop_index"
           -->
      </div>
    </div>
  </v-timeline>
</template>

<script>
import AddEmployeeToRoutes from "../employees/AddEmployeeToRoutes.vue";
export default {
  components: {
    AddEmployeeToRoutes,
  },
  props: {
    route_stops: {
      type: Array,
    },

    routeErrors: {
      type: Array,
    },
  },
  data() {
    return {
      locationList: [],
      openAddEmployees: false,
      totalPassengers: 0,
      filters: {},
    };
  },
  methods: {
    getPickupDropStopsList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.pickup_drop_stops.getPickupDropStopsList(params).then((res) => {
        this.locationList = res.data.map((obj) => {
          return {
            name: obj.location_name,
            value: obj.id,
          };
        });
      });
    },
    addPassengers() {
      this.openAddEmployees = true;
    },
    addPickUpRoute() {
      this.route_stops.push({});
      this.$emit("scrollToBottom");
    },
    removePickUpRoute(index) {
      this.route_stops.splice(index, 1);
    },
    passengersList(data) {
      this.totalPassengers = data.length;
      // while (this.route_stops.length) {
      //   const index = this.route_stops.pop();
      // }
      // console.log("index", index);
      // this.route_stops[index].noOfPassengers =
      //   this.totalPassengers > 0 ? this.totalPassengers : 0;
      // console.log(this.route_stops[index].noOfPassengers);
    },
  },
  mounted() {
    this.getPickupDropStopsList();
  },
};
</script>

<style>
</style>