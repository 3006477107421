<template>
  <v-navigation-drawer
    permanent
    width="270"
    class="bgColor navigation-shadow"
    style="z-index: 11 !important"
    dark
    clipped
    app
    mini-variant
    expand-on-hover
  >
    <v-list dense>
      <v-list-item v-for="(item, i) in menu" :key="i" class="pa-0 ma-0">
        <v-list-group
          v-if="item && item.items && item.items.length > 0"
          class="w-100 v-list-sub-group white--text"
        >
          <template v-slot:activator>
            <v-list-item router exact :to="item.to">
              <v-list-item-action class="mr-2">
                <v-icon class="text-h6 font-weight-light">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="text-transform: capitalize">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="background-aqua"
            router
            exact
            v-for="(subItem, index) in item.items"
            :key="index"
            :to="subItem.to"
          >
            <v-list-item-action class="mr-2">
              <v-icon class="text-h6 font-weight-light">
                {{ subItem.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="text-transform: capitalize">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :to="item.to" router exact>
          <v-list-item-action class="mr-2">
            <v-icon class="text-h6 font-weight-light">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="text-transform: capitalize">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      permission: JSON.parse(localStorage.getItem("permissions")),
      menu: [
        {
          title: "dashboard",
          icon: "mdi-view-dashboard",
          to: "/app/admin/dashboard",
          key: "project",
        },
        {
          title: "routes",
          icon: "mdi-map-marker-distance",
          to: "/app/admin/routes",
          key: "routes",
        },
        {
          title: "route planning",
          icon: "mdi-bus-marker",
          to: "/app/admin/route_planning",
          key: "routes",
        },
        {
          title: "route quotation",
          icon: "mdi-playlist-edit",
          to: "/app/admin/route_quotation",
          key: "routes",
        },
        {
          title: "trips",
          icon: "mdi-bus-side",
          to: "/app/admin/trips",
          key: "trip",
        },
        {
          title: "trips new",
          icon: "mdi-truck-fast",
          to: "/app/admin/trips_new",
          key: "trip",
        },
        {
          title: "company management",
          icon: "mdi-office-building-cog",
          items: [
            {
              title: "Company",
              icon: "mdi-office-building",
              to: "/app/admin/company",
              key: "company",
            },
            {
              title: "Company Locations",
              icon: "mdi-office-building-marker",
              to: "/app/admin/company_locations",
              key: "company",
            },
            {
              title: "Company Shifts",
              icon: "mdi-timer-marker",
              to: "/app/admin/company_shifts",
              key: "company",
            },
          ],
        },
        {
          title: "Assets",
          icon: "mdi-bus-multiple",
          items: [
            {
              title: "drivers",
              icon: "mdi-card-account-details-outline",
              to: "/app/admin/drivers",
              key: "driver",
            },
            {
              title: "vehicles",
              icon: "mdi-truck",
              to: "/app/admin/vehicles",
              key: "vehicle",
            },
            {
              title: "vehicle type",
              icon: "mdi-car-3-plus",
              to: "/app/admin/vehicle_type",
              key: "vehicle",
            },
          ],
        },
        {
          title: "Employees",
          icon: "mdi-account-tie",
          to: "/app/admin/employees",
          key: "employees",
        },
        // {
        //   title: "User Management",
        //   icon: "mdi-account-badge-outline",
        //   items: [
        {
          title: "users",
          icon: "mdi-account-badge",
          to: "/app/admin/users",
          key: "users",
        },
        //   ],
        // },
        {
          title: "Configuration",
          icon: "mdi-application-cog-outline",
          items: [
            {
              title: "project",
              icon: "mdi-map",
              to: "/app/admin/projects",
              key: "project",
            },
            {
              title: "Pickup / Drop Stops",
              icon: "mdi-bus-stop",
              to: "/app/admin/pickup_drop_stops",
              key: "route_stops",
            },
          ],
        },
        // {
        //   title: "support",
        //   icon: "mdi-archive-cog-outline",
        //   to: "/app/admin/support",
        //   key: "support",
        // },
      ],
    };
  },
  computed: {
    navMenu() {
      if (this.permission && Object.keys(this.permission).length != 0) {
        return this.menu.filter((obj) => {
          if (obj.key) {
            return this.permission[obj.key]["view"];
          } else {
            if (obj.items && obj.items.length > 0) {
              obj.items = obj.items.filter((subMenu) => {
                if (subMenu.key) {
                  return this.permission[subMenu.key]["view"];
                } else {
                  return false;
                }
              });
            }
            if (obj.items && obj.items.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        });
      } else {
        return [];
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.bgColor {
  background-color: #1e1e2d;
}
.v-list-sub-group .border-bottom-light_maroon {
  border: none !important;
  background-color: #3f3f5e;
}
.v-list-sub-group .v-list-group__header {
  border-bottom: none !important;
}
.navigation-shadow {
  &:hover {
    box-shadow: black 4px 0px 20px -10px !important;
  }
}
.v-list-sub-group {
  .v-list-group__header {
    padding: 0 !important;
  }
}
</style>
