<template>
  <v-dialog
    v-model="companyShiftDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Company Shifts Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="companyShiftDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="companyShiftFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="12" lg="12" xl="12" class="">
              <BaseSelect
                id="companyFilter"
                dense
                hide-details
                outlined
                clearable
                label="Select Company"
                :itemsList="companyList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="companyShiftFilter.company_id"
                :menu-props="{ offsetY: true }"
                @change="syncCompanyShiftFilters('company_id', $event)"
              ></BaseSelect>
            </v-col>
            <v-col cols="6" md="6" lg="6" xl="6" class="py-4">
              <BaseTimeInput
                id="fromTimeFilter"
                name="fromTimeFilter"
                label="From Time"
                v-model="companyShiftFilter.from_time"
                @change="syncCompanyShiftFilters('from_time', $event)"
              ></BaseTimeInput>
            </v-col>
            <v-col cols="6" md="6" lg="6" xl="6" class="py-4 pl-2">
              <BaseTimeInput
                id="toTimeFilter"
                name="toTimeFilter"
                label="To Time"
                v-model="companyShiftFilter.to_time"
                @change="syncCompanyShiftFilters('to_time', $event)"
              ></BaseTimeInput>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetCompanyShiftFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyCompanyShiftFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      companyList: [],
      companyShiftFilter: {},
    };
  },
  watch: {
    companyShiftDialog(value) {
      if (value) {
        this.getCompanyList();
        let filters = localStorage.getItem("companyShiftFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.companyShiftFilter = filters;
      }
    },
  },
  computed: {
    companyShiftDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    syncCompanyShiftFilters(key, value) {
      let filters = localStorage.getItem("companyShiftFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.companyShiftFilter = filters;
      localStorage.setItem("companyShiftFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("companyShiftFiltersChanged");
      this.companyShiftDialog = false;
    },
    resetFilters(reload) {
      this.companyShiftFilter = {};
      localStorage.removeItem("companyShiftFilters");
      this.$emit("companyShiftFiltersChanged");
      if (this.$refs.companyShiftFilterForm) {
        this.$refs.companyShiftFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>