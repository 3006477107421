<template>
  <v-dialog v-model="openInstructionDialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="py-2 heading">
        <span> Instructions </span>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          fab
          small
          color="heading"
          @click="openInstructionDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="12">
            <h4 class="text-lg-Subtitle-1">Note</h4>
            <h4 class="text-lg-caption mb-4">
              The header given in your excel must be same as below Mandatory
              Fields. For reference download sample excel file.
            </h4>
          </v-col>
          <v-col cols="12">
            <div>
              <h4 class="text-lg-Subtitle-1">All Fields are :</h4>
              <hr style="opacity: 0.3" class="my-2" />
              <h4 v-for="(field, i) in instructionList" :key="i">
                {{ field }}<br />
              </h4>
            </div>
          </v-col>
          <v-col>
            <!-- <div>
                <h4 class="text-lg-Subtitle-1">Mandatory Fields are :</h4>
                <h4
                  class="text-lg-caption"
                  v-for="(field, i) in requiredFields"
                  :key="i"
                >
                  {{ field.name }}<br />
                </h4>
              </div> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Instruction",
  props: {
    value: Boolean,
    openDialog: Object,
    instructionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      requiredFields: [],
    };
  },
  computed: {
    openInstructionDialog: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
};
</script>
