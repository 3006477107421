import { route } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getRoutesList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(route.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRouteObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  createRoute(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(route.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateRouteObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${route.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRouteView(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRouteStops(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route.base}${id}/stops_list/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRoute(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${route.base}${id}/map_view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
