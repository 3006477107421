import { vehicle_type } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVehicleTypeList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vehicle_type.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createVehicleType(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(vehicle_type.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleTypeObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle_type.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateVehicleTypeObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${vehicle_type.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateUserObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${user.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
