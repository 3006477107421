import { tripRedundant } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTripList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(tripRedundant.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getTripObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripRedundant.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  createTrip(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(tripRedundant.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateTripObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${tripRedundant.base}${payload.id}/update_trip/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
