import TripsIndex from "../pages/admin/trips_new/index.vue";
import TripsCreate from "../pages/admin/trips_new/create/index.vue";
import TripsEdit from "../pages/admin/trips_new/edit/_id/index.vue";

const TripsRedundant = [
  {
    path: "trips_new",
    name: "trips_new",
    component: TripsIndex,
  },
  {
    path: "trips_new/create",
    name: "trips_new/create",
    component: TripsCreate,
  },
  {
    path: "trips_new/edit/:id",
    name: "trips_new/edit/:id",
    component: TripsEdit,
  },
];

export default TripsRedundant;
