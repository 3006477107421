<template>
  <ProjectForm :editMode="true" />
</template>

<script>
import ProjectForm from "@/components/ModuleBasedComponents/projects/ProjectForm.vue";
export default {
  components: {
    ProjectForm,
  },
};
</script>

<style>
</style>