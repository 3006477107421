var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"width":"60vh","title":"Edit Company","nonFieldErrors":_vm.formErrors.non_field_errors},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"companyEditForm",attrs:{"id":"companyEditForm","name":"companyEditForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"company_id","name":"company_id","label":"Company ID","required":true,"error-messages":_vm.formErrors && _vm.formErrors.company_id ? _vm.formErrors.company_id : '',"disabled":true},on:{"input":function($event){_vm.formErrors && _vm.formErrors.company_id
                ? delete _vm.formErrors.company_id
                : ''}},model:{value:(_vm.companyFormDetails.company_id),callback:function ($$v) {_vm.$set(_vm.companyFormDetails, "company_id", $$v)},expression:"companyFormDetails.company_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"name","name":"name","label":"Company Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.name ? _vm.formErrors.name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.name ? delete _vm.formErrors.name : ''}},model:{value:(_vm.companyFormDetails.name),callback:function ($$v) {_vm.$set(_vm.companyFormDetails, "name", $$v)},expression:"companyFormDetails.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"id":"project","name":"project","label":"Select Project *","required":true,"rules":[(val) => !!val || 'Project is required'],"itemsList":_vm.projectList,"itemText":"name","itemValue":"value","error-messages":_vm.formErrors && _vm.formErrors.project ? _vm.formErrors.project : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.project
                ? delete _vm.formErrors.project
                : ''}},model:{value:(_vm.companyFormDetails.project),callback:function ($$v) {_vm.$set(_vm.companyFormDetails, "project", $$v)},expression:"companyFormDetails.project"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"background-white rounded-lg remove-number-spinners",attrs:{"outlined":"","type":"number","min":"0","step":"01","hide-details":"auto","dense":"","label":"Contact number","error-messages":_vm.formErrors && _vm.formErrors.contact_number
                ? _vm.formErrors.contact_number
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_number
                ? delete _vm.formErrors.contact_number
                : ''}},model:{value:(_vm.companyFormDetails.contact_number),callback:function ($$v) {_vm.$set(_vm.companyFormDetails, "contact_number", $$v)},expression:"companyFormDetails.contact_number"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseEmailInput',{attrs:{"id":"contact_email","name":"contact_email","label":"Email","required":true,"error-messages":_vm.formErrors && _vm.formErrors.contact_email
                ? _vm.formErrors.contact_email
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.contact_email
                ? delete _vm.formErrors.contact_email
                : ''}},model:{value:(_vm.companyFormDetails.contact_email),callback:function ($$v) {_vm.$set(_vm.companyFormDetails, "contact_email", $$v)},expression:"companyFormDetails.contact_email"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"customClass":"rounded-lg","small":"","id":"companyEditSubmitBtn"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openCompanyEditForm),callback:function ($$v) {_vm.openCompanyEditForm=$$v},expression:"openCompanyEditForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }