import { routePlanning } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getRoutePlanningList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(routePlanning.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createRoutePlan(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(routePlanning.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getRoutePlanObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePlanning.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createRoutes(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePlanning.base}${payload.id}/create_routes/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateRoutePlanObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${routePlanning.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
