<template>
  <BaseDialog
    v-model="openAddEmployeesToRoutes"
    title="Add Employees"
    width="130vh"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-row>
        <v-col cols="5">
          <v-card elevation="0">
            <v-card-title class="mx-1 px-1">
              <h4
                class="text-body-1 black--text font-weight-bold d-flex justify-start"
              >
                All Employees
              </h4>
            </v-card-title>
            <!-- TODO: Uncomment when implemented on backend   -->
            <!-- <v-card-subtitle class="mt-1 px-0 pl-2">
              <BaseTextInput
                id="employeesAddSearch"
                name="employeesAddSearch"
                label="Search Employees"
                v-model="searchEmployees"
              >
              </BaseTextInput>
            </v-card-subtitle> -->
            <v-card-text id="employeePassengerTable" class="mx-1 px-1">
              <div
                style="height: calc(50vh - 100px); border-radius: 4px"
                class="overflow-y-auto"
              >
                <table style="width: 100%" class="custom-table">
                  <tr class="light_background_data_table">
                    <td
                      class="split-table__title px-2"
                      style="width: 20px; height: 20px"
                    >
                      <v-checkbox
                        v-model="selectAll"
                        density="compact"
                        @change="allSelected"
                        @click="selectAll != selectAll"
                      ></v-checkbox>
                    </td>
                    <td>
                      <h6 class="split-table__title pl-4">Employee ID</h6>
                    </td>
                    <td>
                      <h6 class="split-table__title pl-4">Employee Name</h6>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, i) in employeesList"
                    :key="i"
                    style="background-color: white"
                  >
                    <td
                      class="split-table__data px-2"
                      style="width: 20px; height: 20px"
                    >
                      <v-checkbox
                        id="checkbox"
                        class=""
                        density="compact"
                        :value="item"
                        v-model="currentSelectedData"
                        @change="select"
                      ></v-checkbox>
                    </td>
                    <td class="split-table__data pl-4">
                      {{ item.username }}
                    </td>
                    <td class="split-table__data pl-4">{{ item.full_name }}</td>
                  </tr>
                </table>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" class="d-flex flex-column justify-center align-center">
          <div>
            <BaseSecondaryButton
              :disabled="currentSelectedData && currentSelectedData.length == 0"
              id="addPassengers"
              icon="mdi-arrow-right"
              iconClass="font-weight-bold"
              customClass="rounded-lg primary--text"
              iconColor="primary"
              :iconSize="20"
              @click="getSelectedRow"
            >
            </BaseSecondaryButton>
          </div>
        </v-col>
        <v-col cols="5">
          <v-card elevation="0">
            <v-card-title class="mx-1 px-1">
              <h4
                class="text-body-1 black--text font-weight-bold d-flex justify-start"
              >
                Passengers
              </h4>
            </v-card-title>
            <!-- TODO: Uncomment when implemented on backend   -->
            <!-- <v-card-subtitle class="mt-1 px-0 pl-2">
              <BaseTextInput
                id="passengersAddSearch"
                name="passengersAddSearch"
                label="Search Passengers"
                v-model="searchPassengers"
              >
              </BaseTextInput>
            </v-card-subtitle> -->
            <v-card-text id="PassengerTable" class="mx-1 px-1">
              <div
                style="height: calc(50vh - 100px); border-radius: 4px"
                class="overflow-y-auto"
              >
                <table style="width: 100%" class="custom-table">
                  <tr class="light_background_data_table">
                    <td>
                      <h6 class="split-table__title pl-4">Passenger ID</h6>
                    </td>
                    <td>
                      <h6 class="split-table__title pl-4">Passenger Name</h6>
                    </td>
                    <td>
                      <h6 class="split-table__title pl-4">Action</h6>
                    </td>
                  </tr>
                  <tr
                    v-if="passengersList && passengersList.length > 0"
                    v-for="(item, i) in passengersList"
                    :key="i"
                    style="background-color: white"
                  >
                    <td class="pl-4 py-2 split-table__data">
                      {{ item.username }}
                    </td>
                    <td class="pl-4 split-table__data">{{ item.full_name }}</td>
                    <td class="pl-4">
                      <v-icon color="red" small @click="removeItem(i)">
                        mdi-close
                      </v-icon>
                    </td>
                  </tr>
                </table>
              </div>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </template>
    <template #actions>
      <BaseSecondaryButton
        id="resetEmployeesToRoute"
        class="rounded-lg primary--text"
        outlined
        small
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="submitEmployeesToRoute"
        customClass="rounded-lg"
        small
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
export default {
  components: {
    AgGridVue,
  },
  props: {
    value: Boolean,
    routeIndex: Number,
  },
  data() {
    return {
      searchEmployees: null,
      searchPassengers: null,

      gridApi: null,
      gridColumnApi: null,

      defaultColDef: {
        lockPosition: true,
        flex: 1,
        resizable: true,
        sortable: true,
        autoHeight: true,
        cellStyle: {
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          display: "block",
        },
      },
      rowSelection: null,
      isRowSelectable: null,
      gridOptions: {
        // onGridSizeChanged: () => {
        //   this.gridOptions.api.sizeColumnsToFit();
        // },
        headerHeight: 40,
        rowHeight: 40,
        tooltipShowDelay: 0,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        rowSelection: "multiple",
      },
      employeesList: [],
      currentSelectedData: [],
      selectAll: [],
      employeesHeader: [
        {
          headerName: "Employee ID",
          field: "username",
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          showDisabledCheckboxes: true,
          checkboxSelection: (params) => {
            this.isRowSelectable = (params) => {
              return (
                !!params.data && !this.selectedItemsIds.includes(params.data.id)
              );
            };
            if (
              this.passengersList &&
              this.passengersList.length > 0 &&
              params &&
              params.data &&
              params.data.id &&
              this.selectedItemsIds.includes(params.data.id)
            ) {
              this.isRowSelectable = false;
              return false;
            } else {
              return true;
            }
          },
          sortable: true,
          resizable: true,
          pinned: "left",
          width: 200,
        },
        {
          headerName: "Employee Name",
          field: "full_name",
        },
      ],
      passengersList: [],
      selectAll: false,
    };
  },
  created() {
    this.rowSelection = "multiple";
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    openAddEmployeesToRoutes: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    openAddEmployeesToRoutes(val) {
      if (val) {
        // this.getEmployeesList();
      }
    },
  },
  methods: {
    resetForm() {
      this.selectAll = false;
      this.currentSelectedData = [];
      this.passengersList = [];
    },
    allSelected() {
      this.currentSelectedData = [];
      if (this.selectAll) {
        for (var employee in this.employeesList) {
          this.currentSelectedData.push(this.employeesList[employee]);
        }
      }
    },
    select() {
      this.selectAll = false;
    },
    getSelectedRow() {
      while (this.currentSelectedData.length) {
        const index = this.currentSelectedData.pop();
        const data = this.employeesList.splice(index, 1)[0];
        if (data) this.passengersList.push(data);
      }
      this.selectAll = false;
    },

    getEmployeesList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.employees
        .getEmployeesList(params)
        .then((res) => {
          this.employeesList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    autoSizeAll() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    removeItem(index) {
      this.currentSelectedData = [];
      const data = this.passengersList.splice(index, 1)[0];
      if (data) this.employeesList.unshift(data);
    },

    submitForm() {
      this.$emit("passengersList", this.passengersList);
      this.selectAll = false;
      this.passengersList = [];
      this.currentSelectedData = [];
      this.openAddEmployeesToRoutes = false;
    },
  },
  mounted() {},
};
</script>

<style>
#employeePassengerTable .ag-root-wrapper {
  border-radius: 6px;
}
.split-table__title {
  font-size: 13px !important;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  color: #181d15 !important;
  text-align: start;
  padding: 4px;
}
.split-table__data {
  font-size: 13px !important;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  color: #181d15 !important;
  text-align: start;
  padding: 1px;
}
.custom-table {
  border: 1px solid #dde2eb;
  border-collapse: collapse;
  font-size: 12px;
  padding: 1px 0px;
  border-radius: 10%;
}
.custom-table td {
  border: 1px solid #dde2eb;
  border-collapse: collapse;
  font-size: 12px;
  padding: 1px 0px;
}
</style>