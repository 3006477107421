<template>
  <BaseDialog
    v-model="openCompanyEditForm"
    width="60vh"
    title="Edit Company"
    :nonFieldErrors="formErrors.non_field_errors"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="companyEditForm"
        id="companyEditForm"
        name="companyEditForm"
      >
        <v-row>
          <v-col cols="12">
            <BaseTextInput
              id="company_id"
              name="company_id"
              label="Company ID"
              :required="true"
              v-model="companyFormDetails.company_id"
              :error-messages="
                formErrors && formErrors.company_id ? formErrors.company_id : ''
              "
              @input="
                formErrors && formErrors.company_id
                  ? delete formErrors.company_id
                  : ''
              "
              :disabled="true"
            ></BaseTextInput>
          </v-col>
          <v-col cols="12">
            <BaseTextInput
              id="name"
              name="name"
              label="Company Name"
              :required="true"
              v-model="companyFormDetails.name"
              :error-messages="
                formErrors && formErrors.name ? formErrors.name : ''
              "
              @input="
                formErrors && formErrors.name ? delete formErrors.name : ''
              "
            ></BaseTextInput>
          </v-col>
          <v-col cols="12">
            <BaseSelect
              id="project"
              name="project"
              label="Select Project *"
              :required="true"
              :rules="[(val) => !!val || 'Project is required']"
              :itemsList="projectList"
              itemText="name"
              itemValue="value"
              v-model="companyFormDetails.project"
              :error-messages="
                formErrors && formErrors.project ? formErrors.project : ''
              "
              @input="
                formErrors && formErrors.project
                  ? delete formErrors.project
                  : ''
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="12">
            <!-- <BasePhoneInput
              id="contact_number"
              name="contact_number"
              label="Contact No."
              v-model="companyFormDetails.contact_number"
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></BasePhoneInput> -->
            <v-text-field
              outlined
              class="background-white rounded-lg remove-number-spinners"
              type="number"
              min="0"
              step="01"
              hide-details="auto"
              dense
              label="Contact number"
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              v-model="companyFormDetails.contact_number"
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <BaseEmailInput
              id="contact_email"
              name="contact_email"
              label="Email"
              :required="true"
              v-model="companyFormDetails.contact_email"
              :error-messages="
                formErrors && formErrors.contact_email
                  ? formErrors.contact_email
                  : ''
              "
              @input="
                formErrors && formErrors.contact_email
                  ? delete formErrors.contact_email
                  : ''
              "
            ></BaseEmailInput>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <BaseButton
        :disabled="!isValid"
        customClass="rounded-lg"
        small
        id="companyEditSubmitBtn"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      companyFormDetails: {},
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      projectList: [],
    };
  },
  watch: {
    openCompanyEditForm(val) {
      if (val) {
        this.getProjectsList();
      }
    },
  },
  computed: {
    openCompanyEditForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    getCompanyObject(id) {
      bus.$emit("showLoader", true);
      this.$api.company
        .getCompanyObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyFormDetails = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    submitForm() {
      if (!this.$refs.companyEditForm.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = {
        id: this.companyFormDetails.id,
        data: this.companyFormDetails,
      };
      bus.$emit("showLoader", true);
      this.$api.company
        .updateCompanyObject(data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Company Updated!",
            color: "success",
          });
          this.openCompanyEditForm = false;
          this.$emit("refreshList");
          this.resetForm();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
          if (err.data.non_field_errors) {
            this.formErrors.non_field_errors = err.data.non_field_errors;
          }
        });
    },

    resetForm() {
      this.formErrors = {
        non_field_errors: [],
      };
      this.$refs.companyEditForm.reset();
      this.$refs.companyEditForm.resetValidation();
    },
  },
};
</script>

<style>
</style>