<template>
  <TripDetail></TripDetail>
</template>

<script>
import TripDetail from "@/components/ModuleBasedComponents/trips/TripDetail.vue";
export default {
  components: {
    TripDetail,
  },
};
</script>

<style>
</style>