<template>
  <EmployeesForm :editMode="true"></EmployeesForm>
</template>

<script>
import EmployeesForm from "@/components/ModuleBasedComponents/employees/EmployeesForm.vue";
export default {
  components: {
    EmployeesForm,
  },
};
</script>

<style>
</style>