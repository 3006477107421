<template>
  <BaseDialog
    v-model="openVehicleForm"
    :title="`${editMode ? 'Edit' : 'Add'} Vehicle`"
    width="50vh"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="vehicleForm"
        id="vehicleForm"
        name="vehicleForm"
      >
        <v-row>
          <v-col cols="12">
            <BaseTextInput
              id="vehicle_number"
              name="vehicle_number"
              v-model="vehicleDetails.vehicle_number"
              :required="true"
              label="Vehicle Plate No."
              :error-messages="
                formErrors && formErrors.vehicle_number
                  ? formErrors.vehicle_number
                  : ''
              "
              @input="
                formErrors && formErrors.vehicle_number
                  ? delete formErrors.vehicle_number
                  : ''
              "
            ></BaseTextInput>
          </v-col>

          <v-col cols="12">
            <BaseSelect
              id="project"
              name="project"
              v-model="vehicleDetails.project"
              :required="true"
              label="Select Project"
              :itemsList="projectList"
              :rules="[(val) => !!val || 'Project is required']"
              itemText="name"
              itemValue="value"
              :error-messages="
                formErrors && formErrors.project ? formErrors.project : ''
              "
              @change="
                formErrors && formErrors.project
                  ? delete formErrors.project
                  : ''
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="6">
            <BaseSelect
              id="fuel_type"
              name="fuel_type"
              v-model="vehicleDetails.fuel_type"
              :required="true"
              label="Fuel Type"
              :rules="[(val) => !!val || 'Fuel Type is required']"
              :itemsList="fuelTypesList"
              :isSearchRequired="false"
              itemText="name"
              itemValue="value"
              :error-messages="
                formErrors && formErrors.fuel_type ? formErrors.fuel_type : ''
              "
              @input="
                formErrors && formErrors.fuel_type
                  ? delete formErrors.fuel_type
                  : ''
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="6">
            <!-- <BaseTextInput
              id="seating_capacity"
              name="seating_capacity"
              v-model="vehicleDetails.seating_capacity"
              :required="true"
              label="Seating Capacity"
              :error-messages="
                formErrors && formErrors.seating_capacity
                  ? formErrors.seating_capacity
                  : ''
              "
              @input="
                formErrors && formErrors.seating_capacity
                  ? delete formErrors.seating_capacity
                  : ''
              "
            ></BaseTextInput> -->
            <v-text-field
              type="number"
              v-model="vehicleDetails.seating_capacity"
              id="seating_capacity"
              class="rounded-lg remove-number-spinners"
              outlined
              dense
              name="seating_capacity"
              :required="true"
              :rules="[(val) => !!val || 'Seating Capacity is required']"
              min="1"
              label="Seating Capacity *"
              :error-messages="
                formErrors && formErrors.seating_capacity
                  ? formErrors.seating_capacity
                  : ''
              "
              @input="
                formErrors && formErrors.seating_capacity
                  ? delete formErrors.seating_capacity
                  : ''
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="vehicleResetBtn"
        customClass="rounded-lg"
        small
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        :disabled="!isValid"
        id="vehicleSubmitBtn"
        customClass="rounded-lg"
        small
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    id: Number,
  },
  data() {
    return {
      isValid: true,
      projectList: [],
      fuelTypesList: [
        {
          name: "Petrol",
          value: "petrol",
        },
        {
          name: "Diesel",
          value: "diesel",
        },
      ],
      vehicleDetails: {},
      formErrors: {},
    };
  },
  watch: {
    openVehicleForm(val) {
      if (val) {
        this.getProjectsList();
      }

      if (val && this.editMode) {
        this.getVehicleObject(this.id);
      }
    },
  },
  computed: {
    openVehicleForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    getVehicleObject(id = null) {
      this.$api.vehicle
        .getVehicleObject(id)
        .then((res) => {
          this.vehicleDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },

    submitForm() {
      let data = { ...this.vehicleDetails };

      if (!this.editMode) {
        this.$api.vehicle
          .createVehicle(data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vehicle created!",
              color: "success",
            });
            this.resetForm();
            this.openVehicleForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.vehicle
          .updateVehicleObject({
            id: this.vehicleDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Vehicle information updated!",
              color: "success",
            });
            this.resetForm();
            this.openVehicleForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      this.$refs.vehicleForm.reset();
      this.$refs.vehicleForm.resetValidation();
    },
  },
};
</script>

<style>
</style>