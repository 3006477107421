<template><RouteQuotationDetail></RouteQuotationDetail></template>

<script>
import RouteQuotationDetail from "@/components/ModuleBasedComponents/route_quotation/RouteQuotationDetail.vue";
export default {
  components: {
    RouteQuotationDetail,
  },
};
</script>

<style>
</style>