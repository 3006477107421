<template>
  <BaseListLayout
    :titleSection="false"
    :gridStyle="gridStyle"
    :tableHeader="tableHeader"
    :tableData="tableData"
    :total="totalItems"
    :context="context"
    id="routesSearch"
    name="routesSearch"
    btnID="routesBtn"
  >
    <template #leftFilterSlot>
      <BaseRefreshList :isIcon="true" newClass="ml-2"></BaseRefreshList>
      <RouteTripFilter color="light_color_actions"></RouteTripFilter>
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import RouteTripFilter from "./RouteTripFilter.vue";
export default {
  components: {
    BaseListLayout,
    RouteTripFilter,
  },
  props: {
    tableHeader: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    totalItems: {
      required: true,
    },
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      // totalItems: 0,

      gridStyle: {
        width: "100%",
        height: "calc(100vh - 570px)",
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
};
</script>

<style>
</style>