<template>
  <BaseListLayout
    title="Pickup / Drop Stops"
    :table-header="headers"
    :table-data="pickUpDropList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getPickupDropStopsList"
    name="pickupDropSearch"
    id="pickupDropSearch"
  >
    <template #listAction>
      <BaseButton
        id="pickupDropAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addPickupDropStop()"
        >Add Pickup / Drop Stop</BaseButton
      >
      <BaseButton
        id="pickupDropBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Pickup / Drop Stop</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2"
        @click="getPickupDropStopsList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
      <PickupDropStopFilter></PickupDropStopFilter>
    </template>
    <template #dialogs>
      <PickupDropStopForm
        ref="pickupDropForm"
        v-model="openPickupDropForm"
        :editMode="editMode"
        :id="pickupDropStopID"
        @refreshList="getPickupDropStopsList({ offset: 0, limit: 10 })"
      ></PickupDropStopForm>

      <BulkUpload
        uploadTo="pickup_drop_stops"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getPickupDropStopsList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import PickupDropStopActionsButton from "@/components/AgGridButtons/PickupDropStopActionsButton.vue";
import PickupDropStopFilter from "@/components/ModuleBasedComponents/pickup_drop_stops/PickupDropStopFilter.vue";
import PickupDropStopForm from "@/components/ModuleBasedComponents/pickup_drop_stops/PickupDropStopForm.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    PickupDropStopActionsButton,
    PickupDropStopForm,
    PickupDropStopFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      headers: [
        // {
        //   headerName: "Stop ID",
        //   field: "stop_id",
        // },
        {
          headerName: "Stop Name",
          field: "location_name",
        },
        {
          headerName: "Stop Address",
          field: "address",
        },
        {
          headerName: "Coordinates (Latitude & Longitude)",
          valueGetter: function (params) {
            return (
              params.data.coordinates.latitude +
              ", " +
              params.data.coordinates.longitude
            );
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "PickupDropStopActionsButton",
        },
      ],

      editMode: false,
      pickUpDropList: [],
      openPickupDropForm: false,
      pickupDropStopID: null,
      showBulkUploadDialog: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getPickupDropStopsList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.pickup_drop_stops
        .getPickupDropStopsList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.pickUpDropList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addPickupDropStop() {
      this.editMode = false;
      this.openPickupDropForm = true;
    },

    editPickupDropStop(id) {
      this.editMode = true;
      this.$refs.pickupDropForm.getPickupDropStopsObject(id);
      this.openPickupDropForm = true;
    },
  },
};
</script>

<style>
</style>