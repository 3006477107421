import CompanyShiftsIndex from "../pages/admin/company_shifts/index.vue";

const CompanyShifts = [
  {
    path: "company_shifts",
    name: "company_shifts",
    component: CompanyShiftsIndex,
  },
];

export default CompanyShifts;
