import DriversIndex from "../pages/admin/drivers/index.vue";
import DriverDetail from "../pages/admin/drivers/detail/_id/index.vue";

const Drivers = [
  {
    path: "drivers",
    name: "drivers",
    component: DriversIndex,
  },
  {
    path: "drivers/detail/:id",
    name: "drivers/detail/:id",
    component: DriverDetail,
  },
];

export default Drivers;
