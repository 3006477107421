import { company } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCompanyList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(company.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCompany(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(company.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCompanyObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${company.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateDriverObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${company.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
