<template>
  <BaseFormLayout
    :title="`${editMode ? 'Edit' : 'Create'} Trip`"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="tripResetBtn"
        small
        class="mr-2 rounded-lg"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="tripSubmitBtn"
        small
        customClass="rounded-lg"
        :disabled="!isValid"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
    <template #content>
      <v-form ref="tripForm" id="tripForm" v-model="isValid">
        <v-row class="mx-2">
          <v-col cols="12" class="white rounded-lg">
            <v-card elevation="0">
              <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="4">
                    <BaseTextInput
                      :disabled="editMode"
                      id="reference_number"
                      name="reference_number"
                      v-model="tripDetails.reference_number"
                      :required="true"
                      label="Trip Reference Number"
                      :error-messages="
                        formErrors && formErrors.reference_number
                          ? formErrors.reference_number
                          : ''
                      "
                      @input="
                        formErrors && formErrors.reference_number
                          ? delete formErrors.reference_number
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>
                  <v-col cols="4">
                    <BaseSelect
                      :disabled="
                        editMode ||
                        ($route && $route.params && $route.params.data)
                          ? true
                          : false
                      "
                      id="route"
                      name="route"
                      label="Select Route *"
                      v-model="tripDetails.route"
                      :required="true"
                      :itemsList="routeList"
                      itemText="name"
                      itemValue="value"
                      :isClearable="false"
                      :rules="[(val) => !!val || 'Route is required']"
                      :error-message="
                        formErrors && formErrors.route ? formErrors.route : ''
                      "
                      @change="
                        formErrors && formErrors.route
                          ? delete formErrors.route
                          : '',
                          getRouteObject(tripDetails.route),
                          getPickupDropStopsList()
                      "
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="4">
                    <BaseSelect
                      id="driver"
                      name="driver"
                      label="Select Driver *"
                      v-model="tripDetails.driver"
                      :required="true"
                      :isClearable="false"
                      :itemsList="driverList"
                      :rules="[(val) => !!val || 'Driver is required']"
                      item-text="name"
                      item-value="value"
                      :error-messages="
                        formErrors && formErrors.driver ? formErrors.driver : ''
                      "
                      @change="
                        formErrors && formErrors.driver
                          ? delete formErrors.driver
                          : ''
                      "
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="4">
                    <BaseSelect
                      id="vehicle"
                      name="vehicle"
                      label="Select Vehicle *"
                      v-model="tripDetails.vehicle"
                      :rules="[(val) => !!val || 'Vehicle is required']"
                      :required="true"
                      :itemsList="vehicleList"
                      itemText="name"
                      itemValue="value"
                      :isClearable="false"
                      :error-messages="
                        formErrors && formErrors.vehicle
                          ? formErrors.vehicle
                          : ''
                      "
                      @change="
                        formErrors && formErrors.vehicle
                          ? delete formErrors.vehicle
                          : ''
                      "
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="4" v-if="!editMode">
                    <BaseDatePickerInput
                      v-model="tripDetails.from_date"
                      id="from_date"
                      name="from_date"
                      :required="true"
                      label="From Date"
                      :clearable="false"
                      :min="returnToday()"
                      :error-messages="
                        formErrors && formErrors.from_date
                          ? formErrors.from_date
                          : ''
                      "
                      @change="
                        formErrors && formErrors.from_date
                          ? delete formErrors.from_date
                          : ''
                      "
                    ></BaseDatePickerInput>
                  </v-col>
                  <v-col cols="4" v-if="!editMode">
                    <BaseDatePickerInput
                      v-model="tripDetails.to_date"
                      id="to_date"
                      name="to_date"
                      :clearable="false"
                      :required="true"
                      :min="tripDetails.from_date"
                      label="To Date"
                      :error-messages="
                        formErrors && formErrors.to_date
                          ? formErrors.to_date
                          : ''
                      "
                      @change="
                        formErrors && formErrors.to_date
                          ? delete formErrors.to_date
                          : ''
                      "
                    ></BaseDatePickerInput>
                  </v-col>
                  <v-col cols="4" v-if="editMode">
                    <BaseDatePickerInput
                      v-model="tripDetails.trip_date"
                      id="trip_date"
                      name="trip_date"
                      :clearable="false"
                      :required="true"
                      label="Trip Date"
                      :error-messages="
                        formErrors && formErrors.trip_date
                          ? formErrors.trip_date
                          : ''
                      "
                      @input="
                        formErrors && formErrors.trip_date
                          ? delete formErrors.trip_date
                          : ''
                      "
                    ></BaseDatePickerInput>
                  </v-col>
                  <!-- <v-col cols="4">
                    <BaseTextInput
                      v-if="editMode"
                      :disabled="true"
                      v-model="tripDetails.duration"
                      id="duration"
                      name="duration"
                      :required="true"
                      label="Duration"
                      :error-messages="
                        formErrors && formErrors.duration
                          ? formErrors.duration
                          : ''
                      "
                      @input="
                        formErrors && formErrors.duration
                          ? delete formErrors.duration
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            class="white rounded-lg mt-4"
            v-if="route_stops && route_stops.length > 0"
          >
            <v-card elevation="0">
              <v-card-text class="ma-0 pa-0">
                <v-timeline dense align-top>
                  <div v-for="(pickUp, index) in route_stops" :key="index">
                    <v-timeline-item small>
                      <v-row class="">
                        <v-col cols="3">
                          <BaseSelect
                            v-if="!editMode"
                            disabled
                            id="location_id"
                            name="location_id"
                            v-model="pickUp.location_id"
                            label="Pickup Location"
                            :itemsList="locationList"
                            itemText="location_name"
                            itemValue="location_id"
                            :rules="[
                              (val) => !!val || 'Pickup Location is required',
                            ]"
                            :error-message="
                              formErrors && formErrors.location_id
                                ? formErrors.location_id
                                : ''
                            "
                            @input="
                              formErrors && formErrors.location_id
                                ? delete formErrors.location_id
                                : ''
                            "
                          ></BaseSelect>
                          <BaseTextInput
                            v-else
                            id="location_name"
                            name="location_name"
                            v-model="pickUp.location_name"
                            label="PickUp Location"
                            :disabled="true"
                          ></BaseTextInput>
                        </v-col>

                        <v-col cols="2">
                          <BaseTextInput
                            disabled
                            v-model="pickUp.total_passengers"
                            id="total_passengers"
                            name="total_passengers"
                            label="No. of Passengers"
                          ></BaseTextInput>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </div>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseFormLayout>
</template>

<script>
import { bus } from "@/main";
import { returnToday } from "@/utils/functions.js";
export default {
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      returnToday,
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      tripDetails: {},
      vehicleList: [],
      driverList: [],
      routeList: [],
      locationList: [],
      route_stops: [],
    };
  },
  computed: {},
  methods: {
    getTripObject(id = null) {
      bus.$emit("showLoader", true);
      this.$api.trips
        .getTripObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripDetails = res.data;
          this.route_stops = res.data.route_stops;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getPickupDropStopsList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.pickup_drop_stops.getPickupDropStopsList(params).then((res) => {
        this.locationList = res.data;
      });
    },
    getRoutesList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.routes.getRoutesList(params).then((res) => {
        this.routeList = res.data.map((obj) => {
          return {
            name: obj.route_name,
            value: obj.id,
          };
        });
      });
    },
    getDriversList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.driver.getDriverList(params).then((res) => {
        this.driverList = res.data.map((obj) => {
          return {
            name: obj.first_name + " " + obj.last_name,
            value: obj.id,
          };
        });
      });
    },
    getVehicleList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.vehicle.getVehicleList(params).then((res) => {
        this.vehicleList = res.data.map((obj) => {
          return {
            name: obj.vehicle_number,
            value: obj.id,
          };
        });
      });
    },
    getRouteObject(id) {
      bus.$emit("showLoader", true);
      this.$api.routes
        .getRouteObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.route_stops = res.data.route_stops;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    fieldLabel(type) {
      if (type == "source_location") {
        return "Source Location";
      } else if (type == "destination_location") {
        return "Destination Location";
      } else {
        return "PickUp Location";
      }
    },
    timelineIconColor(type) {
      if (type == "source_location") {
        return "#50CD89";
      } else if (type == "destination_location") {
        return "#F15050";
      } else {
        return "primary";
      }
    },
    timelineIcon(type) {
      if (type == "source_location") {
        return "mdi-home";
      } else if (type == "destination_location") {
        return "mdi-map-marker-radius";
      } else {
        return "mdi-check";
      }
    },
    resetForm() {
      this.$refs.tripForm.reset();
      this.$refs.tripForm.resetValidation();
    },
    submitForm() {
      let data = { ...this.tripDetails };
      if (!this.editMode) {
        this.$api.trips
          .createTrip(data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Trip created!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.trips
          .updateTripObject({
            id: this.tripDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Trip information updated!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            this.formErrors = err.data;
          });
      }
    },
  },
  mounted() {
    this.getRoutesList();
    this.getDriversList();
    this.getVehicleList();

    // for edit trip
    if (this.$route.params.id) {
      this.getTripObject(this.$route.params.id);
    }

    // creating trip from the routes page
    if (this.$route.params.data) {
      this.tripDetails.route = this.$route.params.data.id;
      this.getRouteObject(this.$route.params.data.id);
      this.getPickupDropStopsList(this.$route.params.data.id);
    }
  },
};
</script>

<style>
</style>