import { pickup_drop_stops } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getPickupDropStopsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(pickup_drop_stops.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createPickupDropStops(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(pickup_drop_stops.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getPickupDropStopsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${pickup_drop_stops.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updatePickupDropStopsObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${pickup_drop_stops.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdatePickupDropStopsObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${pickup_drop_stops.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
