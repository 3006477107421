<template>
  <v-dialog
    v-model="driverFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="30%"
    ><v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 primary--text font-weight-bold">
              Driver Filter
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="driverFilterDialog = false" color="primary"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="driverFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="">
              <BaseSelect
                dense
                hide-details
                outlined
                clearable
                label="Select Project"
                :itemsList="projectList"
                itemText="name"
                itemValue="value"
                :multiple="true"
                v-model="driverFilter.project_id"
                class="pl-1"
                :menu-props="{ offsetY: true }"
                @change="syncDriverFilters('project_id', $event)"
              ></BaseSelect>
            </v-col>

            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="d-flex justify-center align-center pb-2"
            >
              <v-checkbox
                class="ma-0 pa-0"
                hide-details
                label="Has Vehicle"
                v-model="driverFilter.has_vehicle"
                :input-value="driverFilter.has_vehicle"
                :value="driverFilter.has_vehicle"
                :true-value="true"
                :false-value="false"
                @change="syncDriverFilters('has_vehicle', $event)"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="py-2 pl-1">
              <BaseSelect
                v-model="driverFilter.status"
                :itemsList="driverStatusOptions"
                itemText="name"
                itemValue="value"
                id="driverStatus"
                name="driverStatus"
                label="Status"
                @change="syncDriverFilters('status', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="py-2 pl-2">
              <BaseSelect
                v-model="driverFilter.ordering"
                :itemsList="driverOrderingOptions"
                itemText="name"
                itemValue="value"
                id="driverOrdering"
                name="driverOrdering"
                label="Ordering"
                :multiple="true"
                :menu-props="{ offsetY: true }"
                @change="syncDriverFilters('ordering', $event)"
                :isClearable="false"
                :isSearchRequired="false"
              ></BaseSelect>
            </v-col>

            <v-col cols="12" md="6" lg="6" xl="6" class="py-2 pl-1">
              <BaseTimeInput
                v-model="driverFilter.from_shift"
                id="driverFromShift"
                name="driverFromShift"
                @input="syncDriverFilters('from_shift', $event)"
                label="From Time"
              ></BaseTimeInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="py-2 pl-2">
              <BaseTimeInput
                v-model="driverFilter.to_shift"
                id="driverToShift"
                name="driverToShift"
                @input="syncDriverFilters('to_shift', $event)"
                label="To Time"
              ></BaseTimeInput>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <BaseSecondaryButton
          small
          customClass="rounded-lg"
          id="resetDriverFilter"
          @click="resetFilters()"
          >Reset</BaseSecondaryButton
        >
        <BaseButton
          small
          customClass="rounded-lg"
          id="applyDriverFilter"
          @click="applyFilters()"
          >Apply</BaseButton
        >
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
import {
  driverStatusOptions,
  driverOrderingOptions,
} from "@/utils/optionsMapping.js";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      driverStatusOptions,
      driverOrderingOptions,
      projectList: [],
      driverFilter: {},
    };
  },
  watch: {
    driverFilterDialog(value) {
      if (value) {
        this.getProjectsList();
        let filters = localStorage.getItem("driverFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.driverFilter = filters;
      }
    },
  },
  computed: {
    driverFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    syncDriverFilters(key, value) {
      console.log(key, value);
      let filters = localStorage.getItem("driverFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (
        value !== null &&
        (typeof value == typeof "string" ||
          (typeof value == typeof [] && value.length > 0) ||
          typeof value == "boolean" ||
          typeof value == "number")
      ) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.driverFilter = filters;
      console.log(this.driverFilter);
      localStorage.setItem("driverFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("driverFiltersChanged");
      this.driverFilterDialog = false;
    },
    resetFilters(reload) {
      this.companyFilter = {};
      localStorage.removeItem("driverFilters");
      this.$emit("driverFiltersChanged");
      if (this.$refs.driverFilterForm) {
        this.$refs.driverFilterForm.reset();
      }
    },
  },
};
</script>

<style>
</style>