<template>
  <v-tooltip bottom color="error">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="red" v-bind="attrs" v-on="on"> mdi-information </v-icon>
    </template>
    <span>
      <span
        v-for="[key, value, index] in Object.entries(params.data.formError)"
        :key="index"
        class="text-capitalize"
      >
        <span v-if="key != 'non_field_errors'">
          {{ key.replace(/_/g, " ") }} - </span
        >{{ value[0] }} <br />
      </span>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
