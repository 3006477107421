<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="editCompanyShift"
      name="editCompanyShift"
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editCompanyShift()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    editCompanyShift() {
      this.params.context.parentComponent.editCompanyShift(this.params.data.id);
    },
  },
};
</script>

<style>
</style>