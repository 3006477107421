var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Create'} Trip`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"tripResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"tripSubmitBtn","small":"","customClass":"rounded-lg","disabled":!_vm.isValid},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"tripForm",attrs:{"id":"tripForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"4"}},[_c('BaseTextInput',{attrs:{"disabled":_vm.editMode,"id":"reference_number","name":"reference_number","required":true,"label":"Trip Reference Number","error-messages":_vm.formErrors && _vm.formErrors.reference_number
                        ? _vm.formErrors.reference_number
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.reference_number
                        ? delete _vm.formErrors.reference_number
                        : ''}},model:{value:(_vm.tripDetails.reference_number),callback:function ($$v) {_vm.$set(_vm.tripDetails, "reference_number", $$v)},expression:"tripDetails.reference_number"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('BaseSelect',{attrs:{"disabled":_vm.editMode ||
                      (_vm.$route && _vm.$route.params && _vm.$route.params.data)
                        ? true
                        : false,"id":"route","name":"route","label":"Select Route *","required":true,"itemsList":_vm.routeList,"itemText":"name","itemValue":"value","isClearable":false,"rules":[(val) => !!val || 'Route is required'],"error-message":_vm.formErrors && _vm.formErrors.route ? _vm.formErrors.route : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.route
                        ? delete _vm.formErrors.route
                        : '',
                        _vm.getRouteObject(_vm.tripDetails.route),
                        _vm.getPickupDropStopsList()}},model:{value:(_vm.tripDetails.route),callback:function ($$v) {_vm.$set(_vm.tripDetails, "route", $$v)},expression:"tripDetails.route"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('BaseSelect',{attrs:{"id":"driver","name":"driver","label":"Select Driver *","required":true,"isClearable":false,"itemsList":_vm.driverList,"rules":[(val) => !!val || 'Driver is required'],"item-text":"name","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.driver ? _vm.formErrors.driver : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.driver
                        ? delete _vm.formErrors.driver
                        : ''}},model:{value:(_vm.tripDetails.driver),callback:function ($$v) {_vm.$set(_vm.tripDetails, "driver", $$v)},expression:"tripDetails.driver"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('BaseSelect',{attrs:{"id":"vehicle","name":"vehicle","label":"Select Vehicle *","rules":[(val) => !!val || 'Vehicle is required'],"required":true,"itemsList":_vm.vehicleList,"itemText":"name","itemValue":"value","isClearable":false,"error-messages":_vm.formErrors && _vm.formErrors.vehicle
                        ? _vm.formErrors.vehicle
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.vehicle
                        ? delete _vm.formErrors.vehicle
                        : ''}},model:{value:(_vm.tripDetails.vehicle),callback:function ($$v) {_vm.$set(_vm.tripDetails, "vehicle", $$v)},expression:"tripDetails.vehicle"}})],1),(!_vm.editMode)?_c('v-col',{attrs:{"cols":"4"}},[_c('BaseDatePickerInput',{attrs:{"id":"from_date","name":"from_date","required":true,"label":"From Date","clearable":false,"min":_vm.returnToday(),"error-messages":_vm.formErrors && _vm.formErrors.from_date
                        ? _vm.formErrors.from_date
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.from_date
                        ? delete _vm.formErrors.from_date
                        : ''}},model:{value:(_vm.tripDetails.from_date),callback:function ($$v) {_vm.$set(_vm.tripDetails, "from_date", $$v)},expression:"tripDetails.from_date"}})],1):_vm._e(),(!_vm.editMode)?_c('v-col',{attrs:{"cols":"4"}},[_c('BaseDatePickerInput',{attrs:{"id":"to_date","name":"to_date","clearable":false,"required":true,"min":_vm.tripDetails.from_date,"label":"To Date","error-messages":_vm.formErrors && _vm.formErrors.to_date
                        ? _vm.formErrors.to_date
                        : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.to_date
                        ? delete _vm.formErrors.to_date
                        : ''}},model:{value:(_vm.tripDetails.to_date),callback:function ($$v) {_vm.$set(_vm.tripDetails, "to_date", $$v)},expression:"tripDetails.to_date"}})],1):_vm._e(),(_vm.editMode)?_c('v-col',{attrs:{"cols":"4"}},[_c('BaseDatePickerInput',{attrs:{"id":"trip_date","name":"trip_date","clearable":false,"required":true,"label":"Trip Date","error-messages":_vm.formErrors && _vm.formErrors.trip_date
                        ? _vm.formErrors.trip_date
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.trip_date
                        ? delete _vm.formErrors.trip_date
                        : ''}},model:{value:(_vm.tripDetails.trip_date),callback:function ($$v) {_vm.$set(_vm.tripDetails, "trip_date", $$v)},expression:"tripDetails.trip_date"}})],1):_vm._e()],1)],1)],1)],1),(_vm.route_stops && _vm.route_stops.length > 0)?_c('v-col',{staticClass:"white rounded-lg mt-4",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-timeline',{attrs:{"dense":"","align-top":""}},_vm._l((_vm.route_stops),function(pickUp,index){return _c('div',{key:index},[_c('v-timeline-item',{attrs:{"small":""}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"3"}},[(!_vm.editMode)?_c('BaseSelect',{attrs:{"disabled":"","id":"location_id","name":"location_id","label":"Pickup Location","itemsList":_vm.locationList,"itemText":"location_name","itemValue":"location_id","rules":[
                            (val) => !!val || 'Pickup Location is required',
                          ],"error-message":_vm.formErrors && _vm.formErrors.location_id
                              ? _vm.formErrors.location_id
                              : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.location_id
                              ? delete _vm.formErrors.location_id
                              : ''}},model:{value:(pickUp.location_id),callback:function ($$v) {_vm.$set(pickUp, "location_id", $$v)},expression:"pickUp.location_id"}}):_c('BaseTextInput',{attrs:{"id":"location_name","name":"location_name","label":"PickUp Location","disabled":true},model:{value:(pickUp.location_name),callback:function ($$v) {_vm.$set(pickUp, "location_name", $$v)},expression:"pickUp.location_name"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('BaseTextInput',{attrs:{"disabled":"","id":"total_passengers","name":"total_passengers","label":"No. of Passengers"},model:{value:(pickUp.total_passengers),callback:function ($$v) {_vm.$set(pickUp, "total_passengers", $$v)},expression:"pickUp.total_passengers"}})],1)],1)],1)],1)}),0)],1)],1)],1):_vm._e()],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }