var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseFormLayout',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Project`,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{staticClass:"mr-2 rounded-lg",attrs:{"id":"projectResetBtn","small":""},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"id":"projectSubmitBtn","small":"","customClass":"rounded-lg","loading":_vm.loading,"disabled":!_vm.isValid},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{key:_vm.formCount,ref:"projectForm",attrs:{"id":"projectForm","name":"projectForm","autocomplete":"off"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"mx-1"},[_c('v-col',{staticClass:"white rounded-lg",attrs:{"cols":"3"}},[_c('v-row',{staticClass:"pa-6"},[_c('v-col',{staticClass:"pa-1 mb-2",attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"project_id","name":"project_id","label":"Project ID","required":true,"error-messages":_vm.formErrors && _vm.formErrors.project_id
                    ? _vm.formErrors.project_id
                    : '',"outlined":""},on:{"input":function($event){_vm.formErrors && _vm.formErrors.project_id
                    ? delete _vm.formErrors.project_id
                    : ''}},model:{value:(_vm.projectFormDetails.project_id),callback:function ($$v) {_vm.$set(_vm.projectFormDetails, "project_id", $$v)},expression:"projectFormDetails.project_id"}})],1),_c('v-col',{staticClass:"pa-1 mb-2",attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"project_name","name":"project_name","label":"Project Name","required":true,"error-messages":_vm.formErrors && _vm.formErrors.project_name
                    ? _vm.formErrors.project_name
                    : '',"outlined":""},on:{"input":function($event){_vm.formErrors && _vm.formErrors.project_name
                    ? delete _vm.formErrors.project_name
                    : ''}},model:{value:(_vm.projectFormDetails.project_name),callback:function ($$v) {_vm.$set(_vm.projectFormDetails, "project_name", $$v)},expression:"projectFormDetails.project_name"}})],1),_c('v-col',{staticClass:"pa-1 mb-2",attrs:{"cols":"12"}},[_c('v-text-field',{ref:"address",staticClass:"rounded-lg",attrs:{"id":"address","name":"address","placeholder":"Project Address *","hide-details":"auto","required":true,"dense":"","outlined":"","rules":[(val) => !!val || 'Project Address is required'],"error-message":_vm.formErrors && _vm.formErrors.address ? _vm.formErrors.address : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.address
                    ? delete _vm.formErrors.address
                    : ''}},model:{value:(_vm.projectFormDetails.address),callback:function ($$v) {_vm.$set(_vm.projectFormDetails, "address", $$v)},expression:"projectFormDetails.address"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","name":"latitude","label":"Latitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Latitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.latitude ? _vm.formErrors.latitude : '',"outlined":""},model:{value:(_vm.projectFormDetails.base_coordinates.latitude),callback:function ($$v) {_vm.$set(_vm.projectFormDetails.base_coordinates, "latitude", _vm._n($$v))},expression:"projectFormDetails.base_coordinates.latitude"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","name":"longitude","label":"Longitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Latitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.longitude
                    ? _vm.formErrors.longitude
                    : '',"outlined":""},model:{value:(_vm.projectFormDetails.base_coordinates.longitude),callback:function ($$v) {_vm.$set(_vm.projectFormDetails.base_coordinates, "longitude", _vm._n($$v))},expression:"projectFormDetails.base_coordinates.longitude"}})],1)],1)],1),_c('v-col',{staticClass:"cf-zone-map pt-0",attrs:{"cols":"9"}},[_c('GoogleMap',{ref:"projectFormMap",attrs:{"mapWidth":"100%","mapHeight":"83vh","isDrawable":true,"clusterMarkers":false}})],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }