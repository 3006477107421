<template>
  <BaseListLayout
    ref="listLayout"
    title="Vehicles"
    :table-header="headers"
    :table-data="vehicleList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getVehicleList"
    id="vehicleSearch"
    name="vehicleSearch"
  >
    <template #listAction>
      <BaseButton
        id="vehicleAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addVehicle()"
        >Add Vehicle</BaseButton
      >
      <BaseButton
        id="vehicleBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Vehicles</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getVehicleList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>

      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="vehicleFilterDialog = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <VehicleFilter
        v-model="vehicleFilterDialog"
        @vehicleFiltersChanged="$refs.listLayout.searchTriggered()"
      ></VehicleFilter>
      <VehicleForm
        v-model="openVehicleForm"
        :editMode="editMode"
        :id="vehicleID"
        @refreshList="getVehicleList({ offset: 0, limit: 10 })"
      />

      <BulkUpload
        uploadTo="vehicles"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getVehicleList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import VehicleActionsButton from "@/components/AgGridButtons/VehicleActionsButton.vue";
import VehicleForm from "@/components/ModuleBasedComponents/vehicles/VehicleForm.vue";
import VehicleFilter from "@/components/ModuleBasedComponents/vehicles/VehicleFilter.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    VehicleActionsButton,
    VehicleForm,
    VehicleFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openVehicleForm: false,
      editMode: false,
      showBulkUploadDialog: false,
      vehicleFilterDialog: false,
      headers: [
        {
          headerName: "Vehicle Plate No",
          field: "vehicle_number",
        },
        {
          headerName: "Project",
          field: "project_id",
        },
        {
          headerName: "Fuel Type",
          field: "fuel_type",
        },
        {
          headerName: "Seating Capacity",
          field: "seating_capacity",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "VehicleActionsButton",
        },
      ],
      vehicleList: [],
      vehicleID: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getVehicleList(params = {}) {
      let filters = localStorage.getItem("vehicleFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vehicleList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addVehicle() {
      this.editMode = false;
      this.openVehicleForm = true;
    },

    editVehicle(id) {
      this.vehicleID = id;
      this.editMode = true;
      this.openVehicleForm = true;
    },

    viewDetails(id) {
      this.$router.push(`/app/admin/vehicles/detail/${id}`);
    },
  },
};
</script>

<style>
</style>