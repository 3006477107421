<template>
  <EmployeeDetail></EmployeeDetail>
</template>

<script>
import EmployeeDetail from "@/components/ModuleBasedComponents/employees/EmployeeDetail.vue";
export default {
  components: {
    EmployeeDetail,
  },
};
</script>

<style>
</style>