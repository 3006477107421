<template>
  <CompanyDetail></CompanyDetail>
</template>

<script>
import CompanyDetail from "@/components/ModuleBasedComponents/company/CompanyDetail.vue";
export default {
  components: {
    CompanyDetail,
  },
};
</script>

<style>
</style>