<template>
  <BaseDetailLayout title="Vehicle Details">
    <template #detail>
      <v-row no-gutters class="ma-0 px-3 pt-3">
        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="primary rounded-lg">
            <v-row no-gutters v-if="vehicleDetail.vehicle_details" class="pa-2">
              <v-col cols="4" md="3" lg="4" class="pa-2">
                <img
                  :src="VehicleImage"
                  height="
                  100px
                  "
                  width="
                  100px
                  "
                />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Vehicle Number</h4>
                  <h5 class="white--text">
                    {{ vehicleDetail.vehicle_details.vehicle_number }}
                  </h5>
                </div>
                <div>
                  <h4 class="text-caption white--text">Assigned Driver</h4>
                  <h5 class="white--text">
                    {{ vehicleDetail.vehicle_details.driver }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Project</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ vehicleDetail.vehicle_details.project }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption">Fuel Type</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{ vehicleDetail.vehicle_details.fuel_type }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">
                  Seating Capacity
                </h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ vehicleDetail.vehicle_details.seating_capacity }}
                </h5>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="6">
          <section class="d-flex flex-column">
            <v-row
              no-gutters
              class="pb-3 px-3"
              v-if="vehicleDetail && vehicleDetail.vehicle_details"
            >
              <v-col
                v-for="(value, key, index) in vehicleDetail.vehicle_details
                  .vehicle_analytics"
                :key="index"
                class="px-1 pb-1"
              >
                <v-card elevation="0" class="rounded-lg">
                  <v-card-text class="py-2">
                    <span
                      class="text-capitalize text-start text-body-2 font-weight-bold d-flex justify-start"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </span>
                    <div class="mt-2 d-flex align-center justify-start">
                      <div
                        class="icon-small light_primary d-flex justify-center align-center"
                      >
                        <v-icon color="primary" small>
                          {{ vehicleAnalyticsIcon[key] }}</v-icon
                        >
                      </div>

                      <span class="text-caption px-4 my-1">
                        <h3>{{ value }}</h3>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="px-3" no-gutters>
              <v-col cols="12" class="px-1">
                <v-card class="rounded-lg" elevation="0">
                  <v-card-title>
                    <v-row class="d-flex justify-start">
                      <v-col
                        cols="1"
                        class="d-flex justify-center align-center"
                      >
                        <h4 class="text-body-2 font-weight-bold">Trips</h4>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-start">
                        <BaseTextInput
                          v-model="searchTrips"
                          id="searchTrips"
                          name="searchTrips"
                          :required="false"
                          customClass="searchTripsClass"
                          label="Search"
                        ></BaseTextInput>
                        <!-- <div class="mx-2 my-1">
                          <BaseFilter class="" customClass=""></BaseFilter>
                        </div> -->
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <AgGridVue
                          @grid-ready="gridReady"
                          :grid-options="gridOptions"
                          :column-defs="columnDefs"
                          :default-col-def="defaultColDef"
                          :context="context"
                          :row-data="tripList"
                          style="width: 100%; height: calc(100vh - 435px)"
                          class="ag-theme-alpine"
                        >
                        </AgGridVue>
                      </v-col>
                      <v-col cols="12" class="pt-3 d-flex justify-end">
                        <v-row>
                          <v-col
                            cols="6"
                            class="d-flex align-center justify-start"
                          >
                            <BasePaginationCount :total="totalItems" />
                          </v-col>
                          <v-col
                            cols="6"
                            class="d-flex align-center justify-end"
                          >
                            <BasePagination
                              :pageNo="pageNo"
                              :totalItems="totalItems"
                              :pageSize="itemsPerPage"
                              @nextPage="nextPage"
                              @prevPage="prevPage"
                              @itemsPerPageChange="itemsPerPageChange"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
        </v-col>

        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="rounded-lg">
            <v-card-title
              ><h4 class="text-body-2 font-weight-bold">Logs</h4></v-card-title
            >
            <v-divider></v-divider>
            <v-card-text
              style="height: calc(100vh - 225px)"
              class="overflow-y-auto"
            >
              <BaseLogs :logs="vehicleLogs" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BaseDetailLayout>
</template>

<script>
import VehicleDetailsActionsButton from "@/components/AgGridButtons/VehicleDetailsActionsButton.vue";
import VehicleImage from "@/assets/vehicle-image.png";
import { AgGridVue } from "ag-grid-vue";
import { bus } from "@/main";
export default {
  name: "VehicleLayout",
  components: { AgGridVue, VehicleDetailsActionsButton },
  data() {
    return {
      VehicleImage,

      itemsPerPage: 10,

      totalItems: 0,
      pageNo: 1,

      gridApi: null,
      columnApi: null,
      defaultColDef: {
        lockPosition: true,
        resizable: true,
        flex: 1,
        sortable: true,
        autoHeight: true,
        cellStyle: {
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          display: "block",
        },
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      columnDefs: [
        {
          headerName: "Trip ID",
          field: "reference_number",
        },
        {
          headerName: "Route Name",
          field: "route",
        },
        {
          headerName: "Route Type",
          field: "route_type",
        },
        {
          headerName: "Driver",
          field: "driver",
        },
        {
          headerName: "Start Date",
          field: "start_date",
        },
        {
          headerName: "End Date",
          field: "end_date",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "VehicleDetailsActionsButton",
        },
      ],

      tripList: [],

      vehicleDetail: {
        vehicle_details: {},
      },
      vehicleAnalyticsIcon: {
        total_trips_till_date: "mdi-truck-fast-outline",
        total_trips_in_a_week: "mdi-truck-fast-outline",
        total_trips_in_a_day: "mdi-truck-fast-outline",
        created_on: "mdi-calendar-range",
      },
      searchTrips: null,

      vehicleLogs: [
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Vehicle has been assigned to Ravikant Misra",
        },
        {
          added_by: "Admin",
          created: "23/03/2023",
          message: "Trip Trip-01 has been assigned to the vehicle.",
        },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getVehicleDetailsView(id = null) {
      bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleDetailsView(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vehicleDetail.vehicle_details = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getVehicleTripList(params = {}) {
      params = {
        ...params,
        id: this.$route.params.id,
        offset: this.offset,
        limit: this.itemsPerPage,
      };
      bus.$emit("showLoader", true);
      this.$api.vehicle
        .getVehicleTripList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    nextPage() {
      this.pageNo++;
      this.getVehicleTripList();
    },

    prevPage() {
      this.pageNo--;
      this.getVehicleTripList();
    },

    itemsPerPageChange(value) {
      this.pageNo = 1;
      this.itemsPerPage = value;
      this.getVehicleTripList();
    },

    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    viewDetails(id) {
      this.$router.push(`/app/admin/trips/detail/${id}`);
    },
  },
  mounted() {
    this.getVehicleDetailsView(this.$route.params.id);
    this.getVehicleTripList();
  },
};
</script>

<style>
.searchTripsClass .v-text-field {
  height: 10px !important;
  width: 10px !important;
}
</style>