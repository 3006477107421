import TripsIndex from "../pages/admin/trips/index.vue";
import TripsCreate from "../pages/admin/trips/create/index.vue";
import TripsEdit from "../pages/admin/trips/edit/_id/index.vue";
import TripsDetail from "../pages/admin/trips/detail/_id/index.vue";

const Trips = [
  {
    path: "trips",
    name: "trips",
    component: TripsIndex,
  },
  {
    path: "trips/create",
    name: "trips/create",
    component: TripsCreate,
    props: true,
  },
  {
    path: "trips/edit/:id",
    name: "trips/edit/:id",
    component: TripsEdit,
  },
  {
    path: "trips/detail/:id",
    name: "trips/detail/:id",
    component: TripsDetail,
  },
];

export default Trips;
