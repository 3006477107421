<template>
  <TripForm :editMode="false"></TripForm>
</template>

<script>
import TripForm from "@/components/ModuleBasedComponents/trips/TripForm.vue";
export default {
  components: {
    TripForm,
  },
};
</script>

<style>
</style>