var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Pick Up / Drop Stop`,"width":"60vh","nonFieldErrors":_vm.formErrors && _vm.formErrors.nonFieldErrors},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"pickupDropStopForm",attrs:{"id":"pickupDropStopForm","name":"pickupDropStopForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"location_name","name":"location_name","required":true,"label":"Stop Name","error-messages":_vm.formErrors && _vm.formErrors.location_name
                ? _vm.formErrors.location_name
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.location_name
                ? delete _vm.formErrors.location_name
                : ''}},model:{value:(_vm.pickupDropDetails.location_name),callback:function ($$v) {_vm.$set(_vm.pickupDropDetails, "location_name", $$v)},expression:"pickupDropDetails.location_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"address",staticClass:"rounded-lg",attrs:{"id":"address","name":"address","placeholder":"Stop Address *","hide-details":"auto","required":true,"dense":"","outlined":"","rules":[(val) => !!val || 'Stop Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.address ? _vm.formErrors.address : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.address
                ? delete _vm.formErrors.address
                : '',
                _vm.formErrors && _vm.formErrors.coordinates
                  ? delete _vm.formErrors.coordinates
                  : ''}},model:{value:(_vm.pickupDropDetails.address),callback:function ($$v) {_vm.$set(_vm.pickupDropDetails, "address", $$v)},expression:"pickupDropDetails.address"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","name":"latitude","label":"Latitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Latitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.coordinates
                ? _vm.formErrors.coordinates
                : '',"outlined":""},model:{value:(_vm.pickupDropDetails.coordinates.latitude),callback:function ($$v) {_vm.$set(_vm.pickupDropDetails.coordinates, "latitude", _vm._n($$v))},expression:"pickupDropDetails.coordinates.latitude"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","name":"longitude","label":"Longitude *","required":"","dense":"","hide-details":"auto","disabled":true,"rules":[(v) => !!v || 'Longitude is Required'],"error-messages":_vm.formErrors && _vm.formErrors.coordinates
                ? _vm.formErrors.coordinates
                : '',"outlined":""},model:{value:(_vm.pickupDropDetails.coordinates.longitude),callback:function ($$v) {_vm.$set(_vm.pickupDropDetails.coordinates, "longitude", _vm._n($$v))},expression:"pickupDropDetails.coordinates.longitude"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('GoogleMap',{ref:"pickupDropForMap",attrs:{"mapWidth":"100%","mapHeight":"20vh","isDrawable":false,"clusterMarkers":false}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('BaseSecondaryButton',{attrs:{"customClass":"rounded-lg","small":"","id":"pickupDropStopResetBtn"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Reset")]),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"customClass":"rounded-lg","small":"","id":"pickupDropSubmitBtn"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openPickupDropStopForm),callback:function ($$v) {_vm.openPickupDropStopForm=$$v},expression:"openPickupDropStopForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }