<template>
  <BaseListLayout
    title="Users"
    :table-header="headers"
    :table-data="userList"
    :hasListActions="true"
    :total="totalItems"
    @getList="getUserList"
    :context="context"
    name="userSearch"
    id="userSearch"
  >
    <template #listAction>
      <BaseButton
        id="userAddBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="addUser()"
        >Add User</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2"
        @click="getUserList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import UserActionsButton from "@/components/AgGridButtons/UserActionsButton.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    UserActionsButton,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      headers: [
        {
          headerName: "First Name",
          field: "first_name",
        },
        {
          headerName: "Last Name",
          field: "last_name",
        },
        {
          headerName: "Username",
          field: "username",
        },
        {
          headerName: "Contact Number",
          field: "contact_number",
        },
        {
          headerName: "Email",
          field: "email",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "UserActionsButton",
        },
      ],
      userList: [],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getUserList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.user
        .getUserList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.userList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addUser() {
      this.$router.push("/app/admin/users/create");
    },

    editUser(id) {
      this.$router.push(`/app/admin/users/edit/${id}`);
    },
  },
};
</script>

<style>
</style>