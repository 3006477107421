var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Vehicle Type`,"width":"50vh"},on:{"closeDialog":function($event){return _vm.resetForm()}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [(
        _vm.formErrors &&
        _vm.formErrors.non_field_errors &&
        _vm.formErrors.non_field_errors.length > 0
      )?_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"white--text align-center",attrs:{"color":"error","dismissible":"","dense":""},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.formErrors.non_field_errors = []}}},[_vm._v("mdi-close")])]},proxy:true}],null,false,3651180807)},_vm._l((_vm.formErrors.non_field_errors),function(error,i){return _c('p',{key:i,staticClass:"pa-0 d-flex text-center align-center"},[_vm._v(" "+_vm._s(error)+" ")])}),0)],1)],1):_vm._e(),_c('v-form',{ref:"vehicleTypeForm",attrs:{"id":"vehicleTypeForm","name":"vehicleTypeForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseTextInput',{attrs:{"id":"type","name":"type","required":true,"label":"Vehicle Type"},model:{value:(_vm.vehicleTypeDetails.type),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "type", $$v)},expression:"vehicleTypeDetails.type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","id":"seating_capacity","name":"seating_capacity","required":true,"label":"Seating Capacity *","hide-details":"auto","rules":[
              (val) => !!val || 'Seating Capacity is required',
              (val) => val > 0 || 'Seating Capacity must be at least 1',
            ],"error-messages":_vm.formErrors && _vm.formErrors.seating_capacity
                ? _vm.formErrors.seating_capacity
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.seating_capacity
                ? delete _vm.formErrors.seating_capacity
                : ''}},model:{value:(_vm.vehicleTypeDetails.seating_capacity),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "seating_capacity", $$v)},expression:"vehicleTypeDetails.seating_capacity"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","hide-details":"auto","id":"vehicle_count","name":"vehicle_count","required":true,"label":"Vehicle Count *","rules":[
              (val) => !!val || 'Vehicle Count is required',
              (val) => val > 0 || 'Vehicle Count must be at least 1',
            ],"error-messages":_vm.formErrors && _vm.formErrors.vehicle_count
                ? _vm.formErrors.vehicle_count
                : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.vehicle_count
                ? delete _vm.formErrors.vehicle_count
                : ''}},model:{value:(_vm.vehicleTypeDetails.vehicle_count),callback:function ($$v) {_vm.$set(_vm.vehicleTypeDetails, "vehicle_count", $$v)},expression:"vehicleTypeDetails.vehicle_count"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c('BaseSecondaryButton',{attrs:{"id":"vehicleTypeResetBtn","customClass":"rounded-lg","small":""}},[_vm._v("Reset")]):_vm._e(),_c('BaseButton',{attrs:{"disabled":!_vm.isValid,"id":"vehicleTypeSubmitBtn","customClass":"rounded-lg","small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.openVehicleTypeForm),callback:function ($$v) {_vm.openVehicleTypeForm=$$v},expression:"openVehicleTypeForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }