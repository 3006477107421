<template>
  <UserForm :editMode="false"></UserForm>
</template>

<script>
import UserForm from "@/components/ModuleBasedComponents/users/UserForm.vue";
export default {
  components: {
    UserForm,
  },
};
</script>

<style>
</style>