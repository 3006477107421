<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  methods: {
    async checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/app/login") {
          this.$router.push("/app/login");
        }
      } else {
        if (["/app", "/app/admin"].indexOf(this.$route.path) > -1) {
          this.$router.push("/app/admin/dashboard");
        }
      }
    },
  },
  beforeMount() {
    this.checkIfLoggedIn();
  },
};
</script>

<style></style>
