<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between pa-8">
        <div>
          <h3 class="text-uppercase text-h6 text_color--text font-weight-bold">
            Dashboard
          </h3>
        </div>
      </v-col>

      <v-col cols="12" md="12" sm="12" class="px-8 pt-0">
        <v-row>
          <v-col
            cols="12"
            md="2"
            sm="2"
            v-for="(value, key, index) in dashboardStatistics"
            :key="index"
          >
            <v-card elevation="0" class="rounded-lg">
              <v-card-title>
                <h3
                  class="black--text font-weight-bold text-body-2 text-capitalize"
                >
                  {{ key.replace(/_/g, "  ") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="1" class="mr-3">
                    <v-icon color="primary">
                      {{ dashboardAnalyticsIcon[key] }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    <span class="text-body-1 primary--text font-weight-bold">{{
                      value
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        v-if="tripChartData && Object.keys(tripChartData).length > 0"
        class="pr-2 pl-8"
      >
        <v-card flat outlined class="rounded-lg" min-height="490">
          <v-card-title>
            <v-row class="d-flex justify-space-between align-center">
              <v-col class="d-flex justify-start">
                <span class="text-body-1 font-weight-bold"
                  >Trip(s) & Route(s) per Company</span
                >
              </v-col>
              <!-- <v-col cols="4" class="d-flex justify-end">
                <BaseDatePickerInput
                  id="tripChartSearch"
                  name="tripChartSearch"
                  v-model="tripChartSearch"
                  label="Select Month"
                  :max="nowDate"
                  type="month"
                  @change="getDashboardTripsRoutes()"
                ></BaseDatePickerInput>
              </v-col> -->
            </v-row>
          </v-card-title>
          <v-card-text>
            <BarChart
              :data="tripChartData"
              :options="tripChartOptions"
              :key="tripChartsKey"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        v-if="passengerChartData && Object.keys(passengerChartData).length > 0"
        class="pr-8 pl-2"
      >
        <v-card flat outlined class="rounded-lg" min-height="490">
          <v-card-title>
            <v-row class="d-flex justify-space-between align-center">
              <v-col class="d-flex justify-start">
                <span class="text-body-1 font-weight-bold"
                  >Passengers per Company</span
                >
              </v-col>
              <!-- <v-col cols="4" class="d-flex justify-end">
                <BaseDatePickerInput
                  id="passengerChartSearch"
                  name="passengerChartSearch"
                  v-model="passengerChartSearch"
                  label="Select Month"
                  type="month"
                  @change="getTripAndRoutes()"
                ></BaseDatePickerInput>
              </v-col> -->
            </v-row>
          </v-card-title>
          <v-card-text>
            <BarChart
              :data="passengerChartData"
              :options="passengerChartOptions"
              :key="passengerChartsKey"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        v-if="pieChartData && Object.keys(pieChartData).length > 0"
        class="pl-8"
      >
        <v-card flat outlined class="rounded-lg" min-height="490">
          <v-card-title>
            <v-row class="d-flex justify-space-between align-center">
              <v-col class="d-flex justify-start">
                <span class="text-body-1 font-weight-bold"
                  >Employees per Company</span
                >
              </v-col>
              <!-- <v-col cols="4" class="d-flex justify-end">
                <BaseDatePickerInput
                  id="pieChartSearch"
                  name="pieChartSearch"
                  v-model="pieChartSearch"
                  label="Select Month"
                  type="month"
                  @change="getTripAndRoutes()"
                ></BaseDatePickerInput>
              </v-col> -->
            </v-row>
          </v-card-title>
          <v-card-text>
            <PieChart
              :data="pieChartData"
              :options="pieChartOptions"
              :key="pieChartsKey"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const defaultObj = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};
export default {
  name: "BaseDashboard",
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),

      dashboardStatistics: {},
      dashboardAnalyticsIcon: {
        project: "mdi-map",
        companies: "mdi-office-building",
        company_addresses: "mdi-office-building-marker",
        drivers: "mdi-account",
        vehicles: "mdi-bus",
        active_vehicles: "mdi-bus-multiple",
        employees: "mdi-account-group",
        routes: "mdi-map-marker-path",
        trips: "mdi-bus-side",
        trips_on_time: "mdi-bus-clock",
        avg_seating_capacity_utilization: "mdi-seat-recline-extra",
        avg_trips_per_driver: "mdi-bus-stop",
      },

      tripChartData: {},
      tripChartsKey: 0,
      tripChartOptions: {
        ...defaultObj,
      },
      tripChartSearch: null,

      passengerChartData: {
        labels: ["C1", "C2", "C3", "C4", "C5", "C6", "C7"],
        datasets: [
          {
            label: "Total Passengers",
            data: [30, 40, 20, 50, 20, 35, 45],
            backgroundColor: "#009EF7",
            stack: "stack 0",
          },
          {
            label: "Present Employees",
            data: [20, 35, 15, 45, 15, 35, 45],
            backgroundColor: "#FFA800",
            stack: "stack 1",
          },
        ],
      },
      passengerChartsKey: 0,
      passengerChartOptions: {
        ...defaultObj,
      },
      passengerChartSearch: null,

      pieChartData: {
        datasets: [
          {
            data: [10, 20, 30],
            backgroundColor: ["#3699FF", "#FFA800", "#8950FC"],
          },
        ],
        labels: ["Company 1", "Company 2", "Company 3"],
      },
      pieChartOptions: {
        ...defaultObj,
      },
      pieChartsKey: 0,
      pieChartSearch: null,
    };
  },

  methods: {
    getDashboardStatistics() {
      this.$api.dashboard
        .getDashboardStatistics()
        .then((res) => {
          this.dashboardStatistics = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDashboardTripsRoutes() {
      this.$api.dashboard
        .getDashboardTripsRoutes()
        .then((res) => {
          this.tripChartData = res.data;
          this.tripChartsKey++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.getDashboardStatistics();
    this.getDashboardTripsRoutes();
  },
};
</script>