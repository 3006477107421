import {
  employee,
  company,
  company_shifts,
  company_addresses,
  pickup_drop_stops,
  driver,
  vehicle,
} from "@/utils/constants";
import { handleResponse, handleError } from "@/utils/functions";

export default (axios) => ({
  getEmployeesSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${employee.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadEmployees(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${employee.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanySampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCompany(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${company.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyShiftSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company_shifts.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCompanyShift(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${company_shifts.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyAddressSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${company_addresses.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCompanyAddress(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${company_addresses.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getPickupDropStopsSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${pickup_drop_stops.base}sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadPickupDropStops(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pickup_drop_stops.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriversSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadDrivers(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driver.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getVehiclesSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}sample_excel/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadVehicles(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicle.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverUpdateSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driver.base}bulk_update_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateDriver(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${driver.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadVehicle(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicle.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleUpdateSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}bulk_update_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateVehicle(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${vehicle.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getItemSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${item.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadItem(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${item.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateItem(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${item.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerAddressSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerAddress.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCustomerAddress(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerAddress.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerAddressUpdateSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerAddress.base}bulk_update_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateCustomerAddress(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${customerAddress.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSaleOrderSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orderNew.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
          params: { order_segment: "sales" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadSaleOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orderNew.base}bulk_upload/`, {
          order_segment: "sales",
          payload: payload,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerTagSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tag.base}sample_excel_file_for_customer/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCustomerTag(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${tag.base}customer_tag_bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleTagSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tag.base}sample_excel_file_for_vehicle_tag/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadVehicleTag(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${tag.base}vehicle_tag_bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAdhocOrderSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orderNew.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
          params: { order_segment: "adhoc" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadAdhocOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orderNew.base}bulk_upload/`, {
          order_segment: "adhoc",
          payload: payload,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserManagementSampleSheet() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${normalUser.base}bulk_upload_sample_excel_file/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadUserManagement(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${normalUser.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
