<template>
  <BaseListLayout
    ref="listLayout"
    title="Trips"
    :table-header="headers"
    :table-data="tripsList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getTripsList"
    name="tripSearch"
    id="tripSearch"
  >
    <template #listAction>
      <BaseButton
        id="tripCreateBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="createTrip()"
        >Create Trip</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
      ></BaseRefreshList>
      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="tripFilterDialog = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <TripFilter
        v-model="tripFilterDialog"
        @tripFiltersChanged="$refs.listLayout.searchTriggered()"
      ></TripFilter>
      <!-- <ProjectForm v-model="openCreateTrip" /> -->
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import TripActionsButton from "@/components/AgGridButtons/TripActionsButton.vue";
import TripForm from "@/components/ModuleBasedComponents/trips/TripForm.vue";
import TripFilter from "@/components/ModuleBasedComponents/trips/TripFilter.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    TripActionsButton,
    TripForm,
    TripFilter,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openCreateTrip: false,
      headers: [
        {
          headerName: "Trip ID",
          field: "reference_number",
        },
        {
          headerName: "Route Name",
          field: "route",
        },
        // {
        //   headerName: "Total Passengers",
        //   field: "total_passengers",
        // },
        // {
        //   headerName: "Total Stops",
        //   field: "total_stops",
        // },
        {
          headerName: "Driver",
          field: "driver",
        },
        {
          headerName: "Vehicle",
          field: "vehicle",
        },
        {
          headerName: "Start Date",
          field: "from_date",
        },
        {
          headerName: "End Date",
          field: "to_date",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "TripActionsButton",
        },
      ],
      tripsList: [],
      tripFilterDialog: false,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getTripsList(params = {}) {
      let filters = localStorage.getItem("tripFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }

      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.trips
        .getTripList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripsList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    createTrip() {
      this.$router.push("/app/admin/trips/create");
    },

    editTrip(id) {
      this.$router.push(`/app/admin/trips/edit/${id}`);
    },

    viewDetails(id) {
      this.$router.push(`/app/admin/trips/detail/${id}`);
    },
  },
};
</script>

<style>
</style>