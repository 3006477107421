<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-icon :color="iconColor" small :class="iconClass" class="mr-2">
          {{ icon }}
        </v-icon>
        <span class="text-body-2" v-if="text != null && text != undefined">
          {{ text }}
        </span>
      </div>
    </template>
    <span class="text-capitalize">{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
    },
    iconColor: {
      type: String,
      default: "text_color lighten-5",
    },
    text: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>