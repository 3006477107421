<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="editCompanyLocation"
      name="editCompanyLocation"
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editCompanyLocation()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    editCompanyLocation() {
      this.params.context.parentComponent.editCompanyLocation(
        this.params.data.id
      );
    },
  },
};
</script>

<style>
</style>