export const driverStatusOptions = [
  {
    name: "On Duty",
    value: "on_duty",
  },
  {
    name: "Off Duty",
    value: "off_duty",
  },
  {
    name: "On Trip",
    value: "on_trip",
  },
  {
    name: "On Break",
    value: "break",
  },
];

export const driverOrderingOptions = [
  {
    name: "First Name (Descending)",
    value: "-user__first_name",
  },
  {
    name: "First Name (Ascending)",
    value: "user__first_name",
  },
  {
    name: "Shift Start (Descending)",
    value: "-shift_start",
  },
  {
    name: "Shift Start (Ascending)",
    value: "shift_start",
  },
  {
    name: "Shift End (Descending)",
    value: "-shift_end",
  },
  {
    name: "Shift End (Ascending)",
    value: "shift_end",
  },
  {
    name: "Status (Descending)",
    value: "-status",
  },
  {
    name: "Status (Ascending)",
    value: "status",
  },
];

export const employeeOrderingOptions = [
  {
    name: "First Name (Descending)",
    value: "-user__first_name",
  },
  {
    name: "First Name (Ascending)",
    value: "user__first_name",
  },
 
];

export const routeTypeOptions = [
  {
    name: "Drop",
    value: "drop",
  },
  {
    name: "Pickup",
    value: "pick_up",
  },
];

export const tripStatusOptions = [
  {
    name: "Scheduled",
    value: "scheduled",
  },
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Paused",
    value: "paused",
  },
  {
    name: "Completed",
    value: "completed",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];

export const routePlanningOptions = [
  {
    name: "PENDING",
    value: "PENDING",
  },
  {
    name: "IN PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    name: "FAILED",
    value: "FAILED",
  },
  {
    name: "RETRYING",
    value: "RETRYING",
  },
  {
    name: "POST PROCESSING",
    value: "POST_PROCESSING",
  },
  {
    name: "COMPLETED",
    value: "COMPLETED",
  },
];
