import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router/index";
import DashComponents from "@feroai/dash-component-library";

// Var Imports
import colors from "@/assets/colors.js";
import api from "@/plugins/api.js";
import globalObject from "../src/plugins/globalConfig";
// import axiosInstance from "@/plugins/axios.js";

export const bus = new Vue();

Vue.config.productionTip = false;

Vue.use(DashComponents);

// Vue.prototype.$axios = axiosInstance;
Vue.prototype.$api = api;
Vue.prototype.$colors = colors;
Vue.prototype.$globalConstant = globalObject;

const app = new Vue({
  vuetify,
  router,
  // DashComponents,
  render: (h) => h(App),
}).$mount("#app");
