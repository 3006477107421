import RouteQuotationIndex from "../pages/admin/route_quotation/index.vue";
import RouteQuotationCreate from "../pages/admin/route_quotation/create/index.vue";
import RouteQuotationDetail from "../pages/admin/route_quotation/detail/_id/index.vue";

const RouteQuotation = [
  {
    path: "route_quotation",
    name: "route_quotation",
    component: RouteQuotationIndex,
  },
  {
    path: "route_quotation/create",
    name: "route_quotation/create",
    component: RouteQuotationCreate,
  },
  {
    path: "route_quotation/detail/:id",
    name: "route_quotation/detail/:id",
    component: RouteQuotationDetail,
  },
];

export default RouteQuotation;
