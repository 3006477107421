<template>
  <BaseListLayout
    ref="listLayout"
    title="Drivers"
    :table-header="headers"
    :table-data="driverList"
    :hasListActions="true"
    :total="totalItems"
    :context="context"
    @getList="getDriverList"
    name="driverSearch"
    id="driverSearch"
  >
    <template #listAction>
      <BaseButton
        id="driverAddBtn"
        small
        customClass="font-weight-bold rounded-lg mr-2"
        @click="addDriver()"
        >Add Driver</BaseButton
      >
      <BaseButton
        id="driverBulkUploadBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="showBulkUploadDialog = true"
        >Bulk Upload Drivers</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList
        id="refreshList"
        name="refreshList"
        :isIcon="true"
        newClass="ml-2 mr-2"
        @click="getDriverList({ offset: 0, limit: 10 })"
      ></BaseRefreshList>

      <BaseButton
        id="filterBtn"
        x-small
        fab
        depressed
        customClass="rounded-lg btn-small light_color_actions"
        icon="mdi-filter"
        iconColor="primary"
        iconClass="ma-1"
        iconSize="17px"
        @click="driverFilterDialog = true"
      ></BaseButton>
    </template>
    <template #dialogs>
      <DriverFilter
        v-model="driverFilterDialog"
        @driverFiltersChanged="$refs.listLayout.searchTriggered()"
      ></DriverFilter>

      <DriverForm
        ref="driverForm"
        v-model="openDriverForm"
        :editMode="editMode"
        :id="driverID"
        @refreshList="getDriverList({ offset: 0, limit: 10 })"
      />

      <BulkUpload
        uploadTo="drivers"
        v-model="showBulkUploadDialog"
        :hasBulkActions="true"
        @refreshList="getDriverList({ limit: 10, offset: 0 })"
      />
    </template>
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import DriverActionsButton from "@/components/AgGridButtons/DriverActionsButton.vue";
import DriverForm from "@/components/ModuleBasedComponents/drivers/DriverForm.vue";
import DriverFilter from "@/components/ModuleBasedComponents/drivers/DriverFilter.vue";
import BulkUpload from "@/components/BulkUploadComponents/BulkUpload.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseListLayout,
    DriverActionsButton,
    DriverForm,
    DriverFilter,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openDriverForm: false,
      editMode: false,
      showBulkUploadDialog: false,
      driverFilterDialog: false,
      headers: [
        {
          headerName: "Username",
          field: "username",
        },
        {
          headerName: "Full Name",
          field: "full_name",
        },
        {
          headerName: "Shift Time",
          valueGetter: function (params) {
            return params.data.shift_start + " - " + params.data.shift_end;
          },
        },
        {
          headerName: "Contact No",
          field: "contact_number",
        },
        {
          headerName: "Project",
          field: "project_id",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "DriverActionsButton",
        },
      ],
      driverList: [],
      driverID: null,
      filters: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getDriverList(params = {}) {
      let filters = localStorage.getItem("driverFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters.project_id && filters.project_id.length > 0) {
        filters.project_id = filters.project_id.join(",");
      }
      if (filters.ordering && filters.ordering.length > 0) {
        filters.ordering = filters.ordering.join(",");
      }
      this.filters = filters;
      params = {
        ...params,
        ...this.filters,
      };
      bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.driverList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addDriver() {
      this.editMode = false;
      this.openDriverForm = true;
    },

    editDriver(id) {
      this.editMode = true;
      this.$refs.driverForm.getDriverObject(id);
      this.openDriverForm = true;
    },

    viewDetails(id) {
      this.$router.push(`/app/admin/drivers/detail/${id}`);
    },
  },
};
</script>

<style>
</style>