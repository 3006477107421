import CompanyLocationsIndex from "../pages/admin/company_locations/index.vue";

const CompanyLocations = [
  {
    path: "company_locations",
    name: "company_locations",
    component: CompanyLocationsIndex,
  },
];

export default CompanyLocations;
