<template>
  <BaseListLayout
    title="Support Tickets"
    :table-header="headers"
    :table-data="tableData"
    :hasListActions="true"
    :total="tableData.length"
    :context="context"
    name="supportSearch"
    id="supportSearch"
  >
    <template #listAction>
      <BaseButton
        id="supportAddBtn"
        small
        customClass="font-weight-bold rounded-lg"
        @click="openSupportTicketForm = true"
        >Add Support Ticket</BaseButton
      >
    </template>
    <template #leftFilterSlot>
      <BaseRefreshList :isIcon="true" newClass="ml-2"></BaseRefreshList>
    </template>
    <!-- <template #dialogs>
      <SupportTicketForm v-model="openSupportTicketForm" />
    </template> -->
  </BaseListLayout>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import SupportActionsButton from "@/components/AgGridButtons/SupportActionsButton.vue";
import SupportTicketForm from "@/components/ModuleBasedComponents/support/SupportTicketForm.vue";
export default {
  components: {
    BaseListLayout,
    SupportActionsButton,
    SupportTicketForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      openSupportTicketForm: false,
      headers: [
        {
          headerName: "Title",
          field: "title",
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Module",
          field: "module",
        },
        {
          headerName: "Priority",
          field: "priority",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "SupportActionsButton",
        },
      ],
      tableData: [
        {
          title: "Dashboard Issue",
          status: "Open",
          module: "Dashboard",
          priority: "High",
        },
        {
          title: "Users Module Issue",
          status: "Open",
          module: "Users",
          priority: "Low",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },
};
</script>

<style>
</style>