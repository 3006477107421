<template>
  <BaseFormLayout
    :title="`${editMode ? 'Edit' : 'Add'} Project`"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="projectResetBtn"
        small
        class="mr-2 rounded-lg"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="projectSubmitBtn"
        small
        customClass="rounded-lg"
        @click="submitForm()"
        :loading="loading"
        :disabled="!isValid"
        >Submit</BaseButton
      >
    </template>
    <template #content>
      <v-form
        ref="projectForm"
        id="projectForm"
        name="projectForm"
        autocomplete="off"
        v-model="isValid"
        :key="formCount"
      >
        <v-row class="mx-1">
          <v-col cols="3" class="white rounded-lg">
            <v-row class="pa-6">
              <v-col cols="12" class="pa-1 mb-2">
                <BaseTextInput
                  id="project_id"
                  name="project_id"
                  label="Project ID"
                  :required="true"
                  :error-messages="
                    formErrors && formErrors.project_id
                      ? formErrors.project_id
                      : ''
                  "
                  v-model="projectFormDetails.project_id"
                  outlined
                  @input="
                    formErrors && formErrors.project_id
                      ? delete formErrors.project_id
                      : ''
                  "
                ></BaseTextInput>
              </v-col>
              <v-col cols="12" class="pa-1 mb-2">
                <BaseTextInput
                  id="project_name"
                  name="project_name"
                  label="Project Name"
                  :required="true"
                  :error-messages="
                    formErrors && formErrors.project_name
                      ? formErrors.project_name
                      : ''
                  "
                  v-model="projectFormDetails.project_name"
                  outlined
                  @input="
                    formErrors && formErrors.project_name
                      ? delete formErrors.project_name
                      : ''
                  "
                ></BaseTextInput>
              </v-col>
              <v-col cols="12" class="pa-1 mb-2">
                <v-text-field
                  ref="address"
                  id="address"
                  name="address"
                  placeholder="Project Address *"
                  hide-details="auto"
                  v-model="projectFormDetails.address"
                  :required="true"
                  dense
                  outlined
                  class="rounded-lg"
                  :rules="[(val) => !!val || 'Project Address is required']"
                  :error-message="
                    formErrors && formErrors.address ? formErrors.address : ''
                  "
                  @input="
                    formErrors && formErrors.address
                      ? delete formErrors.address
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pa-1">
                <!-- <BaseTextInput
                  :disabled="true"
                  id="latitude"
                  name="latitude"
                  v-model="projectFormDetails.base_coordinates.latitude"
                  :required="true"
                  label="Latitude"
                  :error-message="
                    formErrors && formErrors.latitude ? formErrors.latitude : ''
                  "
                  @input="
                    formErrors && formErrors.latitude
                      ? delete formErrors.latitude
                      : ''
                  "
                ></BaseTextInput> -->
                <v-text-field
                  type="text"
                  name="latitude"
                  label="Latitude *"
                  required
                  dense
                  hide-details="auto"
                  :disabled="true"
                  :rules="[(v) => !!v || 'Latitude is Required']"
                  :error-messages="
                    formErrors && formErrors.latitude ? formErrors.latitude : ''
                  "
                  class="rounded-lg"
                  v-model.number="projectFormDetails.base_coordinates.latitude"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pa-1">
                <!-- <BaseTextInput
                  :disabled="true"
                  id="longitude"
                  name="longitude"
                  v-model="projectFormDetails.base_coordinates.longitude"
                  :required="true"
                  label="Longitude"
                  :error-message="
                    formErrors && formErrors.longitude
                      ? formErrors.longitude
                      : ''
                  "
                  @input="
                    formErrors && formErrors.longitude
                      ? delete formErrors.longitude
                      : ''
                  "
                ></BaseTextInput> -->
                <v-text-field
                  type="text"
                  name="longitude"
                  label="Longitude *"
                  required
                  dense
                  hide-details="auto"
                  :disabled="true"
                  :rules="[(v) => !!v || 'Latitude is Required']"
                  :error-messages="
                    formErrors && formErrors.longitude
                      ? formErrors.longitude
                      : ''
                  "
                  class="rounded-lg"
                  v-model.number="projectFormDetails.base_coordinates.longitude"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="cf-zone-map pt-0">
            <GoogleMap
              mapWidth="100%"
              mapHeight="83vh"
              ref="projectFormMap"
              :isDrawable="true"
              :clusterMarkers="false"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseFormLayout>
</template>

<script>
import GoogleMap from "@/components/BaseComponents/GoogleMap.vue";
import { bus } from "@/main";
export default {
  components: {
    GoogleMap,
  },
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      projectFormDetails: {
        base_coordinates: {},
      },
      isValid: true,
      formCount: 0,
      loading: false,
    };
  },
  methods: {
    setAutoComplete() {
      const interval = setInterval(async () => {
        if (this.$refs.address && window.google && window.google.maps) {
          clearInterval(interval);

          let input = document.getElementById("address");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["SA"],
          });

          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.projectFormDetails.address =
                place.name + ", " + place.formatted_address;
              this.projectFormDetails.base_coordinates.latitude = lat;
              this.projectFormDetails.base_coordinates.longitude = lon;
            }, 100);

            let newObj = {};
            newObj = {
              coordinates: [
                place.geometry.location.lng(),
                place.geometry.location.lat(),
              ],
            };
            setTimeout(() => {
              this.$refs.projectFormMap.clearMarker();
              this.$refs.projectFormMap.addMultipleMarkers([newObj]);
            }, 100);
          });
        }
      }, 100);
    },

    getProjectDetails(id = null) {
      bus.$emit("showLoader", true);
      this.$api.projects
        .getProjectObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.projectFormDetails = res.data;
          this.loadEditablePolygon();
          let inter = setInterval(() => {
            if (this.$refs.projectFormMap) {
              clearInterval(inter);
              this.$refs.projectFormMap.addMultipleMarkers([
                {
                  coordinates: this.projectFormDetails.base_coordinates,
                },
              ]);
            }
          }, 100);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    loadEditablePolygon() {
      const path = [];

      const coordinatesList =
        this.projectFormDetails.serviceable_area.coordinates[0][0];
      if (!coordinatesList) return;
      coordinatesList.forEach((element) => {
        path.push({ lat: element[1], lng: element[0] });
      });

      let inter = setInterval(() => {
        if (this.$refs.projectFormMap && this.$refs.projectFormMap.map) {
          clearInterval(inter);

          this.$refs.projectFormMap.setMapCenter({
            lat: this.projectFormDetails.base_coordinates.latitude,
            lng: this.projectFormDetails.base_coordinates.longitude,
          });
          this.$refs.projectFormMap.setMapZoom(9);
          this.$refs.projectFormMap.setEditablePolygon(path, coordinatesList);
        }
      });
    },
    submitForm() {
      if (!this.$refs.projectForm.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = { ...this.projectFormDetails };

      let geoJSON = this.$refs.projectFormMap.convertToGeoJSON();
      if (!geoJSON) {
        bus.$emit("showToastMessage", {
          message: "Please Draw your geofence before submitting the form!",
          color: "error",
        });
        bus.$emit("showLoader", false);
        return;
      }
      data.serviceable_area = geoJSON.geometry;

      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.projects
          .createProject(data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Project created!",
              color: "success",
            });
            this.$router.back();
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data) {
              this.formErrors = err.data;
            }
          });
      } else {
        this.$api.projects
          .updateProjectObject({
            id: this.projectFormDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Project information updated!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      this.$refs.projectForm.reset();
      this.$refs.projectForm.resetValidation();
      this.$refs.projectFormMap.deleteShape();
      this.$refs.projectFormMap.removeAllMarkers();
    },

    getDefaultServiceableArea(params) {
      return new Promise((resolve, reject) => {
        bus.$emit("showLoader", true);
        this.$api.projects
          .getDefaultServiceableArea(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            let interval = setInterval(() => {
              if (
                this.$refs &&
                this.$refs.projectFormMap &&
                this.$refs.projectFormMap.map
              ) {
                clearInterval(interval);
                this.$refs.projectFormMap.initMap();
                this.$refs.projectFormMap.loadDisabledMultiPolygon(
                  res.data.serviceable_area.coordinates
                );
                setTimeout(() => {
                  resolve();
                }, 400);
              }
            }, 100);
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
            reject();
          });
      });
    },
  },
  async mounted() {
    this.setAutoComplete();
    await this.getDefaultServiceableArea();
    if (this.$route.params.id) {
      setTimeout(() => {
        this.getProjectDetails(this.$route.params.id);
      }, 100);
    }
  },
};
</script>

<style>
</style>