<template>
  <CompanyForm :editMode="false"></CompanyForm>
</template>

<script>
import CompanyForm from "@/components/ModuleBasedComponents/company/CompanyForm.vue";
export default {
  components: {
    CompanyForm,
  },
};
</script>

<style>
</style>