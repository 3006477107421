<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="detailsRouteQuotation"
      name="detailsRouteQuotation"
      iconName="mdi-eye"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="View Details"
      @click="viewRouteQuotationDetails()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    viewRouteQuotationDetails() {
      this.params.context.parentComponent.viewRouteQuotationDetails(
        this.params.data.id
      );
    },
  },
};
</script>

<style>
</style>