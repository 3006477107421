<template>
  <v-menu v-model="menu" :close-on-content-click="false" left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip color="black" left max-width="300">
        <template #activator="tooltipObj">
          <v-badge
            class="mr-2 btn-filter-small"
            top
            color="warning"
            overlap
            :content="visibleCols.length"
            :value="visibleCols.length"
          >
            <v-btn
              x-small
              fab
              depressed
              color="primary"
              class="ml-1 rounded-lg btn-small"
              outlined
              v-on="{ ...on, ...tooltipObj.on }"
              v-bind="{ ...attrs, ...tooltipObj.attrs }"
              @click="rightDrawer = true"
            >
              <v-icon> mdi-format-list-checks </v-icon>
            </v-btn>
          </v-badge>
        </template>
        <div>
          <span class="font-weight-bold">Selected Headers to Show</span> <br />
          <span v-if="selectedColumns">{{ selectedColumns }}</span>
        </div>
      </v-tooltip>
    </template>

    <div id="col-select-item-list-container">
      <v-list dense max-height="350">
        <v-list-item
          class="white"
          :selectable="false"
          v-for="(header, i) in allowedHeaders"
          :key="i"
          @click="toggleSelection(header)"
          :disabled="
            isSelected(header) && tableHeaders && tableHeaders.length < 3
          "
        >
          <v-list-item-icon>
            <v-icon
              v-if="isSelected(header)"
              :color="
                tableHeaders && tableHeaders.length < 3 ? 'grey' : 'primary'
              "
            >
              mdi-checkbox-marked
            </v-icon>
            <v-icon v-else> mdi-checkbox-blank-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="text-capitalize"
              v-text="header.headerName"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
/**

* This component allow user to toggle

* columns in ag-grid using ag-grid's

* column API instance provided in

* 'gridColInstance'.

*

* This accepts ag-grid headers list and

* excludes the columns listed in 'hideColumns'

* Note: 'actions' field is by default excluded  

*

* If the localStorage key is provided is

* sync the grid status with the server

*/

import { bus } from "@/main";
// import { getAllUserPreferances, getUserPreferances } from "@/utils/functions";

export default {
  emits: ["headersChanged"],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    gridColInstance: {
      type: Object,
    },
    localStorageKey: {
      type: String,
    },
    hideColumns: {
      default: () => ["actions", "warning"],
    },
  },
  data() {
    return {
      menu: false,
      state: [],
      initialLoadInterval: null,
      instanceTimeout: null,
    };
  },
  computed: {
    allowedHeaders() {
      return this.headers.filter(
        (head) => [...this.hideColumns].indexOf(head.field) == -1
      );
    },
    visibleCols() {
      return this.state.filter((col) => !col.hide).map((col) => col.colId);
    },
    tableHeaders() {
      return this.visibleCols.filter((v) => !this.hideColumns.includes(v));
    },
    selectedColumns() {
      return this.headers
        .filter((head) => this.visibleCols.indexOf(head.field) > -1)
        .map((col) => col.headerName)
        .join(", ");
    },
  },
  methods: {
    toggleSelection(col) {
      this.gridColInstance.setColumnVisible(col.field, !this.isSelected(col));
      this.$emit("headersChanged");
      setTimeout(() => {
        this.getColState();
        this.syncPreferences();
      }, 100);
    },
    isSelected(col) {
      if (this.state && this.state.length) {
        const state = this.state.find((cs) => cs.colId == col.field);
        if (state) {
          return !state.hide;
        }
      } else {
        return true;
      }
    },
    getColState() {
      if (this.gridColInstance) {
        this.state = this.gridColInstance.getColumnState();
      }
    },
    syncPreferences() {
      if (this.instanceTimeout) {
        clearTimeout(this.instanceTimeout);
      }
      this.getColState();
      // this.instanceTimeout = setTimeout(() => {
      //   if (this.localStorageKey) {
      //     let allPrefrence = getAllUserPreferances();
      //     allPrefrence[this.localStorageKey] = this.state;
      //     localStorage.setItem("userPreferences", JSON.stringify(allPrefrence));
      //   }
      // }, 1000);
    },
    loadState() {
      if (this.initialLoadInterval) {
        clearInterval(this.initialLoadInterval);
      }
      this.initialLoadInterval = setInterval(() => {
        if (this.gridColInstance) {
          clearInterval(this.initialLoadInterval);
          let preference;
          if (!this.localStorageKey) {
            this.getColState();
            return;
          }
          // preference = getUserPreferances(this.localStorageKey);
          // if (preference) {
          //   this.gridColInstance.applyColumnState({ state: preference });
          //   preference.forEach((col, index) => {
          //     this.gridColInstance.moveColumn(col.colId, index);
          //   });
          // }
          this.getColState();
        }
      }, 50);
      setTimeout(() => {
        if (this.initialLoadInterval) {
          clearInterval(this.initialLoadInterval);
        }
      }, 10000);
    },
  },
  mounted() {
    this.loadState();
    bus.$on("syncPreferences", this.syncPreferences);
  },
  beforeDestroy() {
    bus.$off("syncPreferences");
  },
};
</script>

<style scoped>
.btn-filter-small button {
  height: 36px !important;
}
.btn-small {
  min-width: 36px !important;
  width: 36px !important;
  height: 36px !important;
}
</style>
