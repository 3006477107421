<template>
  <driverDetail></driverDetail>
</template>

<script>
import DriverDetail from "@/components/ModuleBasedComponents/drivers/DriverDetail.vue";
export default {
  components: { DriverDetail },
};
</script>

<style>
</style>