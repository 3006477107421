import { vehicle } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVehicleList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vehicle.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createVehicle(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(vehicle.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleDetailsView(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleTripList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicle.base}trip_list/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateVehicleObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${vehicle.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  partiallyUpdateVehicleObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${vehicle.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
