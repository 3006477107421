<template>
  <v-menu
    v-model="openNotifications"
    :close-on-content-click="false"
    max-width="400px"
    nudge-left="345"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="unreadCount"
        :color="unreadCount == 0 ? '' : 'green'"
        class="mr-4"
        top
        overlap
      >
        <span>
          <v-btn
            text
            small
            icon
            class="mr-n5 primary"
            v-on="on"
            v-bind="attrs"
            :class="openNotifications ? 'text-primary' : ''"
          >
            <v-icon color="primary">mdi-bell</v-icon>
          </v-btn>
        </span>
      </v-badge>
    </template>
    <v-card width="400px" elevation="0">
      <v-card-title
        class="pt-4 pb-2 text-primary body-1 d-flex justify-space-between"
      >
        <span>Notifications</span>
        <v-btn
          :disabled="userNotifications.length <= 0"
          small
          text
          depressed
          class="primary--text"
          @click="markAllAsRead"
          >Clear All</v-btn
        >
      </v-card-title>
      <v-card-text
        elevation-0
        style="max-height: 350px"
        class="pa-0 overflow-y-auto overflow-x-hidden"
        @scroll="handleScroll()"
        ref="notificationParent"
      >
        <div
          v-if="
            userNotifications &&
            userNotifications.length == 0 &&
            !noNewNotifications
          "
          class="d-flex justify-center py-2"
        >
          <v-progress-circular
            :width="3"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else-if="noNewNotifications" class="d-flex justify-center py-2">
          <span>No new Notifications!</span>
        </div>
        <div v-for="(noti, i) in userNotifications" :key="i" v-else>
          <v-row
            class="pl-4 pa-0 my-1 notiication"
            v-if="!noti.is_read"
            :style="{ backgroundColor: 'white' }"
          >
            <v-col cols="12" class="pa-0 ma-0">
              <hr style="opacity: 0.4" />
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-center">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 15px;
                "
                :class="[
                  noti.notification_type == 'success' ? 'successMessage' : '',
                  noti.notification_type == 'warning' ? 'warningMessage' : '',
                  noti.notification_type == 'info' ? 'infoMessage' : '',
                  noti.notification_type == 'error' ? 'errorMessage' : '',
                ]"
              >
                <v-icon
                  color="white"
                  v-if="noti.notification_category == 'driver'"
                  >mdi-card-account-details-outline</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'order'"
                  >mdi-archive-outline</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'trip'"
                  >mdi-truck-fast</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'trip_planning'"
                  >mdi-map-marker-distance</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'location'"
                  >mdi-map-marker</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'customer'"
                  >mdi-account</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'vehicle'"
                  >mdi-truck</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'report'"
                  >mdi-chart-areaspline</v-icon
                >
                <v-icon
                  color="white"
                  v-else-if="noti.notification_category == 'user'"
                  >mdi-account-check</v-icon
                >
              </div>
            </v-col>
            <v-col cols="8" class="d-flex align-center">
              <v-row no-gutters>
                <v-col cols="12" class="text-body-2">
                  <span>
                    {{ noti.title }}
                  </span>
                </v-col>
                <v-col cols="12" class="text-caption">
                  {{ noti.message }}
                </v-col>
                <v-col cols="12" class="text-caption font-weight-bold mt-1">
                  {{ noti.created }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" class="d-flex align-center">
              <v-btn
                small
                icon
                @click="markAsRead(noti.id)"
                class="primary--text"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      notiInterval: null,
      limit: 10,
      unreadCount: 0,
      noNewNotifications: false,
      userNotifications: [],
      totalCount: null,
    };
  },
  computed: {
    openNotifications: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.notiication:hover {
  background-color: #ebf7fc !important;
}

.successMessage {
  background-color: #d1d3d4;
}
.errorMessage {
  background-color: #ee919d;
}
.warningMessage {
  background-color: #e1af0f;
}
.infoMessage {
  background-color: #6bcadc;
}
</style>
