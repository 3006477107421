import Vue from "vue";
import VueRouter from "vue-router";

import DefaultLayout from "../layouts/DefaultLayout.vue";
import AdminLayout from "../layouts/AdminLayout.vue";

import Login from "../pages/login/Login.vue";
import AdminRoutes from "../router/admin";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/app",
    },
    {
      path: "/app",
      component: DefaultLayout,
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
        },
        {
          path: "admin",
          name: "admin",
          component: AdminLayout,
          children: AdminRoutes,
        },
      ],
    },
    {
      path: "**",
      redirect: "/app",
    },
  ],
});

export default router;
