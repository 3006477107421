<template>
  <BaseFormLayout
    :title="`${editMode ? 'Edit' : 'Add'} Route`"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="routesResetBtn"
        small
        class="mr-2 rounded-lg"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="routesSubmitBtn"
        small
        customClass="rounded-lg"
        :disabled="!isValid"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
    <template #content>
      <v-form
        ref="routesForm"
        id="routesForm"
        name="routesForm"
        v-model="isValid"
      >
        <v-row class="mx-2">
          <v-col cols="12" class="white rounded-lg">
            <v-card elevation="0">
              <v-card-title class="pa-2">
                <h4 class="text-body-1 font-weight-bold black--text">
                  Basic Info.
                </h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="3">
                    <BaseTextInput
                      id="route_id"
                      name="route_id"
                      label="Route ID"
                      v-model="routeFormDetails.route_id"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.route_id
                          ? formErrors.route_id
                          : ''
                      "
                      @input="
                        formErrors && formErrors.route_id
                          ? delete formErrors.route_id
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>

                  <v-col cols="3">
                    <BaseTextInput
                      id="route_name"
                      name="route_name"
                      label="Route Name"
                      v-model="routeFormDetails.route_name"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.route_name
                          ? formErrors.route_name
                          : ''
                      "
                      @input="
                        formErrors && formErrors.route_name
                          ? delete formErrors.route_name
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>

                  <v-col cols="3">
                    <BaseSelect
                      id="route_type"
                      name="route_type"
                      v-model="routeFormDetails.route_type"
                      :required="true"
                      label="Select Route Type *"
                      :itemsList="routeTypeList"
                      :isClearable="false"
                      item-text="name"
                      item-value="value"
                      :rules="[(val) => !!val || 'Route Type is required']"
                      :error-messages="
                        formErrors && formErrors.route_type
                          ? formErrors.route_type
                          : ''
                      "
                      @input="
                        formErrors && formErrors.route_type
                          ? delete formErrors.route_type
                          : ''
                      "
                    ></BaseSelect>
                  </v-col>

                  <v-col cols="3">
                    <BaseSelect
                      id="project"
                      name="project"
                      v-model="routeFormDetails.project"
                      :required="true"
                      label="Select Project *"
                      :itemsList="projectList"
                      :isClearable="false"
                      item-text="name"
                      item-value="value"
                      :rules="[(val) => !!val || 'Project is required']"
                      :error-messages="
                        formErrors && formErrors.project
                          ? formErrors.project
                          : ''
                      "
                      @change="
                        formErrors && formErrors.project
                          ? delete formErrors.project
                          : '',
                          getCompanyList({ project_id: routeFormDetails.project })
                      "
                    ></BaseSelect>
                  </v-col>

                  <v-col cols="3">
                    <BaseSelect
                      id="company"
                      name="company"
                      v-model="routeFormDetails.company"
                      :required="true"
                      label="Select Company *"
                      :itemsList="companyList"
                      :isClearable="false"
                      item-text="name"
                      item-value="value"
                      :rules="[(val) => !!val || 'Company is required']"
                      :error-messages="
                        formErrors && formErrors.company
                          ? formErrors.company
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company
                          ? delete formErrors.company
                          : '',
                          getCompanyAddressesList({
                            company_id: routeFormDetails.company,
                          }),
                          getCompanyShiftList({
                            company_id: routeFormDetails.company,
                          }),
                          sendFiltersToEmployees('company_id', $event)
                      "
                    ></BaseSelect>
                  </v-col>

                  <v-col cols="3">
                    <BaseSelect
                      id="company_address"
                      name="company_address"
                      v-model="routeFormDetails.company_address"
                      :required="true"
                      label="Select Company Address *"
                      :itemsList="companyAddressList"
                      :isClearable="false"
                      item-text="location_name"
                      item-value="id"
                      :rules="[(val) => !!val || 'Company Address is required']"
                      :error-messages="
                        formErrors && formErrors.company_address
                          ? formErrors.company_address
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company_address
                          ? delete formErrors.company_address
                          : ''
                      "
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect
                      id="company_shift"
                      name="company_shift"
                      v-model="routeFormDetails.company_shift"
                      :required="true"
                      label="Select Shift Time *"
                      :itemsList="shiftList"
                      :isClearable="false"
                      item-text="name"
                      item-value="value"
                      :rules="[(val) => !!val || 'Shift Time is required']"
                      :error-messages="
                        formErrors && formErrors.company_shift
                          ? formErrors.company_shift
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company_shift
                          ? delete formErrors.company_shift
                          : ''
                      "
                    ></BaseSelect>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0 d-flex justify-space-between">
          <v-col cols="12" class="ma-0 pa-0">
            <v-row class="mt-10 white mx-2 rounded-lg">
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-title class="pa-2">
                    <h4 class="text-body-1 font-weight-bold black--text">
                      Stops & Passengers Details
                    </h4>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="d-flex">
                      <v-col cols="1"></v-col>
                      <v-col cols="2">
                        <h4
                          class="text-uppercase light_grey--text font-weight-bold text-caption"
                        >
                          stops
                        </h4>
                      </v-col>
                      <v-col
                        cols="2"
                        class="d-flex justify-end align-center ml-4"
                      >
                        <h4
                          class="text-uppercase light_grey--text font-weight-bold text-caption"
                        >
                          no. of passengers
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-timeline dense align-top>
                          <div
                            v-for="(route_stop, index) in route_stops"
                            :key="index"
                          >
                            <v-timeline-item
                              small
                              icon="mdi-check"
                              color="primary"
                            >
                              <v-row class="">
                                <v-col cols="3">
                                  <BaseSelect
                                    id="location_id"
                                    name="location_id"
                                    v-model="route_stop.location_id"
                                    :required="true"
                                    label="Pickup Location *"
                                    :itemsList="locationList"
                                    itemText="location_name"
                                    itemValue="id"
                                    :item-disabled="isItemDisabled"
                                    :isClearable="false"
                                    :rules="[
                                      (val) =>
                                        !!val || 'Pickup Location is required',
                                    ]"
                                    :error-messages="
                                      formErrors && formErrors.location_id
                                        ? formErrors.location_id
                                        : ''
                                    "
                                    @change="
                                      formErrors && formErrors.location_id
                                        ? delete formErrors.location_id
                                        : '',
                                        sendFiltersToEmployees(
                                          routeFormDetails.route_type == 'drop'
                                            ? 'drop_location'
                                            : 'pickup_location',
                                          $event
                                        )
                                    "
                                  ></BaseSelect>

                                  <div class="d-flex justify-end mt-2">
                                    <BaseSecondaryButton
                                      id="addPickupRouteBtn"
                                      icon="mdi-plus"
                                      customClass="routeButtons mr-2"
                                      iconColor="primary"
                                      iconClass="mx-1"
                                      x-small
                                      outlined
                                      @click="addPickUpRoute()"
                                    ></BaseSecondaryButton>
                                    <BaseSecondaryButton
                                      :disabled="route_stops.length == 1"
                                      id="removePickupRouteBtn"
                                      icon="mdi-minus"
                                      customClass="routeButtons red--text"
                                      iconColor="red"
                                      iconClass="mx-1"
                                      x-small
                                      outlined
                                      @click="removePickUpRoute(index)"
                                    ></BaseSecondaryButton>
                                  </div>
                                </v-col>

                                <!-- <v-col cols="2">
                              <BaseTimeInput
                                id="time"
                                name="time"
                                v-model="route_stop.time"
                                label="Time"
                                :required="true"
                              ></BaseTimeInput>
                            </v-col> -->

                                <v-col cols="2" class="d-flex">
                                  <BaseButton
                                    :disabled="
                                      route_stop.total_passengers > 0 ||
                                      route_stop.location_id == null
                                    "
                                    id="passengersBtn"
                                    icon="mdi-account"
                                    iconColor="black"
                                    hint="Please select stop to add passenger(s)"
                                    outlined
                                    :customClass="
                                      route_stop.total_passengers > 0
                                        ? 'disabledPassengersClass light_button_grey rounded-lg'
                                        : 'passengersClass light_button_grey rounded-lg'
                                    "
                                    @click="addPassengers(index)"
                                  >
                                    <span
                                      class="black--text font-weight-bold text-body-2 text-capitalize"
                                    >
                                      ({{
                                        route_stop.total_passengers &&
                                        route_stop.total_passengers > 0
                                          ? route_stop.total_passengers
                                          : 0
                                      }}) &nbsp;&nbsp; Passenger(s)</span
                                    >
                                  </BaseButton>

                                  <v-btn
                                    icon
                                    v-if="route_stop.total_passengers > 0"
                                    @click="passengersData(passengersList)"
                                  >
                                    <v-icon color="primary" small
                                      >mdi-eye</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-timeline-item>
                          </div>
                        </v-timeline>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-col cols="2" class="d-flex justify-end ma-0 pa-0">
            <v-row class="mt-10 white ml-2 mr-1 rounded-lg">
              <v-col cols="12">
                <h4 class="text-body-1 font-weight-medium">Source Location</h4>

                <h5 class="text-body-2 font-weight-normal red--text">
                  {{ sourceAddress }}
                </h5>
              </v-col>
              <v-col cols="12">
                <h4 class="text-body-1 font-weight-medium">
                  Destination Location
                </h4>
                <h5 class="text-body-2 font-weight-normal">Address</h5>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
      </v-form>
    </template>

    <template #dialogs>
      <AddEmployeeToRoutes
        ref="addEmployeesToRoutes"
        v-model="openAddEmployees"
        @passengersList="passengersList"
      ></AddEmployeeToRoutes>
    </template>
  </BaseFormLayout>
</template>

<script>
import AddEmployeeToRoutes from "@/components/ModuleBasedComponents/employees/AddEmployeeToRoutes.vue";
import AddRouteStops from "./AddRouteStops.vue";
import { bus } from "@/main";
export default {
  components: { AddEmployeeToRoutes, AddRouteStops },
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      isValid: true,
      openAddEmployees: false,
      routeFormDetails: {},
      route_stops: [{}],
      destination_stop: {},
      companyList: [],
      projectList: [],
      shiftList: [],
      formErrors: {
        non_field_errors: [],
      },
      routeTypeList: [
        {
          name: "Drop",
          value: "drop",
        },
        {
          name: "Pick Up",
          value: "pick_up",
        },
      ],
      locationList: [],
      companyAddressList: [],
      filters: {},
      total_passengers: 0,
      routeStopIndex: null,
    };
  },
  computed: {
    selectedRouteStops() {
      return this.route_stops.map((item) => item.location_id);
    },
    sourceAddress() {
      return this.route_stops[0];
    },
  },
  methods: {
    isItemDisabled(item) {
      return this.selectedRouteStops.indexOf(item.id) > -1;
    },
    passengersList(data) {
      let newEmployeesArray = data.map((obj) => obj.id);
      this.route_stops[this.routeStopIndex]["employee_ids"] = newEmployeesArray;
      this.route_stops[this.routeStopIndex]["total_passengers"] = data.length;
    },
    getRouteObject(id) {
      bus.$emit("showLoader", true);
      this.$api.routes
        .getRouteObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeFormDetails = res.data;
          this.route_stops = res.data.route_stops;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
          if (err.data.non_field_errors) {
            this.formErrors.non_field_errors = err.data.non_field_errors;
          }
        });
    },
    resetForm() {
      this.$refs.routesForm.reset();
      this.$refs.routesForm.resetValidation();
    },
    scrollToBottom() {
      setTimeout(() => {
        let elem = document.getElementById("routesForm");
        elem.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 100);
    },
    addPassengers(index) {
      this.openAddEmployees = true;
      this.routeStopIndex = index;
      this.$refs.addEmployeesToRoutes.getEmployeesList(this.filters);
    },
    addPickUpRoute() {
      this.route_stops.push({});
      this.scrollToBottom();
    },
    sendFiltersToEmployees(key, value) {
      this.filters[key] = value;

      if (this.filters.drop_location) {
        this.filters.is_selected_on_drop_route = true;
      }
      if (this.filters.pickup_location) {
        this.filters.is_selected_on_pickup_route = true;
      }
    },
    removePickUpRoute(index) {
      this.route_stops.splice(index, 1);
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        this.shiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.shift_id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          this.companyAddressList = res.data;
        });
    },
    getPickupDropStopsList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.pickup_drop_stops.getPickupDropStopsList(params).then((res) => {
        this.locationList = res.data;
      });
    },
    submitForm() {
      if (!this.$refs.routesForm.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }

      let data = {
        ...this.routeFormDetails,
        route_stops: this.route_stops,
      };
      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.routes
          .createRoute(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Route Created!",
              color: "success",
            });
            this.$router.back();
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            console.error(err);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        this.$api.routes
          .updateRouteObject({
            id: this.routeFormDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Route Information Updated!",
              color: "success",
            });
            this.$router.back();
            this.resetForm();
          })
          .catch((err) => {
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      }
    },
  },
  mounted() {
    this.getProjectsList();
    this.getPickupDropStopsList();
    this.sourceAddress;
    if (this.$route.params.id) {
      this.getRouteObject(this.$route.params.id);
      this.getCompanyList({ project: this.routeFormDetails.project });
      this.getCompanyAddressesList({
        company_id: this.routeFormDetails.company,
      });
      this.getCompanyShiftList({ company_id: this.routeFormDetails.company });
    }
  },
};
</script>

<style>
.routeButtons {
  min-width: 20px !important;
  width: 20px !important;
  height: 20px !important;
  align-content: center;
}
</style>