<template>
  <div class="d-flex justify-start align-center mt-1">
    <BaseActionsButton
      id="detailsDriver"
      name="detailsDriver"
      iconName="mdi-eye"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="View Details"
      @click="viewDetails()"
    ></BaseActionsButton>
    <BaseActionsButton
      id="editDriver"
      name="editDriver"
      iconName="mdi-pencil"
      iconColor="primary"
      customClass="mr-2"
      tooltipMessage="Edit Details"
      @click="editDriver()"
    ></BaseActionsButton>
  </div>
</template>

<script>
export default {
  methods: {
    editDriver() {
      this.params.context.parentComponent.editDriver(this.params.data.id);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
  },
};
</script>

<style>
</style>