<template>
  <div class="operations-layout pa-3">
    <div class="operations-layout-list px-2">
      <RoutesList
        :selectedId="selectedRouteId"
        @selectedRouteDetails="selectedRouteDetails"
      />
    </div>
    <div class="d-flex flex-column px-2 pt-0">
      <div>
        <RouteCardDetails
          v-if="selectedRouteId"
          :route="selectedRouteData"
          :selectedId="selectedRouteId"
        ></RouteCardDetails>
        <div v-else>
          <v-row
            class="d-flex justify-center align-center"
            style="height: calc(100vh - 220px)"
          >
            <v-col cols="12" class="d-flex justify-center align-center">
              <h4>Please Select a Route!!</h4>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoutesList from "@/components/ModuleBasedComponents/routes/RoutesList.vue";
import RouteCardDetails from "@/components/ModuleBasedComponents/routes/RouteCardDetails.vue";
import { bus } from "@/main";
export default {
  components: {
    RoutesList,
    RouteCardDetails,
  },
  data() {
    return {
      routesList: [],
      selectedRouteId: null,
      selectedRouteData: {},
    };
  },
  methods: {
    async selectedRouteDetails(data, id) {
      this.selectedRouteData = data;
      this.selectedRouteId = id;
    },
  },
  mounted() {},
};
</script>

<style>
.operations-layout .operations-layout-list .v-item-group {
  height: 35px;
}
.operations-layout {
  display: grid;
  grid-template-columns: 400px 1fr;
  height: calc(100vh - 65px);
  background-color: #f5f5f5;
}
</style>