<template>
  <div>
    <v-row class="white rounded-lg pa-0 ma-0">
      <v-col cols="12" class="d-flex justify-space-between">
        <div>
          <h3 class="text-uppercase text-h6 text_color--text font-weight-bold">
            Routes
          </h3>
        </div>
        <div>
          <BaseButton
            small
            customClass="rounded-lg font-weight-bold"
            id="routesAddBtn"
            @click="addRoute()"
            >Add Route</BaseButton
          >
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <BaseTextInput
          id="routesSearch"
          name="routesSearch"
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
          v-model="searchRoutes"
          label="Search Route"
          clearable
          @keydown.enter="getRoutesList(true)"
          @click:clear="clearRouteSearch()"
        ></BaseTextInput>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length !== 0"
            >
              <v-btn
                small
                fab
                depressed
                class="rounded-lg btn-small light_color_actions ml-2"
                @click="routesFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small color="primary">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span class="text-capitalize">Routes Filter</span>
        </v-tooltip>

        <!-- <BaseButton
          id="filterBtn"
          x-small
          fab
          depressed
          customClass="rounded-lg btn-small light_color_actions"
          icon="mdi-filter"
          iconColor="primary"
          iconClass="ma-1"
          iconSize="17px"
          @click="routesFilterDialog = true"
        ></BaseButton> -->
      </v-col>
      <v-col
        cols="12"
        class="px-2 pb-2"
        v-if="appliedFilters && appliedFilters.length !== 0"
      >
        <v-chip
          small
          close
          class="mr-1 mb-1 text-capitalize light_color_actions primary--text font-weight-bold"
          v-for="(filter, index) in appliedFilters"
          :key="index"
          @click:close="removeFilter(filter)"
        >
          {{ filter }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col
        cols="12"
        ref="routesParent"
        class="pb-12 overflow-y-auto"
        @scroll="scrollControl"
        style="height: calc(100vh - 220px)"
      >
        <v-expansion-panels
          v-model="routesExpansion"
          flat
          multiple
          class="app-list-card-expansion"
        >
          <v-expansion-panel
            elevation="0"
            v-for="(route, i) in routesList"
            :key="i"
            class="px-3 py-1 ma-0 mb-2 rounded-lg"
            :class="
              selectedId && route && selectedId == route.id
                ? 'sub_heading darken-1'
                : ''
            "
          >
            <RoutesCard :route="route" @selectRoute="getRouteView(route.id)" />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <div id="dialogs">
      <RoutesFilter
        v-model="routesFilterDialog"
        @routeFiltersChanged="filterChanged"
      ></RoutesFilter>
    </div>
  </div>
</template>

<script>
import RoutesFilter from "./RoutesFilter.vue";
import RoutesCard from "./RoutesCard.vue";
import { bus } from "@/main";
export default {
  components: {
    RoutesFilter,
    RoutesCard,
  },

  props: {
    selectedId: {
      type: Number,
    },
  },

  data() {
    return {
      searchRoutes: null,
      routesExpansion: [0],
      routesList: [],
      totalItems: 0,
      filters: {},
      routesFilterDialog: false,
    };
  },
  computed: {
    appliedFilters() {
      return Object.keys(this.filters).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    removeFilter(key) {
      let actualKey = key.replace(" ", "_");
      let routesFilter = JSON.parse(localStorage.getItem("routeFilters"));
      delete routesFilter[actualKey];
      this.filters = routesFilter;
      localStorage.setItem("routeFilters", JSON.stringify(routesFilter));
      this.filterChanged();
    },
    filterChanged() {
      let filters = localStorage.getItem("routeFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.filters = filters;
      this.getRoutesList(true);
    },
    getRoutesFilter() {
      let filters = localStorage.getItem("routeFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("project_id" in filters && filters != null) {
        filters.project_id = filters.project_id.join(",");
      }
      if ("company_id" in filters && filters.company_id != null) {
        filters.company_id = filters.company_id.toString();
      }

      return filters;
    },
    clearRouteSearch() {
      this.searchRoutes = null;
      this.getRoutesList(false);
    },
    scrollControl() {
      let elem = this.$refs.routesParent;
      if (elem == null) {
        return false;
      } else if (
        Math.ceil(elem.offsetHeight + elem.scrollTop) + 50 ==
        elem.scrollHeight + 50
      ) {
        this.getRoutesList(false);
      }
    },
    getRoutesList(reload, params = {}) {
      params = {
        ...params,
        ...this.getRoutesFilter(),
        offset: reload ? 0 : this.routesList.length,
        limit: 10,
      };
      if (this.searchRoutes) {
        params.search = this.searchRoutes;
      }
      if (reload || this.totalItems != this.routesList.length) {
        bus.$emit("showLoader", true);
        this.$api.routes
          .getRoutesList(params)
          .then((res) => {
            if (reload) {
              this.routesList = res.data;
            } else {
              this.$nextTick(() => {
                this.routesList = [...this.routesList, ...res.data];
              });
            }
            this.totalItems = res?.count;
            bus.$emit("showLoader", false);
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            console.error(err);
          });
      }
    },
    getRouteView(id) {
      bus.$emit("showLoader", true);
      this.$api.routes
        .getRouteView(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.$emit("selectedRouteDetails", res.data, id);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    addRoute() {
      this.$router.push("/app/admin/routes/create");
    },
  },
  mounted() {
    let filters = localStorage.getItem("routeFilters");
    if (!filters) {
      filters = {};
    }
    if (typeof filters == typeof "string") {
      filters = JSON.parse(filters);
    }
    this.filters = filters;
    this.getRoutesList({}, true);
  },
};
</script>

<style>
.expansionPanel .v-expansion-panel-content-wrap {
  padding: 0%;
}
</style>