import { employee } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getEmployeesList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(employee.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getEmployeeObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${employee.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  createEmployee(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(employee.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateEmployeesObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${employee.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
