<template>
  <BaseDetailLayout title="Route Quotation Details">
    <template #action>
      <BaseButton id="exportRoute" class="rounded-lg" small @click="exportRoute"
        >Export Route(s)</BaseButton
      >
    </template>
    <template #detail>
      <v-row no-gutters class="ma-0 px-3 pt-3">
        <v-col cols="12" md="3" lg="3" class="d-flex flex-column">
          <v-card elevation="0" class="primary rounded-lg">
            <v-row
              no-gutters
              v-if="routeQuotationDetail.route_quotation_details"
              class="pa-2"
            >
              <v-col cols="4" md="4" lg="4" class="pa-2">
                <img :src="RouteQuotationImage" height="100px" width="100px" />
              </v-col>
              <v-col class="pa-4 d-flex flex-column justify-space-between">
                <div>
                  <h4 class="text-caption white--text">Route Quotation ID</h4>
                  <h5 class="white--text">
                    {{
                      routeQuotationDetail.route_quotation_details
                        .route_quotation_id
                    }}
                  </h5>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <h4 class="text-caption white--text">Route Type</h4>
                    <h5 class="white--text">
                      {{
                        routeQuotationDetail.route_quotation_details.route_type
                      }}
                    </h5>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-0 ma-0" elevation="0">
            <v-row class="pa-0 ma-0" no-gutters>
              <v-col cols="12" md="12" lg="12" class="pa-4">
                <h4 class="text-caption">Location Address</h4>
                <h5 class="text-body-2 font-weight-bold">
                  {{
                    routeQuotationDetail.route_quotation_details
                      .location_address
                  }}
                </h5>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start pb-2 px-4"
              >
                <h4 class="text-caption d-flex justify-start">
                  Vehicle Fill Ratio(%)
                </h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-start">
                  {{
                    routeQuotationDetail.route_quotation_details
                      .vehicle_fill_ratio
                  }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-end px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-end">Trip Start Time</h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-end">
                  {{ routeQuotationDetail.route_quotation_details.start_time }}
                </h5>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                class="d-flex flex-column justify-start px-4 pb-2"
              >
                <h4 class="text-caption d-flex justify-start">
                  Duration(mins)
                </h4>
                <h5 class="text-body-2 font-weight-bold d-flex justify-start">
                  {{ routeQuotationDetail.route_quotation_details.duration }}
                </h5>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="0" class="mt-4 rounded-lg">
            <v-card-title>
              <h4 class="text-body-2 font-weight-bold">Other Details</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
              <div
                v-if="
                  routeQuotationDetail.route_quotation_details &&
                  routeQuotationDetail.route_quotation_details.other_details
                "
                class="overflow-y-auto route-quotation-details-container"
              >
                <v-row
                  no-gutters
                  v-for="(value, key, index) in routeQuotationDetail
                    .route_quotation_details.other_details"
                  :key="index"
                  class="pt-1 px-2"
                  v-if="value"
                >
                  <v-col md="5" lg="6">
                    <h4 class="text-capitalize text-caption black--text">
                      {{ key.replace(/_/g, " ") }}
                    </h4>
                  </v-col>
                  <v-col md="7" lg="6" class="d-flex justify-end">
                    <h5>{{ value }}</h5>
                  </v-col>
                </v-row>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12" md="9" lg="9">
          <section class="d-flex flex-column">
            <v-row
              no-gutters
              class="pb-3 px-3"
              v-if="
                routeQuotationDetail &&
                routeQuotationDetail.route_quotation_details
              "
            >
              <v-col
                v-for="(value, key, index) in routeQuotationDetail
                  .route_quotation_details.route_quotation_analytics"
                :key="index"
                class="px-1 pb-1"
              >
                <v-card elevation="0" class="rounded-lg">
                  <v-card-text class="py-2">
                    <span
                      class="text-capitalize text-start text-body-2 font-weight-bold d-flex justify-start"
                    >
                      {{ key.replace(/_/g, "  ") }}
                    </span>
                    <div class="mt-2 d-flex align-center justify-start">
                      <div
                        class="icon-small light_primary d-flex justify-center align-center"
                      >
                        <v-icon color="primary" small>
                          {{ routeQuotationAnalyticsIcon[key] }}</v-icon
                        >
                      </div>

                      <span class="text-caption px-4 my-1">
                        <h3>{{ value }}</h3>
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="px-3" no-gutters>
              <v-col cols="12" class="px-1">
                <v-expansion-panels
                  v-for="(routePlan, routePlanIndex) in routePlanList"
                  :key="routePlanIndex"
                  flat
                  class="mb-3"
                >
                  <RoutePlanExpansionPanel
                    v-model="routePlanList[routePlanIndex]"
                    :routePlanIndex="routePlanIndex"
                    :routeErrors="routeErrors[routePlanIndex]"
                    :selectedRoutes="selectedRoutes"
                    :checkboxRequired="false"
                    @routeSelected="routeSelected($event, routePlan.id)"
                  ></RoutePlanExpansionPanel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
    </template>
  </BaseDetailLayout>
</template>

<script>
import DriverDetailsActionsButton from "@/components/AgGridButtons/DriverDetailsActionsButton.vue";
import RoutePlanExpansionPanel from "../route_planning/RoutePlanExpansionPanel.vue";
import RouteQuotationImage from "@/assets/route-quotation-image.png";
import { AgGridVue } from "ag-grid-vue";
import { bus } from "@/main";
import { convertToBlobAndDownload } from "@/utils/functions.js";
export default {
  name: "DriverLayout",
  components: {
    AgGridVue,
    DriverDetailsActionsButton,
    RoutePlanExpansionPanel,
  },
  data() {
    return {
      RouteQuotationImage,

      itemsPerPage: 10,

      totalItems: 0,
      pageNo: 1,

      gridApi: null,
      columnApi: null,
      defaultColDef: {
        lockPosition: true,
        resizable: true,
        flex: 1,
        sortable: true,
        autoHeight: true,
        cellStyle: {
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          display: "block",
        },
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      columnDefs: [
        {
          headerName: "Trip ID",
          field: "reference_number",
        },
        {
          headerName: "Route Name",
          field: "route",
        },
        {
          headerName: "Route Type",
          field: "route_type",
        },
        {
          headerName: "Vehicle",
          field: "vehicle",
        },
        {
          headerName: "Start Date",
          field: "start_date",
        },
        {
          headerName: "End Date",
          field: "end_date",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "DriverDetailsActionsButton",
        },
      ],

      routePlanList: [],

      routeQuotationDetail: {
        route_quotation_details: {},
      },
      routeQuotationAnalyticsIcon: {
        driving_time: "mdi-truck-fast",
        planned_passengers: "mdi-account-multiple-check",
        total_distance: "mdi-map-marker-distance",
        total_time: "mdi-timer-check",
        unplanned_passengers: "mdi-account-multiple-remove",
        vehicle_fill_ratio: "mdi-truck-check",
      },
      searchTrips: null,

      selectedRoutes: [],
      routeErrors: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    routeSelected(e, id) {
      if (e) {
        this.selectedRoutes.push(id);
      } else {
        this.selectedRoutes.splice(
          this.selectedRoutes.indexOf(this.selectedRoutes.find((v) => v == id)),
          1
        );
      }
    },
    getRouteQuotationDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.route_quotation
        .getRouteQuotationDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeQuotationDetail.route_quotation_details = res.data;
          this.routePlanList = res.data.planned_routes;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getDriverDetailsView(id = null) {
      bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverDetailsView(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.routeQuotationDetail.route_quotation_details = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getDriverTripList(id = null) {
      bus.$emit("showLoader", true);
      this.$api.driver
        .getDriverTripList(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    exportRoute() {
      let id;
      id = this.$route.params.id;
      this.$api.route_quotation
        .exportRoute(id)
        .then((res) => {
          convertToBlobAndDownload(res.data, `Route Quotation file.xlsx`);
          bus.$emit("showToastMessage", {
            message: "File downloaded Successfully! ",
            color: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showToastMessage", {
            message: "Can't download File ",
            color: "error",
          });
        });
    },
    nextPage() {
      this.pageNo++;
      this.getDriverTripList();
    },

    prevPage() {
      this.pageNo--;
      this.getDriverTripList();
    },

    itemsPerPageChange(value) {
      this.pageNo = 1;
      this.itemsPerPage = value;
      this.getDriverTripList();
    },

    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    viewDetails(id) {
      this.$router.push(`/app/admin/trips/detail/${id}`);
    },
  },
  mounted() {
    this.getRouteQuotationDetails(this.$route.params.id);
    // this.getDriverTripList(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.searchTripsClass .v-text-field {
  height: 10px !important;
  width: 10px !important;
}
.route-quotation-details-container {
  height: calc(100vh - 830px) !important;
}

@media screen and (max-width: 1300px) {
  .route-quotation-details-container {
    height: calc(100vh - 400px) !important;
  }
}
</style>